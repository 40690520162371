@value variables: "styles/variables.scss";
@value blue from variables;

:local {
  .success {
    background: blue;
    width: 100%;
    height: 721px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 486px) {
      height: 100%;
    }
  }
  .title {
    text-transform: uppercase;
    margin: 35px 0 56px 0;
    width: 534px;
    height: 64px;
    font-size: 2.375em;
    font-weight: 800;
    line-height: 0.84;
    letter-spacing: -0.1px;
    text-align: center;

    @media (max-width: 486px) {
      width: 100%;
      font-size: 1.75em;
      line-height: 0.93;
    }
  }
}
