@value variables: "styles/variables.scss";
@value defaultTransition from variables;
:local {
  .blueprint {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    width: 3920px;
    margin: 0 auto;
    height: calc(100%);
    z-index: 1;
    left: 50%;
    margin-left: -1960px;
    pointer-events: none;
    @media (max-width: 1274px) {
      // width: calc(100% - 100px * 20);
      // margin-left: calc(100% - 100px * 20);
      // width: 3920px;
      // margin-left: -1960px;
    }
    @media (max-width: 1024px) {
      // width: 796px;
      // margin-left: -398px;
      // width: calc(100% - 100px * 20);
      // margin-left: calc(100% - 100px * 20);
      // width: 3920px;
      // margin-left: -1960px;
    }
    @media (max-width: 881px) {
      display: none;
    }
  }
  .blueprint span {
    display: inline-block;
    width: 196px;
    height: 100%;
    border-left: 1px solid rgba(151, 151, 151, 0.4);
    opacity: 0;
  }

  // default
  .blueprint--default span {
    opacity: 1;
  }

  // half
  .blueprint--half span:nth-child(12),
  .blueprint--half span:nth-child(13),
  .blueprint--half span:nth-child(14),
  .blueprint--half span:nth-child(15),
  .blueprint--half span:nth-child(16),
  .blueprint--half span:nth-child(17),
  .blueprint--half span:nth-child(18),
  .blueprint--half span:nth-child(19),
  .blueprint--half span:nth-child(20) {
    opacity: 1;
  }

  // vacancy
  .blueprint--vacancy span:nth-child(10),
  .blueprint--vacancy span:nth-child(11),
  .blueprint--vacancy span:nth-child(12),
  .blueprint--vacancy span:nth-child(13),
  .blueprint--vacancy span:nth-child(14),
  .blueprint--vacancy span:nth-child(15),
  .blueprint--vacancy span:nth-child(16),
  .blueprint--vacancy span:nth-child(17),
  .blueprint--vacancy span:nth-child(18),
  .blueprint--vacancy span:nth-child(19),
  .blueprint--vacancy span:nth-child(20) {
    opacity: 1;
  }

  // small
  .blueprint--small span:nth-child(13),
  .blueprint--small span:nth-child(14),
  .blueprint--small span:nth-child(15),
  .blueprint--small span:nth-child(16),
  .blueprint--small span:nth-child(17),
  .blueprint--small span:nth-child(18),
  .blueprint--small span:nth-child(19),
  .blueprint--small span:nth-child(20) {
    opacity: 1;
  }
}
