:local {
  .categories {
    display: flex;
    flex-flow: wrap;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: -16px;
    @media (max-width: 740px) {
      flex-direction: column;
      margin-left: -16px;
      margin-right: 0;
    }
  }
}
