@value variables: "styles/variables.scss";
@value navy,defaultTransition from variables;

:local {
  .category-item {
    background-color: white;
    padding: 34px 40px;
    width: calc(50% - 16px);
    margin: 16px 8px;
    margin-top: 0;
    display: flex;
    align-items: flex-start;
    transition: background 0.2s defaultTransition,
      transform 0.2s defaultTransition;
    @media (max-width: 1024px) {
      padding: 23px 20px;
    }
    @media (max-width: 680px) {
      width: 100%;
    }
    &:hover {
      background-color: #eaeaea;
      transform: translateY(-2px);
    }
  }
  .icon {
    width: 120px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
      width: 70px;
      height: 70px;
    }
    @media (max-width: 660px) {
      width: 100px;
      height: 100px;
    }
  }

  .content {
    width: calc(100% - 120px - 34px);
    margin-left: 34px;
    padding-top: 24px;
    @media (max-width: 1024px) {
      margin-left: 22px;
      width: calc(100% - 70px - 22px);
    }
    @media (max-width: 768px) {
      padding-top: 0;
      width: calc(100% - 100px - 22px);
    }
  }

  .title {
    text-transform: uppercase;
    color: navy;
    font-weight: 800;
    line-height: 0.84;
    margin: 0;
    font-size: 1.75em;
    @media (max-width: 1024px) {
      // font-size: 1.25em;
    }
    @media (max-width: 660px) {
      font-size: 1.75em;
      line-height: 1.14;
    }
  }

  .description {
    margin: 0;
    margin-top: 6px;
    line-height: 1.17;
    font-size: 1.5em;
    color: navy;
    @media (max-width: 1024px) {
      font-size: 1.125em;
      line-height: 1.33;
    }
    @media (max-width: 486px) {
      margin-top: 0;
    }
  }
  // simple
  .category-item--simple {
    padding: 0;
    background: none;
    pointer-events: none;
    margin-bottom: 72px;
  }
  .category-item--simple .title {
    color: white;
  }
  .category-item--simple .description {
    color: white;
  }
  .category-item--simple svg,
  .category-item--simple svg g,
  .category-item--simple svg g path,
  .category-item--simple path,
  .category-item--simple polygon {
    fill: white;
  }
}
