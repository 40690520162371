@value variables: "styles/variables.scss";
@value blue,navy,red,green from variables;

:local {
  .radio {
    margin-top: 26px;
    margin-bottom: 42px;
    display: flex;
    align-items: flex-start;
    color: black;
    font-size: 1.125em;

    cursor: pointer;

    label {
      display: inline-block !important;
    }

    span {
      position: relative;
      width: 20px;
      min-width: 20px;
      cursor: pointer;
      height: 20px;
      display: inline-block;
      border-radius: 20px;
      background-color: white;
      margin-right: 10px;
      border: 2px solid #d6d6d6;

      &::before {
        position: absolute;
        transition: all 0.2s ease;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 0;
        transform: scale(0);
        margin: auto;
        content: '';
        width: 14px;
        height: 14px;
        border-radius: 20px;
        background: blue;
      }
    }

    a {
      color: blue;
      font-weight: bold;
      text-decoration: underline;
      position: relative;
    }

    input {
      appearance: none;
      position: absolute;
      left: -9999px;
      top: -9999px;
    }

    &.is-active span::before {
      opacity: 1;
      transform: scale(1);
    }
  }
}
