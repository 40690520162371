@value variables: "styles/variables.scss";
@value blue, defaultTransition from variables;
:local {
  .overview {
    background: blue;
    padding-top: 148px;
    padding-bottom: 80px;
    @media (max-width: 1024px) {
      padding-top: 48px;
    }
    @media (max-width: 768px) {
      padding-top: 0;
      padding-bottom: 40px;
    }
  }

  .wrapper {
    position: relative;
    z-index: 3;
  }

  .title {
    font-weight: 900;
    line-height: 0.8;
    text-transform: uppercase;
    font-size: 4.125em;
    margin-top: 64px;
    margin-bottom: 42px;

    @media (max-width: 1024px) {
      font-size: 3em;
      line-height: 1.1;
    }
    @media (max-width: 768px) {
      margin-top: 16px;
      margin-bottom: 32px;
    }
    em {
      font-weight: 300;
      font-style: normal;
    }
  }
  .title--no-result {
    margin-bottom: 0;
  }

  .blueprint {
    composes: blueprint from 'components/grid/styles.scss';
    height: 100%;
    position: absolute;
  }
}
