@value variables: "styles/variables.scss";
@value blue,navy,defaultTransition from variables;

:local {
  .featured-filter {
    background: blue;
    color: white;
    padding-bottom: 140px;
    position: relative;
    z-index: 2;
    @media (max-width: 768px) {
      padding-bottom: 23px;
    }
  }
  .featured-filter :global(.notch) {
    @media (max-width: 1280px) {
      display: none;
    }
  }

  .amount {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 100px;
    padding-top: 16px;
    @media (max-width: 1280px) {
      justify-content: flex-start;
      margin-bottom: 64px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 28px;
      margin-bottom: 40px;
    }
  }

  .amount-item {
    text-transform: uppercase;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: 100px;
    @media (max-width: 1024px) {
      margin-left: 32px;
    }
    @media (max-width: 768px) {
      margin-left: 0;
    }
    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-top: 40px;
      margin-right: 48px;
      @media (max-width: 1024px) {
        margin-right: 0;
      }
    }

    span {
      font-size: 2em;
      line-height: 0.8;
      font-weight: 900;
      margin-right: 6px;
      min-width: 122px;
      text-align: right;
      @media (max-width: 384px) {
        font-size: 1.875em;
      }
      @media (max-width: 342px) {
        font-size: 1.75em;
        min-width: 100px;
      }
    }

    b {
      font-size: 1.125em;
      font-weight: 800;
      line-height: 0.9;
    }
  }

  .wrapper {
    position: relative;
    z-index: 2;
  }
  .wrapper-top {
    margin-top: -220px;
    pointer-events: none;
    position: relative;
    z-index: 2;
    @media (max-width: 1280px) {
      margin-top: 0;
    }
  }
}
