:local {
  .background {
    background: #143264;
  }
  .background :global(.notch) {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .block {
    z-index: 2;
    position: relative;
    padding-bottom: 114px;

    @media (max-width: 768px) {
      padding-top: 56px;
      padding-bottom: 44px;
    }
  }

  .content {
    color: white;
    max-width: 760px;

    @media (max-width: 768px) {
      width: 100%;
    }

    h3 {
      font-weight: 500;
      margin: 4px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 1.125em;
      }
    }

    h1 {
      font-size: 4.125em;
      font-weight: 900;
      margin: 0 0 30px 0;
      line-height: 53px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 3em;
        line-height: 0.79;
      }
      @media (max-width: 486px) {
        font-size: 2em;
      }
    }

    p {
      margin: 32px 0;
      font-size: 1.5em;
      font-weight: normal;

      @media (max-width: 768px) {
        font-size: 1.125em;
        line-height: 1.56;
      }
    }
  }

  .categories {
    composes: categories from 'components/featured-filter-categories/styles.scss';
    margin-top: 75px;
    margin-bottom: -72px;
  }
}
