:local{
    .transition{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: background, 100ms, ease;
    }

    .passive {
        background: rgba(0, 0, 0, 0);
        z-index: 0;

    }
    .blur {
        background: rgba(0, 0, 0, 0.7);
        z-index: 100;
    }
}