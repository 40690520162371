@value variables: "styles/variables.scss";
@value blue,navy,defaultTransition from variables;

:local {
  .partner {
    margin-bottom: 28px;
    width: calc(25% - 16px);
    margin-left: 8px;
    margin-right: 8px;
    @media (max-width: 1024px) {
      width: calc(50% - 16px);
    }
    @media (max-width: 520px) {
      width: calc(100% - 16px);
    }

    h4 {
      font-size: 2.375em;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.84;
      letter-spacing: normal;
      color: #143264;
      text-transform: uppercase;
      margin: 0;
    }
    span {
      font-size: 1.5em;
      font-weight: normal;
      margin-top: 12px;
      display: block;
      color: #0078e6;
      text-transform: uppercase;
    }
  }
}
