@value variables: "styles/variables.scss";
@value blue,red,green,navy from variables;

:local {
  .header {
    height: 76px;
    transition: background-color 300ms ease;
    border-bottom: solid 1px #ffffff;
    user-select: none;
    font-weight: bold;
    font-size: 18px;
    z-index: 998;
    position: sticky;
    width: 100%;
    top: 0;
    @media (max-width: 1024px) {
      position: fixed;
      background-color: white;
    }
    @media (max-width: 700px) {
      transition: none;
      border-bottom: none;
    }
  }
  .uspcontainer.uspcontainer-color{
    @media (max-width: 1024px) {
      visibility: hidden;
    }
    svg{
      fill: #0078e6;
      g{
        fill: #0078e6;
      }
    }
    p{
      color: #143264;
    }
  }
  .uspcontainer{
    display: flex;
    padding-top: 24px;
    padding-left: 15px;
    padding-bottom: 5px;
    align-items: center;
    @media (max-width: 1024px) {
      position: absolute;
      top: 76px;
      visibility: visible;
    }
    svg{
      width: 31px;
      height: 44px;
      fill: white;
    }
    p{
      font-size: 16px;
      color: white;
      width: 247px;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: bold;
      margin-left: 8px;
      line-height: 0.9;
    }
    p.subtext{
      font-size: 12px;
      font-weight: normal;
      line-height: 14px;
      font-style: 300;
    }
  }

  .header-background-A {
    background-color: rgba(0, 0, 0, 0);
    @media (max-width: 1024px) {
      background-color: white;
    }
  }

  .header-background-B {
    background-color: blue;
    @media (max-width: 1024px) {
      background-color: white;
    }
  }

  .header-color {
    background-color: white;
    @media (max-width: 1024px) {
      background-color: white;
    }
  }

  .container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 100%;
    @media (max-width: 1024px) {
      position: relative;
    }
  }

  .menubtn {
    display: flex;
    align-items: center;
    height: 75px;
    justify-content: center;
    color: white;
    width: 100%;
    max-width: 172px;
    font-size: 1.125em;

    .hamburger {
      margin-right: 8px;
      margin-top: 3px;
      width: 22px;
      height: 15px;
    }

    .hamburger-fill-A > g {
      fill: navy;
    }

    .hamburger-fill-B > g {
      fill: blue;
    }
  }

  .menubtn-background-A {
    background-color: blue;
  }

  .menubtn-background-B {
    background-color: navy;
  }

  .logo {
    transition: width 200ms ease;
    background: white;
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 204px;

    // @media (max-width: 700px) {
    //   height: 75px;
    //   max-width: initial;
    //   width: 50%;
    // }
  }

  .rightbtns {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: 18px;

    .vacaturebtn {
      padding-right: 17.5px;
      color: white;
      @media (max-width: 1040px) {
        display: none;
      }
    }

    .vacaturebtn-color {
      color: blue;
    }

    .hourbtn {
      display: flex;
      align-items: center;
      height: 75px;
      padding: 0 35px;
      padding-left: 17.5px;
      @media (max-width: 1280px) {
        display: none;
      }
      .arrow {
        margin-left: 8px;
        left: 0;
        top: 1px;
        transition: left 100ms ease;
        position: relative;
      }

      &:hover {
        .arrow {
          left: 8px;
        }
      }

      .arrow-fill-A > g {
        fill: blue;
      }

      .arrow-fill-B > g {
        fill: white;
      }
    }

    .hourbtn-background-A {
      background-color: #ffffff;
      color: blue;
    }

    .hourbtn-background-B {
      background-color: navy;
      color: white;
    }

    .phonebtn {
      display: flex;
      align-items: center;
      height: 75px;
      padding: 0 36px;
      @media (max-width: 700px) {
        display: none;
      }
      .phone {
        margin-right: 8px;
      }

      .phone-fill-A > g {
        fill: navy;
      }

      .phone-fill-B > g {
        fill: blue;
      }
    }

    .phonebtn-background-A {
      background-color: blue;
      color: white;
    }

    .phonebtn-background-B {
      background-color: white;
      color: blue;
    }
  }
  //IE
  :global(.ie) .header {
    position: fixed;
    width: 100%;
  }
}
