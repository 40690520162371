.overflow-visible,
.overflow-visible > * {
  overflow: visible !important;
}
body {
  overflow-x: hidden;
  position: relative;
  min-width: 320px;
}
body.disable-scroll {
  overflow: hidden;
}

main {
  width: 100%;
  margin-top: -76px;
  position: relative;
  overflow-x: hidden;
  @media screen and (max-width: 1024px) {
    margin-top: 76px;
  }
  // position: absolute;
  // height: 100%;
  // overflow-x: hidden;
}
main.main--top {
  @media screen and (max-width: 768px) {
    padding-top: 124px;
  }
}

main.blue {
  background: #0078e6;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 32px);
  max-width: 1174px;
  // @media screen and (max-width: 1280px) and (min-width: 1024px) {
  //   max-width: 783px;
  // }
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    max-width: 783px;
  }
  @media screen and (min-width: 768px) {
    width: calc(100% - 100px);
  }
}
