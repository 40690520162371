@value variables: "styles/variables.scss";
@value blue,navy,defaultTransition from variables;
:local {
  .grid {
    position: relative;
    z-index: 2;
    display: flex;
    padding-top: 0;
    padding-bottom: 138px;
    @media (max-width: 832px) {
      padding-top: 32px;
      padding-bottom: 70px;
    }
  }
  .heading {
    font-weight: 900;
    font-size: 4.125em;
    line-height: 0.8;
    color: white;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 88px;
    margin-top: 0;
    @media (max-width: 832px) {
      font-size: 3em;
      line-height: 0.79;
      margin-bottom: 48px;
      text-align: left;
    }
    @media (max-width: 486px) {
      font-size: 2em;
      // line-height: 0.77;
    }
  }
  .grid-item {
    position: relative;
    margin-bottom: 90px;
    display: flex;
    // align-items: flex-start;
    justify-content: center;
    @media (max-width: 832px) {
      justify-content: flex-start;
      flex-flow: column;
      margin-bottom: 32px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &::before {
      content: '';
      width: 3px;
      background: white;
      height: calc(100% + 90px);
      position: absolute;
      left: 0;
      right: 0;
      top: 40px;
      margin: auto;
      z-index: 1;
      @media (max-width: 832px) {
        right: auto;
        top: 17px;
        left: 16px;
        height: calc(100% + 32px);
      }
    }
  }
  .index {
    background: white;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.375em;
    font-weight: 900;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    color: blue;
    z-index: 2;
    top: 40px;
    @media (max-width: 832px) {
      right: auto;
      width: 34px;
      height: 34px;
      font-size: 1em;
      top: 0;
    }
  }
  .index--last {
    border-radius: 100%;
    background: #00dc32;
    width: 67px;
    height: 67px;
    top: 29px;
    @media (max-width: 832px) {
      width: 50px;
      height: 50px;
      top: -8px;
      left: -9px;
    }
  }
  .grid-item .column {
    max-width: 380px;
    min-width: 380px;
    background: white;
    padding: 32px;
    position: relative;
    margin-right: calc(136px + 40px + 24px);
    margin-left: auto;
    transform: translateY(-8px);
    opacity: 0;
    transition: transform 0.2s defaultTransition, opacity 0.2s defaultTransition;
    @media (max-width: 1220px) {
      margin-right: calc(41px + 12px + 24px);
    }
    @media (max-width: 1024px) {
      max-width: 324px;
      min-width: 324px;
    }
    @media (max-width: 832px) {
      margin-right: 0;
      margin-left: calc(16px + 12px + 34px);
      min-width: 1px;
    }
    &::before {
      content: '';
      width: 136px;
      height: 2px;
      background: white;
      position: absolute;
      right: -136px;
      top: 64px;
      @media (max-width: 1220px) {
        width: 41px;
        right: -41px;
      }
      @media (max-width: 832px) {
        top: 16px;
        width: 16px;
        left: -16px;
        right: auto;
      }
    }
  }
  .title {
    font-size: 2.375em;
    line-height: 0.84;
    color: navy;
    margin: 0;
    margin-bottom: 16px;
    text-transform: uppercase;
    font-weight: 800;
    @media (max-width: 832px) {
      font-size: 1.75em;
      line-height: 0.86;
    }
  }
  .description {
    font-size: 1.125em;
    line-height: 1.33;
    margin: 0;
  }
  .description a {
    color: blue;
    text-decoration: underline;
    font-weight: bold;
    &:hover {
      text-decoration: none;
    }
  }
  //modifiers
  .grid-item .column--icon {
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 832px) {
      display: none;
    }
    &::before {
      display: none;
    }
  }

  // .grid-item .column--icon svg,
  // .grid-item .column--icon svg path {
  //   fill: white;
  // }
  .grid-item .column--icon svg {
    width: 284px;
    height: auto;
    @media (max-width: 1024px) {
      width: 148px;
    }
  }

  .grid-item .column--right {
    margin-right: auto;
    margin-left: calc(136px + 40px + 24px);
    @media (max-width: 1220px) {
      margin-left: calc(41px + 12px + 24px);
    }
    @media (max-width: 832px) {
      margin-left: calc(16px + 12px + 34px);
    }
    &::before {
      left: -136px;
      right: auto;
      @media (max-width: 1220px) {
        left: -41px;
      }
      @media (max-width: 832px) {
        left: -16px;
      }
    }
  }

  .grid-item--last {
    &::before {
      display: none;

      @media (max-width: 832px) {
        // display: block;
        // height: calc(50% + 48px);
      }
    }
  }
  .grid-item--last .column {
    &:last-of-type {
      @media (max-width: 832px) {
        margin-top: 32px;
      }
    }
  }
  .grid-item--text .column {
    &:last-of-type {
      @media (max-width: 832px) {
        margin-top: 32px;
      }
      &::before {
        @media (max-width: 832px) {
          display: none;
        }
      }
    }
  }

  //transition
  .grid-item.grid-item-enter-done .column {
    opacity: 1;
    transform: translateY(0);
  }
}
