@value variables: "styles/variables.scss";
@value blue,navy,defaultTransition from variables;

:local {
  .textblock {
    position: relative;
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;

    @media (max-width: 768px) {
      margin-top: 56px;
      margin-bottom: 56px;
    }

    @media (max-width: 486px) {
      margin-top: 32px;
    }
  }
}
