:local {
  .loading {
    height: 50px;
    width: 100%;
    display: block;
    position: relative;
  }
  .spinner {
    :global {
      animation: rotate 2s linear infinite;
    }
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;

    circle {
      stroke: rgb(255, 255, 255);
      stroke-linecap: round;
      :global {
        animation: dash 1.5s ease-in-out infinite;
      }
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}
