@value variables: "styles/variables.scss";
@value blue,navy,defaultTransition from variables;

:local {
  .popup {
    position: relative;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @media (max-width: 1024px) {
      // align-items: flex-start;
      // padding-top: 24px;
    }
    @media (max-width: 600px) {
      padding-top: 0;
    }
  }

  .popup-content {
    width: 100%;
    position: relative;
    // transform: translateX(100%);
    opacity: 0;
    transition: transform 0.2s defaultTransition, opacity 0.2s defaultTransition;
  }

  .popupcontainer {
    width: 1160px;
    // position: fixed;
    z-index: 3;
    // height: 724px;
    overflow: hidden;
    display: flex;
    background: white;
    // left: 50%;
    // top: 50%;
    // margin-left: calc(-1160px * 0.5);
    opacity: 0;
    margin-top: 0px;
    transition: all 0.2s defaultTransition;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.53);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100% - 54px);
    @media (max-width: 1192px) {
      width: 1000px;
      // margin-left: calc(-1000px * 0.5);
      // padding-bottom: 64px;
    }
    @media (max-width: 1024px) {
      width: 700px;
      max-height: calc(100% - 24px);
      // margin-left: calc(-700px * 0.5);
      // padding-bottom: 32px;
    }
    @media (max-width: 732px) {
      width: 568px;
      // margin-left: calc(-568px * 0.5);
    }
    @media (max-width: 600px) {
      width: 100%;
      left: 0;
      top: 0;
      // margin-left: initial;
      // height: 100vh;
      // max-height: 100vh;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }

    .closebtn {
      position: absolute;
      right: 0;
      top: 0;
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: transform 0.2s defaultTransition;
      transform: translateY(-100%);
      cursor: pointer;
      z-index: 3;
      background: navy;
      svg {
        width: 25px;
        height: 25px;
        @media (max-width: 768px) {
          width: 19px;
          height: 19px;
        }
        g {
          fill: white;
        }
      }

      @media (max-width: 600px) {
        width: 60px;
        height: 60px;
      }
    }

    .closebtnnavy {
      background: navy;
    }

    .closebtnblue {
      background: blue;
    }
  }

  .background {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: background-color 0.2s defaultTransition;
  }

  // transition
  .popup-enter-done .popupcontainer {
    // margin-top: calc(-724px * 0.5);
    opacity: 1;
    transition: all 250ms ease-out;
    @media (max-width: 600px) {
      margin-top: initial;
    }
  }

  .popup-enter-done .background {
    background-color: rgba(0, 0, 0, 0.34);
  }

  .popup-content.popup-content-enter-done {
    transform: translateX(0);
    opacity: 1;
  }
  .closebtn.closebtn-enter-done {
    transform: translateY(0);
  }
}
