@value variables: "styles/variables.scss";
@value blue,navy from variables;
:local {
  .cookie-notice {
    background: navy;
    user-select: none;
    color: white;
    padding: 32px 0;
    position: relative;
    z-index: 999;
    @media (max-width: 1024px) {
      margin-top: 76px;
      margin-bottom: -76px;
    }
  }

  .wrapper {
    padding: 0 56px;
    max-width: 1163px;
    @media (min-width: 768px) and (max-width: 1280px) {
      padding: 0 26px;
    }
    @media (max-width: 768px) {
      padding: 0 16px;
    }
  }
  .title {
    margin: 0;
    font-weight: 800;
    font-size: 1.25em;
    text-transform: uppercase;
    @media (max-width: 768px) {
      padding-right: 74px;
    }
  }
  .description {
    line-height: 1.56;
    margin-bottom: 0;
    margin-top: 8px;
    letter-spacing: 0;
    font-size: 1em;
    @media (max-width: 1280px) {
      padding-right: 74px;
    }
    @media (max-width: 600px) {
      padding-right: 0;
    }
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 3;
    background: blue;
    svg {
      width: 25px;
      height: 25px;
      @media (max-width: 768px) {
        width: 19px;
        height: 19px;
      }
      g {
        fill: white;
      }
    }

    @media (max-width: 600px) {
      width: 60px;
      height: 60px;
    }
  }
}
