:local {
  .marquee {
    height: 187px;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    margin: 40px 0;
    @media (max-width: 768px) {
      height: 131px;
    }
  }

  .marquee--footer {
    height: 131px;
  }

  .marqueeInner {
    display: block;
    width: max-content;
    margin: 0;
    position: absolute;
    :global {
      animation: marquee 35s linear infinite;
      @media (max-width: 486px) {
        animation: marquee 20s linear infinite;
      }
    }
    p {
      font-size: 9em;
      font-weight: 900;
      margin: 0;
      text-transform: uppercase;
      @media (max-width: 768px) {
        font-size: 6.75em;
      }
    }
    span {
      display: flex;
    }
  }

  .marquee--footer .marqueeInner p {
    font-size: 6.75em;
  }

  /*.half {
        float: left;
        width: 10%;
      }*/

  @keyframes marquee {
    0% {
      left: 0;
    }
    100% {
      left: -170%;
    }
  }
}
