@value variables: "styles/variables.scss";
@value blue, navy, defaultTransition from variables;
:local {
  .notch {
    position: relative;
    // z-index: 2;
    // overflow: hidden;
    // background: white;
    color: black;
    height: 111px;
    top: -110px;
    overflow: hidden;
    pointer-events: none;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .wrapper {
    position: relative;
    height: 100%;
    min-width: 1174px;
    @media (max-width: 1200px) {
      min-width: 1000px;
      width: 1000px;
    }
    &::after {
      content: '';
      width: 100vw;
      height: 110px;
      position: absolute;
      right: calc(425px - 100vw);
      top: 0;
      background: white;
      // opacity: 0;
      // transform: translateY(110px);
      transition: all 0.2s defaultTransition;
      transform: translateY(0);
      opacity: 1;
      @media (max-width: 1024px) {
        right: calc(365px - 100vw);
      }
    }
  }

  .path {
    position: absolute;
    right: 424px;
    backface-visibility: hidden;
    top: 0px;
    width: 110px;
    height: 111px;
    background-color: white;
    clip-path: polygon(100% 0, 100% 0%, 100% 100%, 0 100%);
    // opacity: 0;
    // transform: translateY(110px);
    transition: all 0.2s defaultTransition;
    transform: translateY(0);
    opacity: 1;
    @media (max-width: 1024px) {
      right: 364px;
    }
  }
  .grid {
    width: 100%;
    max-width: 1174px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    height: calc(100%);
    z-index: 3;
    background-repeat: initial;
    background-size: 196px;
    background-position: left;
    background-image: linear-gradient(
      to right,
      rgba(151, 151, 151, 0.4) 1px,
      transparent 1px
    );
    &::before {
      content: '';
      width: 100vw;
      height: calc(100%);
      position: absolute;
      left: calc(-100vw - 196px);
      top: 0;
      transform: rotate(180deg);
      background-repeat: initial;
      background-size: 196px;
      background-position: left;
      background-image: linear-gradient(
        to right,
        rgba(151, 151, 151, 0.4) 1px,
        transparent 1px
      );
    }
    &::after {
      content: '';
      width: 100vw;
      height: calc(100%);
      right: -100vw;
      position: absolute;
      top: 0;
      background-repeat: initial;
      background-size: 196px;
      background-position: left;
      background-image: linear-gradient(
        to right,
        rgba(151, 151, 151, 0.4) 1px,
        transparent 1px
      );
    }
  }
  // enter
  // .notch-enter-done .path {
  //   transform: translateY(0);
  //   opacity: 1;
  // }
  // .notch-enter-done .wrapper::after {
  //   transform: translateY(0);
  //   opacity: 1;
  // }

  // modifiers
  .notch--top {
    top: 0;
  }
  .notch--top .path {
    top: -1px;
  }

  .notch--longer .wrapper::after {
    left: calc(324px + 110px);
    right: auto;
  }
  .notch--longer .path {
    left: 325px;
    right: auto;
  }

  .notch--vacancy .wrapper::after {
    left: calc(259px + 110px);
  }
  .notch--vacancy .path {
    left: 260px;
    right: auto;
  }

  .notch--left .wrapper::after {
    left: calc(479px - 100vw);
    right: 0;
  }

  .notch--left .path {
    transform: translateY(0) rotate(90deg);
    left: 479px;
    right: 0;
  }

  .notch--back .path {
    clip-path: polygon(100% 100%, 100% 100%, 0% 0%, 100% 0%);
  }

  .notch--blue .path {
    background-color: blue;
  }
  .notch--blue .wrapper::after {
    background-color: blue;
  }

  .notch--navy .path {
    background-color: navy;
  }
  .notch--navy .wrapper::after {
    background-color: navy;
  }

  .notch--flip {
    transform: scaleY(-1);
    top: -631px;
  }
  // buiten alle components zetten dan met z-index kijken waar wel of geen grid moet komen
  .test {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    // TRY
    // position: absolute;
    // left: 0;
    // right: 0;
    // margin: auto;
    z-index: -5;
    span {
      border-right: 1px solid #eee;
      width: calc((100% / 9.736) - 1px);
      &:last-of-type {
        border-right: 0;
      }

      &:first-of-type {
        width: 10%;
      }
    }
  }
  .notch--small .wrapper::after {
    right: calc(204px - 100vw);
  }
  .notch--small .path {
    right: 204px;
  }
  .notch--small.notch--left .wrapper::after {
    left: calc(310px - 100vw);
    right: auto;
  }
  .notch--small.notch--left .path {
    left: 310px;
    right: auto;
  }
}
