@value variables: "styles/variables.scss";
@value defaultTransition from variables;
:local {
  .page-hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 630px;
    overflow: hidden;
    position: relative;
    padding-top: 76px;
    display: flex;
    align-items: flex-end;
    color: white;
    z-index: 2;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      mix-blend-mode: multiply;
      background-blend-mode: multiply, multiply;
      background-image: linear-gradient(
          to bottom,
          rgba(0, 120, 230, 0.5),
          rgba(0, 120, 230, 0.5)
        ),
        linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
      z-index: 1;
    }
    @media (max-width: 486px) {
      min-height: 380px;
    }
  }

  :global(.ie) .page-hero {
    padding-top: 304px;
    min-height: 1px;
  }

  .wrapper {
    position: relative;
    padding-bottom: 140px;
    z-index: 2;
    @media (max-width: 768px) {
      padding-bottom: 100px;
    }
    @media (max-width: 486px) {
      padding-bottom: 54px;
    }
  }

  .heading {
    display: flex;
    flex-flow: column;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .subtitle {
    font-size: 1.75em;
    text-transform: uppercase;
    line-height: 1.14;
    font-weight: 300;
    letter-spacing: 0.6px;
    transition: all 0.2s defaultTransition;
    opacity: 0;
    transform: translateY(10px);
    @media (max-width: 768px) {
      font-size: 1.25em;
      line-height: 1.6;
      letter-spacing: 0.4px;
    }
  }
  .subtitle-enter-done {
    opacity: 1;
    transform: translateY(0px);
  }

  .title {
    composes: title from global;
    opacity: 0;
    transform: translateY(10px);
    max-width: 876px;
    hyphens: auto;
    @media (max-width: 1024px) {
      padding-right: 44px;
    }
    @media (max-width: 768px) {
      font-size: 3em;
      line-height: 0.81;
      padding-right: 0;
      max-width: 100%;
    }
    @media (max-width: 486px) {
      font-size: 2em !important;
    }
  }

  .title-enter-done {
    opacity: 1;
    transform: translateY(0px);
  }

  .slogan {
    width: 227px;
    margin-top: 52px;
    transition: all 0.2s defaultTransition;
    opacity: 0;
    transform: translateY(10px);
    @media (max-width: 768px) {
      margin-top: 8px;
      width: 170px;
    }
  }
  .slogan-enter-done {
    opacity: 1;
    transform: translateY(0px);
  }

  .plus {
    position: relative;
    width: 40px;
    height: 40px;
    margin-left: -20px;
    margin-bottom: 12px;
    transition: all 0.2s defaultTransition;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .plus span {
    height: 3px;
    width: 100%;
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    transform-origin: center;
    transform: scale(0);
    transition: all 0.2s defaultTransition;
    &:nth-child(2) {
      transform: rotate(90deg) scale(0);
    }
  }
  .plus-enter-done span {
    transform: scale(1);
    &:nth-child(2) {
      transform: rotate(90deg) scale(1);
    }
  }

  .grid {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    width: 2348px;
    margin: 0 auto;
    height: calc(100%);
    z-index: 1;
    left: 50%;
    margin-left: -1174px;
    user-select: none;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .grid .vertical,
  .grid .horizontal {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    width: 2348px;
    margin: 0 auto;
    height: calc(100%);
    z-index: 1;
    left: 50%;
    margin-left: -1174px;
  }
  .grid .horizontal {
    flex-flow: column;
    height: calc(66px * 36);
  }
  .grid .vertical span {
    display: inline-block;
    width: 66px;
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.05);
  }
  .grid .horizontal span {
    display: inline-block;
    width: 100%;
    height: 66px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  // .social {
  //   position: absolute;
  //   right: 0;
  //   bottom: 110px;
  //   margin-bottom: 84px;
  //   display: flex;
  //   flex-flow: column;
  //   align-items: flex-end;
  //   transition: all 0.2s defaultTransition;
  //   opacity: 0;
  //   transform: translateY(10px);
  // }
  // .social-enter-done {
  //   opacity: 1;
  //   transform: translateY(0px);
  // }
  // .social-title {
  //   transform-origin: right;
  //   transform: rotate(90deg) translate(0, 0%);
  //   text-transform: uppercase;
  //   font-weight: 900;
  //   white-space: nowrap;
  //   margin-right: 100%;
  //   display: flex;
  //   align-items: center;
  //   align-self: flex-start;
  //   position: relative;
  //   right: 12px;
  //   font-size: 0.875em;
  //   letter-spacing: 1;
  //   @media (max-width: 486px){
  //     transform: rotate(0deg);
  //   }
  //   &::after {
  //     content: '';
  //     width: 39px;
  //     margin-left: 24px;
  //     background: white;
  //     height: 2px;
  //     display: inline-block;
  //   }
  // }
  // .social a {
  //   color: white;
  //   margin-bottom: 32px;
  //   &:first-of-type {
  //     margin-top: 24px;
  //   }
  //   &:last-of-type {
  //     margin-bottom: 0;
  //   }
  // }
  // .social a svg {
  //   width: 22px;
  //   height: 22px;
  //   transition: transform 0.2s ease;
  // }
  // .social a:hover svg {
  //   transform: scale(1.1);
  // }

  .goback {
    border-style: solid;
    border-width: 2px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 25px;
    // width: 55px;
    // height: 23px;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    order: 1;
    @media (max-width: 768px) {
      margin-bottom: 24px;
    }
  }

  .arrowleft {
    transform: rotateY(180deg);
    margin-right: 8px;
  }

  // modifiers

  .page-hero--story {
    padding-top: 204px;
  }
  :global(.ie) .page-hero--story {
    padding-top: 279px;
  }
  .page-hero--story .wrapper {
    padding-bottom: 232px;
    @media (max-width: 1280px) {
      padding-bottom: 32px;
    }
  }

  .page-hero--radio {
    padding-top: 204px;

    @media (max-width: 768px) {
      h1 {
        padding-bottom: 60px;
        padding-left: 10px;
      }
    }
  }
  :global(.ie) .page-hero--radio {
    padding-top: 279px;
  }
  .page-hero--radio .wrapper {
    padding-bottom: 232px;
    @media (max-width: 1280px) {
      padding-bottom: 32px;
    }
  }


  .page-hero--vacancy .heading {
    @media (max-width: 768px) {
      order: 2;
    }
  }
  .page-hero--vacancy .title {
    padding-left: calc(344px + 54px);
    margin-left: -9px;
    padding-bottom: 110px;
    max-width: calc(100% - 44px);
    @media (max-width: 1280px) {
      padding-left: 0;
      margin-left: 0;
      margin-bottom: 32px;
    }
    @media (max-width: 768px) {
      padding-bottom: 0;
      margin-left: 0;
      margin-bottom: 0;
      padding-left: 0;
    }
  }

  .page-hero--salesbox .wrapper {
    padding-left: 339px;
    width: initial;
    @media (max-width: 900px) {
      padding-left: initial;
    }
  }
  .page-hero--salesbox .heading {
    //float: right;
    max-width: 734px;
  }
  .page-hero--vacancy .wrapper {
    padding-bottom: 32px;
    @media (max-width: 768px) {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
    }
  }
  // .page-hero--vacancy .goback {
  //   margin-top: 108px;
  //   display: block;
  //   @media (max-width: 768px) {
  //     margin-top: 108px;
  //   }
  // }
  .background {
    position: absolute;
    height: 100%;
    width: 177.77777778vh;
    min-width: 100%;
    min-height: 56.25vw;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
