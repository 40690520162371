:local {
  .button {
    border: 0;
    padding: 0;
    position: relative;
    background: none;
    display: inline-block;
    font-weight: bold;

    &::after {
      content: "";
      width: 100%;
      height: 0px;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 1;
      // transition: 0.2s all $basicTransition;
      background-color: rgba(255, 255, 255, 0.2);
    }

    &:active {
      // transform: translateY(0px);
      // &:after {
      //   // transition: 0.1s all $basicTransition;
      //   // transform: scale(1.05);
      //   background-color: rgba(255, 255, 255, 0.2);
      //   top: 0;
      // }
    }

    &:focus {
      outline: none;
    }
  }

  span.content {
    padding: 0 15px;
    height: 41px;
    min-height: 41px;
    position: relative;
    color: #172b48;
    z-index: 2;
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
    user-select: none;
    background: none;
    border: 0;
    min-width: 100%;
    height: 100%;
    letter-spacing: 0;
    text-align: center;
    transition: 0.2s all ease-in-out;
    backface-visibility: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: white;
    margin-left: 0;
    text-align: center;
    white-space: inherit;

    @media (max-width: 768px) {
      // min-width: 180px;
      font-size: 0.9375em;
      padding: 0 10px;
    }
    @media (max-width: 486px) {
      font-size: 1em;
    }
  }

  .button svg {
    margin-left: 8px;
    position: relative;
    transition: all 100ms ease;
    left: 0;
    top: 1px;
  }

  .button .content span {
    margin-left: 8px;
    // min-width: 200px;
    text-align: left;
    white-space: nowrap;
  }

  //sizes
  .button--large {
    width: 270px;
    height: 63px;
    min-height: 63px;

    .content {
      font-size: 18px;
      font-weight: bold;
    }
  }

  //color
  .button--blue {
    .content {
      background: #0078e6;
      color: white;
    }

    &:hover {
      svg {
        left: 8px;
      }

      .content {
        background: #0062bb;
      }
    }

    &:active {
      .content {
        background: #143264;
      }
    }
  }

  .button--white {
    .content {
      background: white;
      color: #0078e6;
    }

    &:hover {
      svg {
        left: 8px;
      }

      .content {
        background: #dbdbdb;
      }
    }

    &:active {
      .content {
        background: #dbdbdb;
      }
    }
  }

  .button--transparent {
    .content {
      background: transparent;
      color: #0078e6;
    }

    &:hover {
      svg {
        left: 8px;
      }

      .content {
        background: transparent;
      }
    }

    &:active {
      .content {
        background: #dbdbdb;
      }
    }
  }

  .button--green {
    .content {
      background: #00dc32;
      color: white;
    }

    &:hover {
      svg {
        left: 8px;
      }

      .content {
        background: #00a425;
      }
    }

    &:active {
      .content {
        background: #007a1b;
      }
    }
  }

  .button--small {
    width: 133;
    height: 63px;

    .content {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .button--disabled {
    opacity: 0.75;
    pointer-events: none;
  }

  .button--lower {
    margin-top: 60px;
  }
}
