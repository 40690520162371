@value variables: "styles/variables.scss";
@value blue,navy,defaultTransition from variables;

:local {
  .textblock {
    position: relative;
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    background: #0078e6;
    padding-bottom: 90px;
    @media (max-width: 768px) {
      padding-top: 56px;
      padding-bottom: 56px;
    }
    @media (max-width: 486px) {
      padding-top: 32px;
    }
  }
  .list {
    display: flex;
    flex-flow: wrap;
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: -48px;
    margin-top: 88px;
    @media (max-width: 1024px) {
      flex-flow: column;
      margin-bottom: 0;
      margin-top: 56px;
    }
    @media (max-width: 768px) {
      margin-bottom: -24px;
    }
  }
  .item {
    display: flex;
    width: calc(50% - 48px);
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 76px;
    @media (max-width: 1024px) {
      width: calc(100% - 48px);
      margin-bottom: 24px;
    }
    @media (max-width: 443px) {
      flex-flow: column;
      justify-content: flex-start;
    }
  }
  .icon {
    margin-top: 4px;
    min-width: 110px;
    @media (max-width: 768px) {
      min-width: 78px;
      height: auto;
    }
  }
  .text {
    margin-left: 40px;
    display: flex;
    flex-flow: column;
    width: calc(100% - 110px - 40px);
    text-transform: uppercase;
    @media (max-width: 1024px) {
      max-width: 407px;
    }
    @media (max-width: 768px) {
      margin-left: 24px;
      width: calc(100% - 110px - 24px);
    }
    @media (max-width: 443px) {
      margin-left: 0;
      width: 100%;
      margin-top: 16px;
    }
  }
  .title {
    font-size: 2.375em;
    color: white;
    font-weight: 800;
    line-height: 0.84;
    display: block;
    @media (max-width: 768px) {
      font-size: 1.5em;
    }
  }
  .description {
    display: block;
    margin-top: 6px;
    font-weight: 500;
    line-height: 0.88;
    color: #143264;
    font-size: 1.5em;
    @media (max-width: 768px) {
      font-size: 1.125em;
    }
  }
}
