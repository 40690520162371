@value variables: "styles/variables.scss";
@value blue,defaultTransition from variables;

:local {
  .block {
    position: relative;
    margin-left: auto;
    display: flex;
    z-index: 2;
    width: 50%;
    max-width: 586px;
    padding-top: 190px;
    @media (max-width: 1278px) {
      margin-left: 50px;
      width: calc(50% - 50px);
    }
    @media (max-width: 1024px) {
      width: 100%;
      max-width: 100%;
      padding-top: 80px;
      composes: wrapper from global;
    }
    @media (max-width: 768px) {
      padding-top: 43px;
      margin-left: 0;
    }
  }

  .leftblock {
    // width: 584px;
    padding-bottom: 133px;
    // margin-top: -78px;
    opacity: 1;
    transform: translateX(-200px);
    transition: opacity 0.2s defaultTransition, transform 0.2s defaultTransition;
    padding-right: 32px;
    @media (max-width: 1024px) {
      padding-bottom: 42px;
      width: 100%;
      padding-right: 0;
    }
    @media (max-width: 768px) {
    }

    h1 {
      font-size: 4.125em;
      font-weight: 900;
      margin-bottom: 24px;
      margin-top: 0;
      text-transform: uppercase;
      line-height: 0.8;
      @media (max-width: 768px) {
        font-size: 3em;
        line-height: 0.94;
        margin-bottom: 16px;
      }
    }

    p {
      font-size: 1.5em;
      line-height: 1.75;
      color: #ffffff;
      max-width: 518px;
      margin: 0;
      @media (max-width: 768px) {
        font-size: 1.25em;
        line-height: 1.5;
        width: 100%;
      }
    }

    h4 {
      font-size: 1.5em;
      font-weight: 800;
      margin-top: 42px;
      margin-bottom: 8px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        line-height: 0.86;
        margin-top: 32px;
        margin-bottom: 14px;
      }
      @media (max-width: 486px) {
        max-width: 250px;
      }
    }
    :global(.contact) {
      padding-top: 16px;
      margin-bottom: 8px;
      @media (max-width: 768px) {
        font-size: 1.25em;
      }
    }
    :global(.button) {
      margin-bottom: 0;
      @media (max-width: 486px) {
        width: 100%;
      }
    }
  }

  .leftblock-enter-done {
    opacity: 1;
    transform: translateX(0);
  }
}
