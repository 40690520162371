:local {
  .background {
    background: white;
    margin-bottom: 196px;
    position: relative;
    z-index: 2;
    @media (max-width: 1024px) {
      margin-bottom: 114px;
    }
    @media (max-width: 768px) {
      margin-bottom: 80px;
      margin-top: 24px;
    }
  }
  .background :global(.notch) {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .block {
    z-index: 2;
    position: relative;

    display: flex;

    @media (max-width: 1024px) {
      flex-direction: column-reverse;
    }
  }

  .content {
    h3 {
      text-transform: uppercase;
      color: #0078e6;
      font-weight: 500;
      margin: 4px;

      @media (max-width: 768px) {
        font-size: 1.125em;
      }
    }

    h1 {
      max-width: 554px;
      text-transform: uppercase;
      color: #143264;
      font-size: 4.125em;
      font-weight: 900;
      margin: 0 0 24px 0;
      line-height: 53px;

      @media (max-width: 768px) {
        font-size: 3em;
        line-height: 0.79;
        width: 100%;
      }
      @media (max-width: 486px) {
        font-size: 2em;
      }
    }

    p {
      margin: 32px 0;
      font-size: 1.5em;
      font-weight: normal;

      @media (max-width: 768px) {
        font-size: 1.125em;
        line-height: 1.56;
      }
    }
    :global(.button) {
      @media (max-width: 486px) {
        width: 100%;
      }
    }
  }

  .image {
    margin-left: 52px;
    position: relative;
    @media (max-width: 1280px) {
      margin-left: 24px;
    }
    @media (max-width: 1024px) {
      margin-left: 0;
      margin-bottom: 16px;
    }
    img {
      max-width: 397px;
      width: 397px;
      // height: 517px;
      @media (max-width: 1024px) {
        // max-width: 596px;
        width: 596px;
      }
      @media (max-width: 768px) {
        // max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
  }
}
