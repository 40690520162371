:local {
  .width {
    max-width: 924px;
  }
  .content {
    margin-bottom: 174px;
    z-index: 2;
    position: relative;
    padding-top: 154px;

    @media (max-width: 1024px) {
      margin-bottom: 32px;
      padding-top: 80px;
    }
    @media (max-width: 768px) {
      padding-top: 43px;
    }
    h1 {
      text-transform: uppercase;
      max-width: 857px;
      font-size: 4.125em;
      font-weight: 900;
      line-height: 0.8;
      margin: 0 0 24px 0;

      @media (max-width: 768px) {
        font-size: 3em;
        line-height: 0.94;
        width: 100%;
      }
    }

    h3 {
      font-size: 2.375em;
      font-weight: 800;
      margin: 50px 0 16px 0;
      line-height: 0.84;
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 1.75em;
        line-height: 1.14;
        margin: 25px 0 16px 0;
      }
    }

    p {
      margin: 0;
      font-size: 1.5em;
      line-height: 1.75;
      color: #ffffff;

      @media (max-width: 486px) {
        font-size: 1.25em;
        line-height: 1.5;
      }

      strong,
      a {
        font-weight: bold;
      }
    }
  }

  .threeblock {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 1134px;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      justify-content: initial;
    }

    .block {
      width: 275px;
      align-self: flex-end;

      @media (max-width: 1024px) {
        align-self: initial;
        margin-right: 100px;
      }
      &:nth-of-type(2n + 2) {
        @media (max-width: 1024px) {
          margin-right: 0;
        }
      }
      p {
        width: 300px;
        line-height: normal;
        font-size: 1.125em;
        font-weight: 300;
        color: #ffffff;

        b {
          font-weight: bolder;
        }
      }
    }
  }
}
