:local {
  .wrapper {
    position: relative;
    h2 {
      text-transform: uppercase;
      position: relative;
      left: 0;
      top: 0;
      font-size: 1.5em;
      font-weight: 500;
      line-height: 1.42;
      color: #0078e6;
      margin: 0;
      margin-bottom: -63px;
      @media (max-width: 486px) {
        font-size: 1.125em;
        margin-left: 16px;
        margin-top: 56px;
      }
    }
  }
}
