:global {
  /* Helpers */
  @value variables: "styles/variables.scss";
  @value defaultTransition from variables;
  /*
  1. Algemene style regels zet je hier neer
*/
  /* Flex helpers */
  /* Text styles */
  /* Margin */
  /* Padding */
  /* Width */
  /* Font weight */
  /* Font style */
  /* Font transform */
  /* Font decoration */
  /* Font align */
  /* Display */
  /* Text transforms */
  /* Base */
  @value variables: "styles/variables.scss";
  @value mainFont from variables;
  /* Layout */
  /* SimpleBar */ }
  :global img {
    max-width: 100%; }
  :global .clearfix:after {
    content: '';
    display: table;
    clear: both; }
  @media screen and (max-width: 768px) {
    :global .hide-mobile {
      display: none !important; } }
  :global .flex {
    display: flex; }
  :global .flex-1 {
    flex: 1; }
  :global .flex-ajbottom {
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-start; }
  :global .flex-ajtop {
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start; }
  :global .flex-center {
    align-items: center;
    align-content: center;
    justify-content: flex-start; }
  :global .flex-justify {
    justify-content: space-between; }
  :global .title {
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0; }
  :global .ls0 {
    letter-spacing: 0px; }
  :global .ls1 {
    letter-spacing: 1px; }
  :global .ls2 {
    letter-spacing: 2px; }
  :global .ls4 {
    letter-spacing: 4px; }
  :global .ls11 {
    letter-spacing: 11px; }
  :global .mt0 {
    margin-top: 0px; }
  :global .mt10 {
    margin-top: 10px; }
  :global .mt12 {
    margin-top: 12px; }
  :global .mt24 {
    margin-top: 24px; }
  :global .mt32 {
    margin-top: 32px; }
  :global .mt38 {
    margin-top: 38px; }
  :global .mt40 {
    margin-top: 40px; }
  :global .mt42 {
    margin-top: 42px; }
  :global .mt44 {
    margin-top: 44px; }
  :global .mt50 {
    margin-top: 50px; }
  :global .mt55 {
    margin-top: 55px; }
  :global .mt64 {
    margin-top: 64px; }
  :global .mt60 {
    margin-top: 60px; }
  :global .mt80 {
    margin-top: 80px; }
  :global .mt100 {
    margin-top: 100px; }
  :global .mt120 {
    margin-top: 120px; }
  :global .ml5 {
    margin-left: 5px; }
  :global .ml10 {
    margin-left: 10px; }
  :global .ml20 {
    margin-left: 20px; }
  :global .ml30 {
    margin-left: 30px; }
  :global .mr8 {
    margin-right: 8px; }
  :global .mr16 {
    margin-right: 16px; }
  :global .mr20 {
    margin-right: 20px; }
  :global .mr30 {
    margin-right: 30px; }
  :global .mr40 {
    margin-right: 30px; }
  :global .mb0 {
    margin-bottom: 0; }
  :global .mb8 {
    margin-bottom: 8px; }
  :global .mb16 {
    margin-bottom: 16px; }
  :global .mb24 {
    margin-bottom: 24px; }
  :global .mb32 {
    margin-bottom: 32px; }
  :global .mb35 {
    margin-bottom: 35px; }
  :global .mb38 {
    margin-bottom: 38px; }
  :global .mb42 {
    margin-bottom: 42px; }
  :global .mb48 {
    margin-bottom: 48px; }
  :global .mb50 {
    margin-bottom: 50px; }
  :global .mb64 {
    margin-bottom: 64px; }
  :global .mb80 {
    margin-bottom: 80px; }
  :global .mb100 {
    margin-bottom: 100px; }
  :global .pt20 {
    padding-top: 20px; }
  :global .pt40 {
    padding-top: 40px; }
  :global .pt50 {
    padding-top: 50px; }
  :global .pt100 {
    padding-top: 100px; }
  :global .pt150 {
    padding-top: 150px; }
  :global .pb10 {
    padding-bottom: 10px; }
  :global .pb50 {
    padding-bottom: 50px; }
  :global .pb70 {
    padding-bottom: 70px; }
  :global .pb100 {
    padding-bottom: 100px; }
  :global .pb150 {
    padding-bottom: 150px; }
  :global .pl20 {
    padding-left: 20px; }
  :global .pr20 {
    padding-right: 20px; }
  :global .w50 {
    width: 50%; }
  :global .f-light {
    font-weight: 300; }
  :global .f-regular {
    font-weight: 400; }
  :global .f-medium {
    font-weight: 500; }
  :global .f-bold {
    font-weight: 800; }
  :global .f-black {
    font-weight: 900; }
  :global .italic {
    font-style: italic; }
  :global .uppercase {
    text-transform: uppercase; }
  :global .line-through {
    text-decoration: line-through; }
  :global .underline {
    text-decoration: underline; }
  :global .td-none {
    text-decoration: none; }
  :global .ta-left {
    text-align: left; }
  :global .ta-center {
    text-align: center; }
  :global .ta-right {
    text-align: right; }
  :global .hidden {
    visibility: hidden;
    display: none;
    opacity: 0; }
  :global .d-block {
    display: block; }
  :global .lowercase {
    text-transform: lowercase; }
  :global .uppercase {
    text-transform: uppercase; }
  :global .disable-scrolling {
    overflow: hidden; }
  :global .title {
    font-size: 4.125em;
    line-height: 0.8;
    font-weight: 300;
    margin: 0;
    margin-left: -3px;
    text-transform: uppercase;
    transition: all 0.2s defaultTransition; }
    :global .title b {
      font-weight: 900; }
    @media (max-width: 768px) {
      :global .title {
        font-size: 3em; } }
  :global * {
    box-sizing: border-box; }
  :global html {
    height: 100%;
    width: 100%; }
  :global body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    cursor: default; }
  :global .react-reveal {
    z-index: 2;
    position: relative; }
  :global body {
    font-family: mainFont;
    font-size: 1em;
    line-height: 1;
    color: #000000; }
  :global h1,
  :global h2,
  :global h3,
  :global h4,
  :global h5,
  :global h6 {
    font-family: mainFont;
    font-weight: 600;
    color: white; }
  :global h1 {
    font-size: 3em;
    line-height: 1.25em; }
    @media screen and (max-width: 768px) {
      :global h1 {
        font-size: 1.75em; } }
  :global h2 {
    font-size: 2.5em;
    line-height: normal; }
    @media screen and (max-width: 768px) {
      :global h2 {
        font-size: 1.875em; } }
  :global h3 {
    font-size: 1.5em; }
    @media screen and (max-width: 768px) {
      :global h3 {
        font-size: 1.25em; } }
  :global h4 {
    font-size: 1.25em;
    font-weight: 500; }
    @media screen and (max-width: 768px) {
      :global h4 {
        font-size: 1em; } }
  :global p {
    font-size: 1.25em;
    line-height: 1.5; }
  :global a {
    text-decoration: none;
    color: currentColor; }
  :global ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1em;
    line-height: 1.5em; }
  :global .overflow-visible,
  :global .overflow-visible > * {
    overflow: visible !important; }
  :global body {
    overflow-x: hidden;
    position: relative;
    min-width: 320px; }
  :global body.disable-scroll {
    overflow: hidden; }
  :global main {
    width: 100%;
    margin-top: -76px;
    position: relative;
    overflow-x: hidden; }
    @media screen and (max-width: 1024px) {
      :global main {
        margin-top: 76px; } }
  @media screen and (max-width: 768px) {
    :global main.main--top {
      padding-top: 124px; } }
  :global main.blue {
    background: #0078e6; }
  :global .wrapper {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 32px);
    max-width: 1174px; }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      :global .wrapper {
        max-width: 783px; } }
    @media screen and (min-width: 768px) {
      :global .wrapper {
        width: calc(100% - 100px); } }
  :global #concreeto {
    /* feautured i18n */
    white-space: pre-line; }
    @media screen and (max-width: 1024px) {
      :global #concreeto {
        overflow-x: hidden; } }
  :global .simplebar-scrollbar {
    width: 3px;
    right: 1px; }
  :global .simplebar-scrollbar::before {
    border-radius: 0;
    opacity: 1;
    background: #0078e6; }
  :global .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1; }
  :global .simplebar-offset {
    right: -20px !important; }
  :global .simplebar-content-wrapper {
    padding-right: 20px !important; }
  :global .simplebar-track.simplebar-vertical {
    width: 5px; }
