@value variables: "styles/variables.scss";
@value blue,navy,defaultTransition from variables;
:local .top {
  pointer-events: none;
  position: relative;
  z-index: 2; }
  @media (max-width: 768px) {
    :local .top {
      pointer-events: initial; } }

@media (max-width: 486px) {
  :local .top :global(.button) {
    width: 100%; } }

@media (max-width: 1280px) {
  :local .top :global(.notch) {
    display: none; } }

:local .dummy {
  composes: sidebar from 'components/vacancy-detail/styles.scss'; }
  @media (max-width: 1280px) {
    :local .dummy {
      display: none; } }

:local .amount {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 100px;
  padding-top: 58px; }
  @media (max-width: 1024px) {
    :local .amount {
      margin-bottom: 56px; } }
  @media (max-width: 768px) {
    :local .amount {
      flex-direction: column;
      align-items: initial;
      margin-bottom: 24px;
      padding-top: 34px; } }
  @media (max-width: 486px) {
    :local .amount {
      width: 100%; } }

:local .amount-item {
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 196px; }
  @media (max-width: 768px) {
    :local .amount-item {
      margin-bottom: 18px; } }
  :local .amount-item:first-of-type {
    margin-left: 0; }
  :local .amount-item:last-of-type {
    margin-right: 48px; }
    @media (max-width: 768px) {
      :local .amount-item:last-of-type {
        margin-bottom: 34px; } }
  :local .amount-item span {
    font-size: 4.125em;
    line-height: 0.8;
    font-weight: 900;
    margin-right: 6px; }
  :local .amount-item b {
    font-size: 1.125em;
    font-weight: 800;
    line-height: 0.83; }

:local .wrapper {
  display: flex;
  position: relative;
  z-index: 2;
  margin-top: -220px; }
  @media (max-width: 1280px) {
    :local .wrapper {
      margin-top: 0; } }

:local .categories {
  display: flex;
  flex-flow: wrap;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: -16px; }

:local .category-item {
  background-color: white;
  padding: 34px 40px;
  width: calc(50% - 16px);
  margin: 16px 8px;
  margin-top: 0;
  display: flex;
  transition: background 0.2s defaultTransition, -webkit-transform 0.2s defaultTransition;
  transition: background 0.2s defaultTransition, transform 0.2s defaultTransition;
  transition: background 0.2s defaultTransition, transform 0.2s defaultTransition, -webkit-transform 0.2s defaultTransition; }
  :local .category-item:hover {
    background-color: #eaeaea;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px); }

:local .content {
  margin-left: 65px; }

:local .title {
  text-transform: uppercase;
  color: navy;
  font-weight: 800;
  line-height: 0.84;
  margin: 0;
  font-size: 2.375em; }

:local .description {
  margin: 0;
  margin-top: 6px;
  line-height: 1.17;
  font-size: 1.5em;
  color: navy; }

:local .iconname {
  width: -webkit-fit-content;
  width: fit-content;
  font-size: 1.125em;
  font-weight: 800;
  line-height: 0.94;
  color: #143264;
  padding: 11px; }
