@value variables: "styles/variables.scss";
@value blue, navy, defaultTransition from variables;
:local {
  .filter {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    z-index: 3;
    @media (max-width: 1280px) {
      flex-wrap: wrap;
    }
    @media (max-width: 768px) {
      flex-flow: column;
      margin-left: -16px;
      margin-right: -16px;
    }
  }
  .search {
    cursor: pointer;
    background: navy;
    color: white;
    height: 90px;
    // padding: 0 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.125em;
    font-weight: bold;
    min-width: 175px;
    user-select: none;
    @media (max-width: 768px) {
      width: 100%;
      height: 63px;
    }
    span {
      max-width: 102px;
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
    svg {
      margin-right: 8px;
      min-width: 15px;
      height: 15px;
    }
  }
  .filter-loading {
    height: 90px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .filter--alert {
    flex-wrap: wrap;
    @media (max-width: 768px) {
      flex-flow: wrap;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
