:local {
  .intro {
    position: relative;
    z-index: 2;
    font-size: 1.5em;
    left: -1px;
    font-weight: 300;
    line-height: 1.67;
    color: #000000;
    margin: 0;
    margin-bottom: 48px;
    @media (max-width: 900px) {
      font-size: 1.375em;
      line-height: 1.56;
    }
    @media (max-width: 486px) {
      font-size: 1.25em;
    }
  }
  .description {
    position: relative;
    z-index: 2;
    left: -1px;
    display: flex;
    flex-direction: column;
    h2 {
      font-family: Mark;
      font-size: 2.375em;
      font-weight: 900;
      line-height: 0.84;
      color: #143264;
      margin-top: 0;
      text-transform: uppercase;
      hyphens: auto;
      &:not(:first-of-type) {
        margin-top: 44px;
        @media (max-width: 486px) {
          margin-top: 24px;
        }
      }
    }
    p {
      font-family: Mark;
      font-size: 1.125em;
      line-height: 1.56;
      color: #000000;
      margin: 0;
      @media (max-width: 900px) {
        line-height: 1.56;
      }
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    ul {
      margin-bottom: 24px;
      margin-left: 24px;
      li {
        min-height: 28px;
        margin-bottom: 5px;
        font-size: 1.125em;
        color: #000000;
        position: relative;
        padding-left: 44px;
        &:last-of-type {
          margin-bottom: 0px;
        }
        span {
          position: absolute;
          left: 0;
          top: 0;
        }
        svg {
          margin-right: 16px;
        }
      }
    }
  }
  .iconcheck {
    margin-right: 16px;
  }
}
