:local {
  .item {
    background-color: #143264;
    width: 380px;
    padding: 42px;
    // height: 380px;
    margin-right: 16px;
    z-index: 2;
    @media (max-width: 1280px) {
      // height: 280px;
      padding: 32px;
      width: calc(50% - 8px);
    }
    @media (max-width: 1024px) {
      width: 100%;
      margin-bottom: 16px;
      padding: 48px;
    }
    @media (max-width: 768px) {
      padding: 24px;
    }
    &:last-of-type {
      margin-right: 0;
      @media (max-width: 1024px) {
        margin-bottom: 0;
      }
    }
  }

  .title {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 0.84;
    color: #ffffff;
    margin: 0;
    margin-bottom: 57px;
    text-transform: uppercase;
    hyphens: auto;
    @media (max-width: 768px) {
      font-size: 1.75em;
      margin-bottom: 16px;
    }
  }

  .subtitle {
    width: 297px;
    font-family: Mark;
    font-size: 1.125em;
    color: #ffffff;
    text-transform: uppercase;
  }

  .icon {
    margin-bottom: 25px;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  .icon,
  .icon path,
  .icon g {
    fill: white;
  }
}
