:local {
  .flex {
    display: flex;
  }
  .block {
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 120px;
    @media (max-width: 960px) {
      justify-content: flex-start;
      flex-direction: column;
      height: auto;
    }
    @media (max-width: 768px) {
      margin-bottom: 56px;
    }
  }

  .content {
    width: 80%;
    margin-top: 32px;
    margin-right: 32px;
    @media (max-width: 960px) {
      margin-right: 0;
      margin-bottom: 48px;
    }
    h3 {
      color: #0078e6;
      font-weight: 500;
      margin: 4px;
      text-transform: uppercase;
      font-size: 1.5em;

      @media (max-width: 768px) {
        font-size: 1.125em;
      }
    }

    h1 {
      color: #143264;
      font-size: 4.125em;
      font-weight: 900;
      margin: 0 0 24px 0;
      line-height: 53px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 3em;
        line-height: 0.79;
      }
    }

    p {
      margin: 32px 0;
      font-size: 1.5em;
      font-weight: normal;

      @media (max-width: 768px) {
        font-size: 1.125em;
        line-height: 1.33;
        width: calc(100% - 54px);
      }
      @media (max-width: 486px) {
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      margin-top: 48px;
    }
  }

  .route {
    display: flex;
    flex-direction: column;
    position: relative;
    left: 0;
    align-items: center;
  }

  .circle {
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 90px;
    background: #0078e6;
    z-index: 2;
    margin-top: 240px;
    @media (max-width: 369px) {
      width: 30px;
      height: 30px;
    }
  }

  .numbers {
    background: #0078e6;
    width: 48px;
    height: 48px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.375em;
    font-weight: 700;
    position: relative;
    @media (max-width: 369px) {
      width: 30px;
      height: 30px;
    }
  }

  .number-1 {
    &::after {
      content: '';
      background: #0078e6;
      width: 3px;
      height: 330px;
      position: absolute;
      top: 48px;
      @media (max-width: 369px) {
        top: 30px;
        height: 312px;
      }
    }
  }

  .number-2 {
    margin-top: 42px;
  }

  .border {
    width: 0px;
    height: 192px;
    border-color: #b2b1b1;
    border-style: dashed;
    border-width: 2px;
    position: relative;

    &::after {
      content: '';
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.9)
      );
      position: absolute;
      height: 208px;
      width: 8px;
      bottom: -4px;
      left: -4px;
    }
    @media (max-width: 486px) {
      align-self: center;
    }
  }

  .roadmap {
    display: flex;
    flex-direction: column;
    margin-left: calc(48px + 24px);
    @media (max-width: 768px) {
      margin-left: 24px;
    }
  }

  .step {
    width: 350px;
    height: 345px;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      background: #143264;
      width: 62px;
      height: 2px;
      left: -62px;
      top: 21px;

      @media (max-width: 768px) {
        width: 15px;
        left: -15px;
      }
    }
    @media (max-width: 486px) {
      width: calc(100%);
      height: 324px;
    }
    @media (max-width: 369px) {
      width: 227px;
      height: 100%;
    }
  }

  .step-content {
    background: #143264;
    color: white;
    padding: 33px 40px;

    h2 {
      font-size: 1.5em;
      line-height: 24px;
      margin: 0 0 16px 0;
      font-weight: 800;
      text-transform: uppercase;
      @media (max-width: 486px) {
        font-size: 1.375em;
        line-height: 1;
      }
    }

    p {
      font-size: 1.125em;
      line-height: 24px;
      margin: 0;
      @media (max-width: 486px) {
        font-size: 1em;
        line-height: 1.25;
      }
    }
    @media (max-width: 486px) {
      padding: 24px 24px;
    }
    @media (max-width: 369px) {
      padding: 10px 10px;
    }
  }

  .step-image {
    margin-top: 36px;

    h2 {
      color: white;
      position: absolute;
      z-index: 2;
      bottom: 0;
      margin-left: 32px;
      margin-right: 32px;
      font-size: 1.75em;
      text-transform: uppercase;
      font-weight: 800;
      @media (max-width: 768px) {
        margin-left: 24px;
        margin-right: 24px;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      mix-blend-mode: multiply;
      background-blend-mode: multiply, multiply;
      background-image: linear-gradient(
          to bottom,
          rgba(0, 120, 230, 0.8),
          rgba(0, 120, 230, 0.8)
        ),
        linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    }
    @media (max-width: 486px) {
      margin-top: 52px;
    }
    @media (max-width: 369px) {
      margin-top: 42px;
    }
  }

  .videopreview {
    align-items: center;
    background-color: #eef;
    background-image: url(images/bedrijfsfilmpreview.jpg);
    background-size: cover;
    display: flex;
    height: 517px;
    justify-content: center;
    margin-top: 32px;
    width: 397px;

    .playbutton {
      background-color: white;
      background-image: url(icons/playbutton.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 18px auto;
      display: block;
      height: 50px;
      width: 50px;
    }
  }
}
