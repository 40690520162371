@value variables: "styles/variables.scss";
@value blue from variables;

:local {
  .form {
    // margin-left: 48px;
    padding: 0 48px;
    position: relative;
    color: black;
    font-weight: bold;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 32px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @media (max-width: 486px) {
      width: 100%;
      padding: 0 16px;
      height: 100%;
    }

    .names {
      display: flex;
      justify-content: space-between;

      label {
        input {
          width: 180px;
          @media (max-width: 486px) {
            width: initial;
          }
        }
      }
      @media (max-width: 486px) {
        flex-direction: column;
      }
    }

    label {
      display: flex;
      flex-direction: column;
      @media (max-width: 486px) {
        width: calc(100% - 34px);
      }
    }
  }
  .form--blue {
    background: blue;
  }

  .input-wrap {
    width: 400px;
    @media (max-width: 486px) {
      width: 100%;
    }
  }

  .progressbar {
    content: "";
    position: absolute;
    transition: width 100ms ease;
    background: blue;
    height: 3px;
    bottom: 0;
    width: 0;
    z-index: 3;
    @media (max-width: 786px) {
      display: none;
    }
  }

  .progressbar-step-1 {
    width: 50%;
  }

  .progressbar-step-2 {
    width: 100%;
  }

  .back {
    width: 85px;
    font-size: 1.125em;
    color: blue;
    font-weight: bold;
    cursor: pointer;
    margin-top: 24px;
    margin-left: 7px;

    svg {
      position: relative;
      margin-right: 8px;
      transform: rotate(180deg);
      right: 0;
      transition: right 100ms ease;
    }

    &:hover {
      svg {
        right: 8px;
      }
    }
  }
  .error {
    color: #ff7373;
    font-size: 1em;
    line-height: 1;
    // margin-top: 24px;
    margin-bottom: 24px;
    font-weight: normal;
  }
  .error span {
    display: block;
    margin-bottom: 4px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .error span svg {
    margin-right: 8px;
  }
}
