@value variables: "styles/variables.scss";
@value blue,navy,red from variables;
:local {
  .notice {
    background: white;
    user-select: none;
    color: red;
    padding: 32px 0 20px;
    position: relative;
    z-index: 999;
    @media (max-width: 1024px) {
      margin-top: 76px;
      margin-bottom: -76px;
    }
  }

  .wrapper {
    padding: 0 56px;
    @media (min-width: 768px) and (max-width: 1280px) {
      padding: 0 26px;
    }
    @media (max-width: 768px) {
      padding: 0 16px;
    }
  }
  .title {
    margin: 0;
    font-weight: 800;
    font-size: 1.25em;
    color: red;
    text-transform: uppercase;
    @media (max-width: 768px) {
      padding-right: 74px;
    }
  }
  .content{
    display: flex;
    width: 100%;
    @media (max-width: 1280px) {
      flex-direction: column;
    }
  }
  .description {
    line-height: 1.56;
    margin-bottom: 0;
    margin-top: 8px;
    letter-spacing: 0;
    font-size: 1em;
    display: inline;
    @media (max-width: 1280px) {
      padding-right: 74px;
    }
    @media (max-width: 600px) {
      padding-right: 0;
    }
    
  }
  .description a{
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }

  .btn-container{
    display: flex;
    margin-left: 24px;
    @media (max-width: 1280px) {
      margin-left: 0;
      margin-top: 24px;
    }
    @media (max-width: 364px) {
      flex-direction: column;
    }
  }
  .btn-container > *{
    margin-right: 8px;
    @media (max-width: 364px) {
      margin-right: 0;
      width: 100%;
      margin-bottom: 16px;
    }
    &:last-child{
      margin-bottom: 0;
      margin-right: 0;
    }
  }

}
