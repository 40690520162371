:local {
  .background {
    background: white;
    margin-bottom: 282px;
    @media (max-width: 1024px) {
      margin-bottom: 182px;
    }
    @media (max-width: 768px) {
      margin-bottom: 52px;
    }
  }

  .block {
    z-index: 2;
    display: flex;
    position: relative;
    // justify-content: space-between;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .item {
    min-width: 392px;
    @media (max-width: 1024px) {
      min-width: 1px;
      margin-top: 69px;
      margin-bottom: 80px;
      // width: 50%;
    }
    @media (max-width: 768px) {
      margin-top: 32px;
      margin-bottom: 32px;
    }
    &:last-of-type {
      @media (max-width: 768px) {
        margin-bottom: 0;
      }
    }
    span {
      font-size: 1.9375em;
      font-weight: 900;
      line-height: 0.45;
      text-transform: uppercase;
      position: relative;

      @media (max-width: 768px) {
        font-size: 1.5em;
        line-height: 0.58;
      }

      .rotate {
        transform-origin: left;
        transform: rotate(270deg) translate(0, 0%);
        text-transform: uppercase;
        font-weight: 900;
        white-space: nowrap;
        margin-right: 100%;
        display: flex;
        align-items: center;
        align-self: flex-start;
        position: absolute;
        left: calc(100% + 18px);
        bottom: 0;
      }

      .countup {
        margin: 0 0 79px 0;
        color: black;
        font-size: 200px;
        font-weight: 800;
        display: inline-block;
        left: -5px;
        @media (max-width: 768px) {
          font-size: 150px;
          margin: 0 0 60px 0;
        }
      }
    }

    h2 {
      width: 276px;
      margin: 0;
      margin-top: -10px;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 38px;
      color: #0078e6;
      line-height: 0.84;
      @media (max-width: 768px) {
        font-size: 1.75em;
        // line-height: 1.14;
      }
      @media (max-width: 486px) {
        width: 100%;
      }
      br {
        @media (max-width: 1024px) {
          display: none;
        }
      }
    }
  }
}
