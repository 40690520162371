:local {
  .image {
    width: 186px;
    height: 186px;
    margin: 0;
    background: #eee;
  }

  .title {
    font-size: 1.5em;
    font-weight: 900;
    line-height: 0.71;
    letter-spacing: normal;
    color: #143264;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .contact {
    // width: 377px;
    font-size: 18px;
    font-weight: 800;
    line-height: 0.94;
    letter-spacing: normal;
    color: #0078e6;
    margin-top: 24px;
  }
  .contact li {
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .contact li a {
    display: flex;
    align-items: center;
  }
  .contact li svg {
    margin-right: 8px;
  }
  .contact li span {
    text-transform: uppercase;
  }
  .contact li :global(.button) span {
    text-transform: none;
  }

  .subtitle {
    width: 226px;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
  }
}
