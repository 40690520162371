:local {
  .label {
    display: flex;
    flex-direction: column;
  }

  .input {
    padding: 10px;
    font-size: 1.125em;
    margin-top: 8px;
    border: #d5d5d5 solid 1px;
    height: 48px;
    outline: none;
    border-radius: 0;

    &:last-of-type {
      margin-bottom: 24px;
      @media (max-width: 486px) {
        margin-bottom: 16px;
      }
    }
  }
  .invalid {
    border-color: #ff7373;
  }
  .valid {
    border-color: #00dc32;
  }
}
