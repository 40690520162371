:local {
  .wrapper {
    padding: 48px 0;
    position: relative;
  }
  .wrapper p {
    max-width: 680px;
    margin-top: 0;
    margin-bottom: 32px;
  }
  .wrapper :global(.button) {
    margin-bottom: 0;
  }
}
