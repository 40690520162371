@value variables: "styles/variables.scss";
@value defaultTransition from variables;
:local {
  .image-cta {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    color: white;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      mix-blend-mode: multiply;
      background-blend-mode: multiply, multiply;
      background-image: linear-gradient(
          to bottom,
          rgba(0, 120, 230, 0.5),
          rgba(0, 120, 230, 0.5)
        ),
        linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    }
  }
  .image-cta :global(.notch) {
    z-index: 1;
  }
  .wrapper {
    position: relative;
    z-index: 2;
    padding-top: 78px;
    padding-bottom: 96px;
    @media (max-width: 768px) {
      padding-top: 40px;
      padding-bottom: 48px;
    }
  }

  .title {
    composes: title from global;
    hyphens: auto;
  }

  .description {
    font-size: 1.5em;
    line-height: 1.75;
    max-width: 550px;
    margin-top: 8px;
    margin-bottom: 22px;
    @media (max-width: 768px) {
      font-size: 1.125em;
      line-height: 1.33;
    }
  }
  .image-cta :global(.button) {
    @media (max-width: 480px) {
      width: 100%;
    }
  }

  .image-cta :global(.notch) {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .image-cta :global(.wrapper) {
  }
}
