:local {
  .member {
    display: flex;
    margin-bottom: 24px;
    @media (max-width: 800px) {
      flex-flow: column;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .imagewrapper {
      max-width: 550px;

      width: 100%;
      height: 100%;
      position: relative;
      @media (max-width: 1024px) {
        width: 434px;
      }
      @media (max-width: 768px) {
        width: 534px;
      }
      @media (max-width: 690px) {
        width: 100%;
      }
      h3 {
        width: 100%;
        margin: 16px;
        text-align: right;
        text-transform: uppercase;
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 1.75em;
        font-weight: 300;
        line-height: 0.96;
        letter-spacing: 0.6px;
        color: #ffffff;
        z-index: 2;

        @media (max-width: 768px) {
          font-size: 1.5em;
          line-height: 1.33;
          letter-spacing: 0.5px;
        }
      }

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        mix-blend-mode: multiply;
        background-blend-mode: multiply, multiply;
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.15),
          rgba(0, 0, 0, 0.15)
        );
      }
    }

    .content {
      margin-left: 45px;
      @media (max-width: 1024px) {
        margin-left: 24px;
        width: calc(100% - 434px - 24px);
      }
      @media (max-width: 800px) {
        margin-left: 0;
        width: 100%;
      }
      h3 {
        text-transform: uppercase;
        font-size: 2.375em;
        font-weight: 800;
        line-height: 0.96;
        letter-spacing: 0.6px;
        line-height: 0.84;
        color: #143264;
        margin: 28px 0 24px 0;

        @media (max-width: 768px) {
          width: 100%;
          font-size: 1.75em;
          line-height: 0.86;
          letter-spacing: normal;
        }
      }

      p {
        max-width: 489px;
        font-size: 1.125em;
        line-height: 1.33;
        color: #000000;
        margin: 0;

        @media (max-width: 768px) {
          line-height: 1.56;
          width: 100%;
        }
      }
    }

    @media (max-width: 486px) {
      flex-direction: column;
    }
  }
}
