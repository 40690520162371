@font-face {
  font-family: 'Mark';
  src: url('./Mark-Heavy.woff2') format('woff2'),
    url('./Mark-Heavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./Mark-Black.woff2') format('woff2'),
    url('./Mark-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./Mark-Bold.woff2') format('woff2'),
    url('./Mark-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./Mark-Medium.woff2') format('woff2'),
    url('./Mark-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./Mark-Light.woff2') format('woff2'),
    url('./Mark-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./Mark-Regular.woff2') format('woff2'),
    url('./Mark-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
