

._3zA5NJgH86lpV9lzvOW7tN {
  height: 76px;
  transition: background-color 300ms ease;
  border-bottom: solid 1px #ffffff;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: bold;
  font-size: 18px;
  z-index: 998;
  position: sticky;
  width: 100%;
  top: 0; }
  @media (max-width: 1024px) {
    ._3zA5NJgH86lpV9lzvOW7tN {
      position: fixed;
      background-color: white; } }
  @media (max-width: 700px) {
    ._3zA5NJgH86lpV9lzvOW7tN {
      transition: none;
      border-bottom: none; } }

@media (max-width: 1024px) {
  ._1osf82VvUOscmzbdgfkzg._27xxAYe1m2d9AWUYVEGREq {
    visibility: hidden; } }

._1osf82VvUOscmzbdgfkzg._27xxAYe1m2d9AWUYVEGREq svg {
  fill: #0078e6; }
  ._1osf82VvUOscmzbdgfkzg._27xxAYe1m2d9AWUYVEGREq svg g {
    fill: #0078e6; }

._1osf82VvUOscmzbdgfkzg._27xxAYe1m2d9AWUYVEGREq p {
  color: #143264; }

._1osf82VvUOscmzbdgfkzg {
  display: flex;
  padding-top: 24px;
  padding-left: 15px;
  padding-bottom: 5px;
  align-items: center; }
  @media (max-width: 1024px) {
    ._1osf82VvUOscmzbdgfkzg {
      position: absolute;
      top: 76px;
      visibility: visible; } }
  ._1osf82VvUOscmzbdgfkzg svg {
    width: 31px;
    height: 44px;
    fill: white; }
  ._1osf82VvUOscmzbdgfkzg p {
    font-size: 16px;
    color: white;
    width: 247px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
    margin-left: 8px;
    line-height: 0.9; }
  ._1osf82VvUOscmzbdgfkzg p._3QdsjoHnRyaBQj--QJ7JzW {
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    font-style: 300; }

._2MsNIoveq1lG1HE8tQ7rZ1 {
  background-color: rgba(0, 0, 0, 0); }
  @media (max-width: 1024px) {
    ._2MsNIoveq1lG1HE8tQ7rZ1 {
      background-color: white; } }

.NoJpZpc403CInh86JE4kC {
  background-color: #0078e6; }
  @media (max-width: 1024px) {
    .NoJpZpc403CInh86JE4kC {
      background-color: white; } }

._3Sadg1nK1uP98rLw89vyqt {
  background-color: white; }
  @media (max-width: 1024px) {
    ._3Sadg1nK1uP98rLw89vyqt {
      background-color: white; } }

._2PR2kqlo2yfOVP9mIb3mzT {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 100%; }
  @media (max-width: 1024px) {
    ._2PR2kqlo2yfOVP9mIb3mzT {
      position: relative; } }

.dsBBswLyg4TiSc4aXWiMW {
  display: flex;
  align-items: center;
  height: 75px;
  justify-content: center;
  color: white;
  width: 100%;
  max-width: 172px;
  font-size: 1.125em; }
  .dsBBswLyg4TiSc4aXWiMW ._2cjKoE3n8SkiK-fRfTHAy2 {
    margin-right: 8px;
    margin-top: 3px;
    width: 22px;
    height: 15px; }
  .dsBBswLyg4TiSc4aXWiMW ._3-jsL360waCk2Yqw3M33SE > g {
    fill: #143264; }
  .dsBBswLyg4TiSc4aXWiMW ._3FSn-j1Xf4ViCHlCkiSOV9 > g {
    fill: #0078e6; }

._253N37JMlx4UnxLQ27FjIr {
  background-color: #0078e6; }

._3AvT_UG81sj91OS-5Wl80P {
  background-color: #143264; }

._1qb95T1XnTaOL8kcGyDV5z {
  transition: width 200ms ease;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 204px; }

._33bkgxDy0O_bXdVnPLtvEY {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 18px; }
  ._33bkgxDy0O_bXdVnPLtvEY ._3TAQDjB9Vi7-6_M4gYKlSE {
    padding-right: 17.5px;
    color: white; }
    @media (max-width: 1040px) {
      ._33bkgxDy0O_bXdVnPLtvEY ._3TAQDjB9Vi7-6_M4gYKlSE {
        display: none; } }
  ._33bkgxDy0O_bXdVnPLtvEY ._2Cf6fSd3alErxVdZNoFC69 {
    color: #0078e6; }
  ._33bkgxDy0O_bXdVnPLtvEY ._1J_7m-uH9kmM89eKGnwrme {
    display: flex;
    align-items: center;
    height: 75px;
    padding: 0 35px;
    padding-left: 17.5px; }
    @media (max-width: 1280px) {
      ._33bkgxDy0O_bXdVnPLtvEY ._1J_7m-uH9kmM89eKGnwrme {
        display: none; } }
    ._33bkgxDy0O_bXdVnPLtvEY ._1J_7m-uH9kmM89eKGnwrme .wNtxU1gKRHqhxHaNF_mbR {
      margin-left: 8px;
      left: 0;
      top: 1px;
      transition: left 100ms ease;
      position: relative; }
    ._33bkgxDy0O_bXdVnPLtvEY ._1J_7m-uH9kmM89eKGnwrme:hover .wNtxU1gKRHqhxHaNF_mbR {
      left: 8px; }
    ._33bkgxDy0O_bXdVnPLtvEY ._1J_7m-uH9kmM89eKGnwrme ._2Qf6pJ_TXxCfhDyDe9_i3r > g {
      fill: #0078e6; }
    ._33bkgxDy0O_bXdVnPLtvEY ._1J_7m-uH9kmM89eKGnwrme ._3-is27fEDdybUkpcWJ3X54 > g {
      fill: white; }
  ._33bkgxDy0O_bXdVnPLtvEY ._6l77b-mOgxWu6qF0licW1 {
    background-color: #ffffff;
    color: #0078e6; }
  ._33bkgxDy0O_bXdVnPLtvEY ._2I39Pb4Dq66Xt6Z7l7ldSl {
    background-color: #143264;
    color: white; }
  ._33bkgxDy0O_bXdVnPLtvEY ._3zO9PJxaLp2rFTAkY9lfng {
    display: flex;
    align-items: center;
    height: 75px;
    padding: 0 36px; }
    @media (max-width: 700px) {
      ._33bkgxDy0O_bXdVnPLtvEY ._3zO9PJxaLp2rFTAkY9lfng {
        display: none; } }
    ._33bkgxDy0O_bXdVnPLtvEY ._3zO9PJxaLp2rFTAkY9lfng ._2FNalMz1Y6prfcqs0czis {
      margin-right: 8px; }
    ._33bkgxDy0O_bXdVnPLtvEY ._3zO9PJxaLp2rFTAkY9lfng .uLlZ0IfFBcB1g2O2dz6Ar > g {
      fill: #143264; }
    ._33bkgxDy0O_bXdVnPLtvEY ._3zO9PJxaLp2rFTAkY9lfng ._3tMhe0sORw1Zls48X9a-wq > g {
      fill: #0078e6; }
  ._33bkgxDy0O_bXdVnPLtvEY ._3hE5SqeUH2yPUgrUI8xE-5 {
    background-color: #0078e6;
    color: white; }
  ._33bkgxDy0O_bXdVnPLtvEY ._2vU97T5WasOXc3Bkx22OTZ {
    background-color: white;
    color: #0078e6; }

.ie ._3zA5NJgH86lpV9lzvOW7tN {
  position: fixed;
  width: 100%; }

._1aAvOKKrVkNltEqFf11jjD {
  align-items: center;
  align-self: center;
  border-left: solid white 1px;
  background-color: #0078e6;
  color: white;
  display: flex;
  flex-flow: row;
  height: 100%;
  justify-self: center;
  letter-spacing: 0.9px;
  position: relative;
  text-transform: uppercase;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 80px;
  z-index: 2; }

._2W-MT1Bmz9YR5eCsq9krmX {
  display: flex;
  padding-left: 6px; }
  ._2W-MT1Bmz9YR5eCsq9krmX ._38iD7bqHfRZ0L17fpoaZj7 {
    margin-left: 6px; }
    ._2W-MT1Bmz9YR5eCsq9krmX ._38iD7bqHfRZ0L17fpoaZj7 svg {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

._2Ii8-Aq1Y-_YgVvh00fIsp {
  cursor: pointer;
  font-weight: 1600;
  margin-left: 10px;
  position: relative;
  z-index: 2; }
  ._2Ii8-Aq1Y-_YgVvh00fIsp::before {
    background-color: #0078e6;
    border-radius: 10px;
    content: "";
    height: 100%;
    left: -10px;
    position: absolute;
    top: 2px;
    width: 44px;
    z-index: -1; }
  ._2Ii8-Aq1Y-_YgVvh00fIsp::after {
    background: currentColor;
    bottom: -2px;
    content: "";
    height: 1.5px;
    left: 0;
    position: absolute;
    transition: all 300ms ease;
    width: 0; }

.hW4Vu4ccHRRuA7lKqtQD8::after {
  width: 100%; }

._2l7lpEdaqNSK6tHyW9S59p {
  background-color: white;
  color: #0078e6;
  overflow: hidden;
  left: 0;
  padding: 0;
  position: absolute;
  top: calc(100% - 80px);
  transition: all 300ms ease;
  width: 100%;
  z-index: 3; }
  ._2l7lpEdaqNSK6tHyW9S59p li {
    text-align: center;
    cursor: pointer;
    margin-top: 10px; }
    ._2l7lpEdaqNSK6tHyW9S59p li svg {
      height: 100%;
      width: 80%; }
  ._2l7lpEdaqNSK6tHyW9S59p ._38iD7bqHfRZ0L17fpoaZj7 {
    margin-bottom: 10px; }
    ._2l7lpEdaqNSK6tHyW9S59p ._38iD7bqHfRZ0L17fpoaZj7 svg {
      height: 40%;
      width: 50%; }

._3PAk55W9k2h6T1pB9nX_-q {
  opacity: 0;
  -webkit-transform: translateY(-16px);
          transform: translateY(-16px); }

._20Fd5HK-q_PRwoqRiy1ct4 {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, transform 200ms;
  transition: opacity 200ms, transform 200ms, -webkit-transform 200ms; }

._2MaXKf2a_GJ-O3vFXOX4GC {
  opacity: 1; }

._372yDQpzHulzZOSR9Ru1bj {
  opacity: 0;
  -webkit-transform: translateY(-16px);
          transform: translateY(-16px);
  transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, transform 200ms;
  transition: opacity 200ms, transform 200ms, -webkit-transform 200ms; }

._1-YZ2Fj59Uu9Ve5nfvnjzv {
  background: #143264;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: white;
  padding: 32px 0;
  position: relative;
  z-index: 999; }
  @media (max-width: 1024px) {
    ._1-YZ2Fj59Uu9Ve5nfvnjzv {
      margin-top: 76px;
      margin-bottom: -76px; } }

.MRrDUrqVA3XCLS-J6QaYT {
  padding: 0 56px;
  max-width: 1163px; }
  @media (min-width: 768px) and (max-width: 1280px) {
    .MRrDUrqVA3XCLS-J6QaYT {
      padding: 0 26px; } }
  @media (max-width: 768px) {
    .MRrDUrqVA3XCLS-J6QaYT {
      padding: 0 16px; } }

._3sBRDh92v5BMpjfo6s8AQH {
  margin: 0;
  font-weight: 800;
  font-size: 1.25em;
  text-transform: uppercase; }
  @media (max-width: 768px) {
    ._3sBRDh92v5BMpjfo6s8AQH {
      padding-right: 74px; } }

._3DwNXapU4Vg1kG2TOUrahX {
  line-height: 1.56;
  margin-bottom: 0;
  margin-top: 8px;
  letter-spacing: 0;
  font-size: 1em; }
  @media (max-width: 1280px) {
    ._3DwNXapU4Vg1kG2TOUrahX {
      padding-right: 74px; } }
  @media (max-width: 600px) {
    ._3DwNXapU4Vg1kG2TOUrahX {
      padding-right: 0; } }

._1r0o5vieAuRXQEgrWRo7DU {
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 3;
  background: #0078e6; }
  ._1r0o5vieAuRXQEgrWRo7DU svg {
    width: 25px;
    height: 25px; }
    @media (max-width: 768px) {
      ._1r0o5vieAuRXQEgrWRo7DU svg {
        width: 19px;
        height: 19px; } }
    ._1r0o5vieAuRXQEgrWRo7DU svg g {
      fill: white; }
  @media (max-width: 600px) {
    ._1r0o5vieAuRXQEgrWRo7DU {
      width: 60px;
      height: 60px; } }

._2G99FJF2ysVYPB7vu1qS1D {
  border: 0;
  padding: 0;
  position: relative;
  background: none;
  display: inline-block;
  font-weight: bold; }
  ._2G99FJF2ysVYPB7vu1qS1D::after {
    content: "";
    width: 100%;
    height: 0px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.2); }
  ._2G99FJF2ysVYPB7vu1qS1D:focus {
    outline: none; }

span._1408PHfeVq5hbU5DQqY--o {
  padding: 0 15px;
  height: 41px;
  min-height: 41px;
  position: relative;
  color: #172b48;
  z-index: 2;
  text-decoration: none;
  font-size: 1em;
  font-weight: bold;
  width: 100%;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: none;
  border: 0;
  min-width: 100%;
  height: 100%;
  letter-spacing: 0;
  text-align: center;
  transition: 0.2s all ease-in-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: white;
  margin-left: 0;
  text-align: center;
  white-space: inherit; }
  @media (max-width: 768px) {
    span._1408PHfeVq5hbU5DQqY--o {
      font-size: 0.9375em;
      padding: 0 10px; } }
  @media (max-width: 486px) {
    span._1408PHfeVq5hbU5DQqY--o {
      font-size: 1em; } }

._2G99FJF2ysVYPB7vu1qS1D svg {
  margin-left: 8px;
  position: relative;
  transition: all 100ms ease;
  left: 0;
  top: 1px; }

._2G99FJF2ysVYPB7vu1qS1D ._1408PHfeVq5hbU5DQqY--o span {
  margin-left: 8px;
  text-align: left;
  white-space: nowrap; }

._16_IdWwgNCRZTMAIFeHRGX {
  width: 270px;
  height: 63px;
  min-height: 63px; }
  ._16_IdWwgNCRZTMAIFeHRGX ._1408PHfeVq5hbU5DQqY--o {
    font-size: 18px;
    font-weight: bold; }

._20NZKTdYJncJ8_ueozAI5F ._1408PHfeVq5hbU5DQqY--o {
  background: #0078e6;
  color: white; }

._20NZKTdYJncJ8_ueozAI5F:hover svg {
  left: 8px; }

._20NZKTdYJncJ8_ueozAI5F:hover ._1408PHfeVq5hbU5DQqY--o {
  background: #0062bb; }

._20NZKTdYJncJ8_ueozAI5F:active ._1408PHfeVq5hbU5DQqY--o {
  background: #143264; }

._32-yeCOZnB0ntAARXv0OIF ._1408PHfeVq5hbU5DQqY--o {
  background: white;
  color: #0078e6; }

._32-yeCOZnB0ntAARXv0OIF:hover svg {
  left: 8px; }

._32-yeCOZnB0ntAARXv0OIF:hover ._1408PHfeVq5hbU5DQqY--o {
  background: #dbdbdb; }

._32-yeCOZnB0ntAARXv0OIF:active ._1408PHfeVq5hbU5DQqY--o {
  background: #dbdbdb; }

._2ZfkyshNlHHiwaSuqbYUpQ ._1408PHfeVq5hbU5DQqY--o {
  background: transparent;
  color: #0078e6; }

._2ZfkyshNlHHiwaSuqbYUpQ:hover svg {
  left: 8px; }

._2ZfkyshNlHHiwaSuqbYUpQ:hover ._1408PHfeVq5hbU5DQqY--o {
  background: transparent; }

._2ZfkyshNlHHiwaSuqbYUpQ:active ._1408PHfeVq5hbU5DQqY--o {
  background: #dbdbdb; }

._1BrsxN4q9tw0O5F5Ol7zZN ._1408PHfeVq5hbU5DQqY--o {
  background: #00dc32;
  color: white; }

._1BrsxN4q9tw0O5F5Ol7zZN:hover svg {
  left: 8px; }

._1BrsxN4q9tw0O5F5Ol7zZN:hover ._1408PHfeVq5hbU5DQqY--o {
  background: #00a425; }

._1BrsxN4q9tw0O5F5Ol7zZN:active ._1408PHfeVq5hbU5DQqY--o {
  background: #007a1b; }

.XFjk-ENBRaIwfFX1UPD1I {
  width: 133;
  height: 63px; }
  .XFjk-ENBRaIwfFX1UPD1I ._1408PHfeVq5hbU5DQqY--o {
    font-size: 18px;
    font-weight: bold; }

._1BIbO0SQg6OB0dQfvLUzaH {
  opacity: 0.75;
  pointer-events: none; }

._1OqJv7S-GxRPwYPJwKMhfW {
  margin-top: 60px; }

._14UI1NV6b29ChSQlGexwBn {
  background: white;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #ff0000;
  padding: 32px 0 20px;
  position: relative;
  z-index: 999; }
  @media (max-width: 1024px) {
    ._14UI1NV6b29ChSQlGexwBn {
      margin-top: 76px;
      margin-bottom: -76px; } }

._1X6TXW9uGqNoYmjxXX5Sf6 {
  padding: 0 56px; }
  @media (min-width: 768px) and (max-width: 1280px) {
    ._1X6TXW9uGqNoYmjxXX5Sf6 {
      padding: 0 26px; } }
  @media (max-width: 768px) {
    ._1X6TXW9uGqNoYmjxXX5Sf6 {
      padding: 0 16px; } }

._1CAwX85espRF8jx38riHPu {
  margin: 0;
  font-weight: 800;
  font-size: 1.25em;
  color: #ff0000;
  text-transform: uppercase; }
  @media (max-width: 768px) {
    ._1CAwX85espRF8jx38riHPu {
      padding-right: 74px; } }

._1GGOzIAN12ZcVEH3Ona4F- {
  display: flex;
  width: 100%; }
  @media (max-width: 1280px) {
    ._1GGOzIAN12ZcVEH3Ona4F- {
      flex-direction: column; } }

._1haVmiVVcXSAzi8dLllhNu {
  line-height: 1.56;
  margin-bottom: 0;
  margin-top: 8px;
  letter-spacing: 0;
  font-size: 1em;
  display: inline; }
  @media (max-width: 1280px) {
    ._1haVmiVVcXSAzi8dLllhNu {
      padding-right: 74px; } }
  @media (max-width: 600px) {
    ._1haVmiVVcXSAzi8dLllhNu {
      padding-right: 0; } }

._1haVmiVVcXSAzi8dLllhNu a {
  text-decoration: underline; }
  ._1haVmiVVcXSAzi8dLllhNu a:hover {
    text-decoration: none; }

._3fIFihCdhnITu6DMV_y_mM {
  display: flex;
  margin-left: 24px; }
  @media (max-width: 1280px) {
    ._3fIFihCdhnITu6DMV_y_mM {
      margin-left: 0;
      margin-top: 24px; } }
  @media (max-width: 364px) {
    ._3fIFihCdhnITu6DMV_y_mM {
      flex-direction: column; } }

._3fIFihCdhnITu6DMV_y_mM > * {
  margin-right: 8px; }
  @media (max-width: 364px) {
    ._3fIFihCdhnITu6DMV_y_mM > * {
      margin-right: 0;
      width: 100%;
      margin-bottom: 16px; } }
  ._3fIFihCdhnITu6DMV_y_mM > *:last-child {
    margin-bottom: 0;
    margin-right: 0; }

._1LBB2WVeDdn7IpgfTm0eFS {
  background: #000000;
  padding: 72px 0px 22px 0px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 100%;
  position: relative;
  overflow: hidden;
  color: white; }
  @media (max-width: 1024px) {
    ._1LBB2WVeDdn7IpgfTm0eFS {
      padding-bottom: 32px; } }
  @media (max-width: 486px) {
    ._1LBB2WVeDdn7IpgfTm0eFS {
      padding-top: 40px; } }

._2XynQr4hoU3TkwC9GyLCGe {
  width: 100%;
  max-width: 1174px;
  position: absolute;
  left: 0;
  right: 0;
  top: -110px;
  margin: 0 auto;
  height: calc(100% + 110px);
  z-index: 3;
  background-repeat: repeat;
  background-repeat: initial;
  background-size: 196px;
  background-position: left;
  background-image: linear-gradient(to right, rgba(151, 151, 151, 0.4) 1px, transparent 1px); }
  ._2XynQr4hoU3TkwC9GyLCGe::before {
    content: '';
    width: 100vw;
    height: calc(100% + 110px);
    position: absolute;
    left: calc(-100vw - 196px);
    top: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    background-repeat: repeat;
    background-repeat: initial;
    background-size: 196px;
    background-position: left;
    background-image: linear-gradient(to right, rgba(151, 151, 151, 0.4) 1px, transparent 1px); }
  ._2XynQr4hoU3TkwC9GyLCGe::after {
    content: '';
    width: 100vw;
    height: calc(100% + 110px);
    right: -100vw;
    position: absolute;
    top: 0;
    background-repeat: repeat;
    background-repeat: initial;
    background-size: 196px;
    background-position: left;
    background-image: linear-gradient(to right, rgba(151, 151, 151, 0.4) 1px, transparent 1px); }
  @media (max-width: 486px) {
    ._2XynQr4hoU3TkwC9GyLCGe {
      display: none; } }

._1AYRSTQw9fvGOkWffDYCWV {
  display: flex;
  position: relative; }
  @media (max-width: 1024px) {
    ._1AYRSTQw9fvGOkWffDYCWV {
      flex-direction: column;
      margin-left: 16px;
      font-size: 1em; } }
  ._1AYRSTQw9fvGOkWffDYCWV ._2bV4CdqqWajShIk13DGVLa {
    margin-right: 138px; }
    ._1AYRSTQw9fvGOkWffDYCWV ._2bV4CdqqWajShIk13DGVLa svg {
      width: 255px;
      height: 39px;
      object-fit: contain; }
      @media (max-width: 486px) {
        ._1AYRSTQw9fvGOkWffDYCWV ._2bV4CdqqWajShIk13DGVLa svg {
          width: 179px;
          height: 27px; } }
    ._1AYRSTQw9fvGOkWffDYCWV ._2bV4CdqqWajShIk13DGVLa p {
      margin: 30px 0 8px 0;
      font-size: 1.5em; }
      @media (max-width: 486px) {
        ._1AYRSTQw9fvGOkWffDYCWV ._2bV4CdqqWajShIk13DGVLa p {
          font-size: 1.125em; } }
    ._1AYRSTQw9fvGOkWffDYCWV ._2bV4CdqqWajShIk13DGVLa a {
      margin: 0 0 8px 0; }
      ._1AYRSTQw9fvGOkWffDYCWV ._2bV4CdqqWajShIk13DGVLa a svg {
        margin-bottom: 0;
        margin-left: 8px;
        width: 21px;
        height: 15px;
        transition: all 100ms ease; }
    ._1AYRSTQw9fvGOkWffDYCWV ._2bV4CdqqWajShIk13DGVLa ._34Fb-KlZ2PqbLJDDCznl2q {
      font-weight: bold;
      font-size: 1.5em; }
      @media (max-width: 486px) {
        ._1AYRSTQw9fvGOkWffDYCWV ._2bV4CdqqWajShIk13DGVLa ._34Fb-KlZ2PqbLJDDCznl2q {
          font-size: 1.125em; } }
      ._1AYRSTQw9fvGOkWffDYCWV ._2bV4CdqqWajShIk13DGVLa ._34Fb-KlZ2PqbLJDDCznl2q:hover svg {
        margin-left: 16px; }
      ._1AYRSTQw9fvGOkWffDYCWV ._2bV4CdqqWajShIk13DGVLa ._34Fb-KlZ2PqbLJDDCznl2q:active svg {
        margin-left: 16px; }
    @media (max-width: 486px) {
      ._1AYRSTQw9fvGOkWffDYCWV ._2bV4CdqqWajShIk13DGVLa {
        margin-right: 0;
        margin-right: initial; } }

.TFDLuRdcnEWdg0vWgz_jI {
  display: flex;
  justify-content: flex-start;
  justify-content: space-between;
  z-index: 3;
  position: relative;
  font-size: 1.125em;
  width: 100%; }
  @media (max-width: 1024px) {
    .TFDLuRdcnEWdg0vWgz_jI {
      flex-flow: column; } }
  @media (max-width: 486px) {
    .TFDLuRdcnEWdg0vWgz_jI {
      font-size: 1em; } }
  .TFDLuRdcnEWdg0vWgz_jI ._29jRzvu_7MecapUJG6VV_X {
    opacity: 0.5;
    position: relative;
    margin-right: 390px;
    min-width: 392px; }
    @media (max-width: 1280px) {
      .TFDLuRdcnEWdg0vWgz_jI ._29jRzvu_7MecapUJG6VV_X {
        min-width: 1px;
        margin-right: 0; } }
  .TFDLuRdcnEWdg0vWgz_jI ._3gwspuLEcOkoLTBeNlVQ5i {
    min-width: 453px;
    display: flex; }
    @media (max-width: 1280px) {
      .TFDLuRdcnEWdg0vWgz_jI ._3gwspuLEcOkoLTBeNlVQ5i {
        min-width: 1px; } }
    @media (max-width: 1024px) {
      .TFDLuRdcnEWdg0vWgz_jI ._3gwspuLEcOkoLTBeNlVQ5i {
        margin-top: 16px;
        flex-flow: column; } }
    .TFDLuRdcnEWdg0vWgz_jI ._3gwspuLEcOkoLTBeNlVQ5i a {
      text-decoration: underline;
      font-weight: normal;
      transition: all 100ms ease;
      margin-left: 44px; }
      .TFDLuRdcnEWdg0vWgz_jI ._3gwspuLEcOkoLTBeNlVQ5i a:first-of-type {
        margin-left: 0; }
      .TFDLuRdcnEWdg0vWgz_jI ._3gwspuLEcOkoLTBeNlVQ5i a:hover {
        font-weight: 800; }
      @media (max-width: 1024px) {
        .TFDLuRdcnEWdg0vWgz_jI ._3gwspuLEcOkoLTBeNlVQ5i a {
          margin-left: 0;
          margin-bottom: 8px; } }
      @media (max-width: 486px) {
        .TFDLuRdcnEWdg0vWgz_jI ._3gwspuLEcOkoLTBeNlVQ5i a {
          margin: 8px 0; } }
      @media (max-width: 1024px) {
        .TFDLuRdcnEWdg0vWgz_jI ._3gwspuLEcOkoLTBeNlVQ5i a:last-of-type {
          margin-bottom: 0; } }
    @media (max-width: 486px) {
      .TFDLuRdcnEWdg0vWgz_jI ._3gwspuLEcOkoLTBeNlVQ5i {
        width: 100%;
        margin-left: 0;
        flex-direction: column;
        margin: 8px 0; } }
  @media (max-width: 486px) {
    .TFDLuRdcnEWdg0vWgz_jI {
      flex-direction: column;
      margin: 0;
      margin-left: 16px; } }

._2_wmG0C9dCUAL13klbGysC {
  position: absolute;
  width: 227px;
  margin-top: 149px;
  right: -33px; }
  @media (max-width: 1024px) {
    ._2_wmG0C9dCUAL13klbGysC {
      position: relative;
      right: 0; } }
  @media (max-width: 486px) {
    ._2_wmG0C9dCUAL13klbGysC {
      width: 143px; } }

 {
  /*.half {
        float: left;
        width: 10%;
      }*/ }
  ._1fT8df6Lk61LLBOzNbQFp8 {
    height: 187px;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    margin: 40px 0; }
    @media (max-width: 768px) {
      ._1fT8df6Lk61LLBOzNbQFp8 {
        height: 131px; } }
  .LwTFQvBeGeXL5EbCUcdkD {
    height: 131px; }
  ._2tr7SUDyVWFoI3Ioxhf6jI {
    display: block;
    width: -webkit-max-content;
    width: max-content;
    margin: 0;
    position: absolute; }
    ._2tr7SUDyVWFoI3Ioxhf6jI {
      -webkit-animation: marquee 35s linear infinite;
              animation: marquee 35s linear infinite; }
      @media (max-width: 486px) {
        ._2tr7SUDyVWFoI3Ioxhf6jI {
          -webkit-animation: marquee 20s linear infinite;
                  animation: marquee 20s linear infinite; } }
    ._2tr7SUDyVWFoI3Ioxhf6jI p {
      font-size: 9em;
      font-weight: 900;
      margin: 0;
      text-transform: uppercase; }
      @media (max-width: 768px) {
        ._2tr7SUDyVWFoI3Ioxhf6jI p {
          font-size: 6.75em; } }
    ._2tr7SUDyVWFoI3Ioxhf6jI span {
      display: flex; }
  .LwTFQvBeGeXL5EbCUcdkD ._2tr7SUDyVWFoI3Ioxhf6jI p {
    font-size: 6.75em; }

@-webkit-keyframes marquee {
  0% {
    left: 0; }
  100% {
    left: -170%; } }

@keyframes marquee {
  0% {
    left: 0; }
  100% {
    left: -170%; } }

.ItJ3jWA-P378vJXv-0bx9 {
  display: flex;
  flex-direction: column;
  padding-top: 78px;
  color: white;
  font-size: 1.500em; }
  .ItJ3jWA-P378vJXv-0bx9 a {
    margin-bottom: 24px;
    font-weight: normal;
    transition: all 100ms linear; }
    .ItJ3jWA-P378vJXv-0bx9 a:hover {
      font-weight: bold; }
      .ItJ3jWA-P378vJXv-0bx9 a:hover svg rect {
        fill: #dbdbdb; }
    @media (max-width: 486px) {
      .ItJ3jWA-P378vJXv-0bx9 a {
        margin-bottom: 10px; } }
  @media (max-width: 486px) {
    .ItJ3jWA-P378vJXv-0bx9 {
      font-size: 1.125em;
      padding-top: 26px; } }

._3uTGWt-ERWHFSwIDXfPJ9L {
  margin-right: 16px; }
  @media (max-width: 486px) {
    ._3uTGWt-ERWHFSwIDXfPJ9L {
      margin-right: 8px;
      width: 32px; } }
  ._3uTGWt-ERWHFSwIDXfPJ9L rect {
    fill: white; }
  ._3uTGWt-ERWHFSwIDXfPJ9L path {
    fill: black; }

._1C23ZHCYkLWTzVcIqM5McX {
  margin-right: 16px; }
  @media (max-width: 486px) {
    ._1C23ZHCYkLWTzVcIqM5McX {
      margin-right: 8px;
      width: 32px; } }
  ._1C23ZHCYkLWTzVcIqM5McX rect {
    fill: white; }
  ._1C23ZHCYkLWTzVcIqM5McX path {
    fill: #0078e6; }

._3YbCLWj2IxwFBKhQ3tRbj1 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  width: 3920px;
  margin: 0 auto;
  height: calc(100%);
  z-index: 1;
  left: 50%;
  margin-left: -1960px;
  pointer-events: none; }
  @media (max-width: 881px) {
    ._3YbCLWj2IxwFBKhQ3tRbj1 {
      display: none; } }

._3YbCLWj2IxwFBKhQ3tRbj1 span {
  display: inline-block;
  width: 196px;
  height: 100%;
  border-left: 1px solid rgba(151, 151, 151, 0.4);
  opacity: 0; }

._3_SRJ1K7U_yZEeXlVWL3Pt span {
  opacity: 1; }

._10P6pDZKw0xu7RI-ZjJ5yO span:nth-child(12),
._10P6pDZKw0xu7RI-ZjJ5yO span:nth-child(13),
._10P6pDZKw0xu7RI-ZjJ5yO span:nth-child(14),
._10P6pDZKw0xu7RI-ZjJ5yO span:nth-child(15),
._10P6pDZKw0xu7RI-ZjJ5yO span:nth-child(16),
._10P6pDZKw0xu7RI-ZjJ5yO span:nth-child(17),
._10P6pDZKw0xu7RI-ZjJ5yO span:nth-child(18),
._10P6pDZKw0xu7RI-ZjJ5yO span:nth-child(19),
._10P6pDZKw0xu7RI-ZjJ5yO span:nth-child(20) {
  opacity: 1; }

._23pLXiUxloFkCidyLCRYPB span:nth-child(10),
._23pLXiUxloFkCidyLCRYPB span:nth-child(11),
._23pLXiUxloFkCidyLCRYPB span:nth-child(12),
._23pLXiUxloFkCidyLCRYPB span:nth-child(13),
._23pLXiUxloFkCidyLCRYPB span:nth-child(14),
._23pLXiUxloFkCidyLCRYPB span:nth-child(15),
._23pLXiUxloFkCidyLCRYPB span:nth-child(16),
._23pLXiUxloFkCidyLCRYPB span:nth-child(17),
._23pLXiUxloFkCidyLCRYPB span:nth-child(18),
._23pLXiUxloFkCidyLCRYPB span:nth-child(19),
._23pLXiUxloFkCidyLCRYPB span:nth-child(20) {
  opacity: 1; }

._1aiAhDL215d9PcEf88crS2 span:nth-child(13),
._1aiAhDL215d9PcEf88crS2 span:nth-child(14),
._1aiAhDL215d9PcEf88crS2 span:nth-child(15),
._1aiAhDL215d9PcEf88crS2 span:nth-child(16),
._1aiAhDL215d9PcEf88crS2 span:nth-child(17),
._1aiAhDL215d9PcEf88crS2 span:nth-child(18),
._1aiAhDL215d9PcEf88crS2 span:nth-child(19),
._1aiAhDL215d9PcEf88crS2 span:nth-child(20) {
  opacity: 1; }

._6kBpkaKIa29u1ujcwNvMx {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: background, 100ms, ease; }

._3M9WKTKNxXDD_FmY_K_-jW {
  background: rgba(0, 0, 0, 0);
  z-index: 0; }

.rb54U4k-bY6T2_A2YxSH9 {
  background: rgba(0, 0, 0, 0.7);
  z-index: 100; }

._3ggVYWh-2QaPwXURNbDSaf {
  z-index: 999;
  position: sticky;
  top: 0;
  width: 100%; }
  @media (max-width: 1024px) {
    ._3ggVYWh-2QaPwXURNbDSaf {
      position: fixed; } }

.ie ._3ggVYWh-2QaPwXURNbDSaf {
  position: fixed;
  left: 0;
  width: 100%; }

.yaIx24922YDmhUZlz1YJ {
  position: absolute;
  z-index: 999;
  border-bottom: solid 1px #ffffff;
  font-weight: bold;
  font-size: 1.125em;
  transition: display 175ms linear;
  transition-delay: 500ms;
  width: 100%;
  max-width: 457px; }
  @media (max-width: 486px) {
    .yaIx24922YDmhUZlz1YJ {
      max-width: 486px; } }

._3sQeJh7JhMr4y1lfpP-5Yj {
  display: none; }

._1f3CMyul1INtViHNg_B1c5 {
  display: flex; }

._2MOvxCBNW4XnKukgvLOEL9 {
  display: flex;
  align-items: center;
  height: 75px;
  justify-content: center;
  color: white;
  width: 100%;
  max-width: 232px;
  min-width: 232px;
  font-size: 1.125em; }
  ._2MOvxCBNW4XnKukgvLOEL9 ._2yrUjKGNmKTw3GE2ZX0H-U {
    margin-right: 8px;
    margin-top: 3px;
    width: 13px;
    height: 13px; }
  ._2MOvxCBNW4XnKukgvLOEL9 ._2fOZygBB3fuix-VAhyRbr2 > g {
    fill: #143264; }
  ._2MOvxCBNW4XnKukgvLOEL9 ._313nc4rZUdw_uk1wKU6lW- > g {
    fill: #0078e6; }
  @media (max-width: 468px) {
    ._2MOvxCBNW4XnKukgvLOEL9 {
      min-width: 0; } }

._3sGfCS0-xUPTNQLLGpFckh {
  background-color: #0078e6; }

._2YdelPqSHHuJOeDwdtgrk4 {
  background-color: #143264; }

._2ouaHBizjKUwKc2YfU-YZy {
  width: 100%;
  transition: width 175ms ease;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 224px; }

.cxTV4wu0SrgUDvQpJe1vY {
  width: 100vw;
  position: fixed;
  background: white;
  color: #143264;
  padding: 62px 204px 0 92px;
  max-width: 456px;
  height: calc(100vh - 76px);
  top: 76px;
  transition: left 300ms linear;
  z-index: 3;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  @media (max-width: 700px) {
    .cxTV4wu0SrgUDvQpJe1vY {
      padding: 30px 0 0 25px;
      padding-bottom: 98px;
      max-width: 456px; } }
  .cxTV4wu0SrgUDvQpJe1vY h2 {
    color: #143264;
    font-size: 2.375em;
    font-weight: 800;
    margin-top: 0; }
    @media (max-width: 320px) {
      .cxTV4wu0SrgUDvQpJe1vY h2 {
        margin-bottom: 10px; } }
  .cxTV4wu0SrgUDvQpJe1vY li {
    margin-bottom: 28px;
    white-space: nowrap;
    font-size: 1.375em;
    font-weight: normal; }
    .cxTV4wu0SrgUDvQpJe1vY li:last-of-type {
      margin-bottom: 0; }
    @media (max-width: 320px) {
      .cxTV4wu0SrgUDvQpJe1vY li {
        margin-bottom: 20px; } }

._14me9FW-O-emKxn8n8HTZ0 {
  background: #0078e6;
  display: inline-block;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 0 8px;
  margin-left: 4px; }

.GJfQ5cNH84gUqkRRsRYrW {
  color: #0078e6; }

._2i_SeviFfAADPYV1VpcKfo {
  left: -500px; }

.gUfBK3LlWM83qghbUYoTQ {
  left: 0; }

._2G0Cmk3ATY6N8yXvzh51Lp {
  position: fixed;
  width: 100%;
  max-width: 456px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  bottom: 0;
  left: -456px;
  font-size: 1.375em;
  font-weight: bold;
  transition: left 300ms linear; }

.gUfBK3LlWM83qghbUYoTQ ._2G0Cmk3ATY6N8yXvzh51Lp {
  left: 0; }

._3qPqyIbGzuV8v-bM4ldSBv {
  margin-right: 8px; }

._25Z3OjX1ArRp7_nzdXnQgk > g {
  fill: #143264; }

._2u7a_ZNKSpiIYqBln_c5dl {
  background-color: #0078e6;
  color: white; }

.f-FJJqCVJWRgB0sKa4l6E {
  text-transform: uppercase; }

._224t9sd8nEBgUnJFAmF7GT {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 630px;
  overflow: hidden;
  position: relative;
  padding-top: 76px;
  display: flex;
  align-items: flex-end;
  color: white;
  z-index: 2; }
  ._224t9sd8nEBgUnJFAmF7GT::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    mix-blend-mode: multiply;
    background-blend-mode: multiply, multiply;
    background-image: linear-gradient(to bottom, rgba(0, 120, 230, 0.5), rgba(0, 120, 230, 0.5)), linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    z-index: 1; }
  @media (max-width: 486px) {
    ._224t9sd8nEBgUnJFAmF7GT {
      min-height: 380px; } }

.ie ._224t9sd8nEBgUnJFAmF7GT {
  padding-top: 304px;
  min-height: 1px; }

._1p5prUE8Cl7YqvtiDsOB3D {
  position: relative;
  padding-bottom: 140px;
  z-index: 2; }
  @media (max-width: 768px) {
    ._1p5prUE8Cl7YqvtiDsOB3D {
      padding-bottom: 100px; } }
  @media (max-width: 486px) {
    ._1p5prUE8Cl7YqvtiDsOB3D {
      padding-bottom: 54px; } }

.Akxcdgiz4K7BKXh2upngG {
  display: flex;
  flex-flow: column; }
  @media (max-width: 768px) {
    .Akxcdgiz4K7BKXh2upngG {
      width: 100%; } }

.fCURqBvw2QQMxohT-9Ofz {
  font-size: 1.75em;
  text-transform: uppercase;
  line-height: 1.14;
  font-weight: 300;
  letter-spacing: 0.6px;
  transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  opacity: 0;
  -webkit-transform: translateY(10px);
          transform: translateY(10px); }
  @media (max-width: 768px) {
    .fCURqBvw2QQMxohT-9Ofz {
      font-size: 1.25em;
      line-height: 1.6;
      letter-spacing: 0.4px; } }

._3SGJtZ7rBKxhjG3TKajJn4 {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px); }

._2dqnS81nIFNY-GiCxJTcMe {
  opacity: 0;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  max-width: 876px;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto; }
  @media (max-width: 1024px) {
    ._2dqnS81nIFNY-GiCxJTcMe {
      padding-right: 44px; } }
  @media (max-width: 768px) {
    ._2dqnS81nIFNY-GiCxJTcMe {
      font-size: 3em;
      line-height: 0.81;
      padding-right: 0;
      max-width: 100%; } }
  @media (max-width: 486px) {
    ._2dqnS81nIFNY-GiCxJTcMe {
      font-size: 2em !important; } }

.n9k0puMbiPSzmp4aTgLNv {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px); }

.j8iN5P0cxTRNOIMpACRr3 {
  width: 227px;
  margin-top: 52px;
  transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  opacity: 0;
  -webkit-transform: translateY(10px);
          transform: translateY(10px); }
  @media (max-width: 768px) {
    .j8iN5P0cxTRNOIMpACRr3 {
      margin-top: 8px;
      width: 170px; } }

._1FaNIAqOeDaADtPzgyDmWc {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px); }

._3Yn6TVlO3ak3ahriYNwXCS {
  position: relative;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-bottom: 12px;
  transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1); }
  @media (max-width: 768px) {
    ._3Yn6TVlO3ak3ahriYNwXCS {
      display: none; } }

._3Yn6TVlO3ak3ahriYNwXCS span {
  height: 3px;
  width: 100%;
  background: white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1); }
  ._3Yn6TVlO3ak3ahriYNwXCS span:nth-child(2) {
    -webkit-transform: rotate(90deg) scale(0);
            transform: rotate(90deg) scale(0); }

._326QRVdful-M_3RTBzOYdN span {
  -webkit-transform: scale(1);
          transform: scale(1); }
  ._326QRVdful-M_3RTBzOYdN span:nth-child(2) {
    -webkit-transform: rotate(90deg) scale(1);
            transform: rotate(90deg) scale(1); }

.FWKAwvCYL5fpUZLwcgQTE {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  width: 2348px;
  margin: 0 auto;
  height: calc(100%);
  z-index: 1;
  left: 50%;
  margin-left: -1174px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  @media (max-width: 768px) {
    .FWKAwvCYL5fpUZLwcgQTE {
      display: none; } }

.FWKAwvCYL5fpUZLwcgQTE .rnfTItjFumTcos1NdVXwH,
.FWKAwvCYL5fpUZLwcgQTE ._2d_PHZ3iFyZXsADrQJqTDL {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  width: 2348px;
  margin: 0 auto;
  height: calc(100%);
  z-index: 1;
  left: 50%;
  margin-left: -1174px; }

.FWKAwvCYL5fpUZLwcgQTE ._2d_PHZ3iFyZXsADrQJqTDL {
  flex-flow: column;
  height: calc(66px * 36); }

.FWKAwvCYL5fpUZLwcgQTE .rnfTItjFumTcos1NdVXwH span {
  display: inline-block;
  width: 66px;
  height: 100%;
  border-left: 1px solid rgba(255, 255, 255, 0.05); }

.FWKAwvCYL5fpUZLwcgQTE ._2d_PHZ3iFyZXsADrQJqTDL span {
  display: inline-block;
  width: 100%;
  height: 66px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05); }

._2oOHMdYggGXqIejQFcsNqm {
  border-style: solid;
  border-width: 2px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 30px;
  padding-left: 25px;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  order: 1; }
  @media (max-width: 768px) {
    ._2oOHMdYggGXqIejQFcsNqm {
      margin-bottom: 24px; } }

.l0Kzx5VMPxsu_2sL93K4f {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  margin-right: 8px; }

.QL12JrRn9CoWrjoi3zJSl {
  padding-top: 204px; }

.ie .QL12JrRn9CoWrjoi3zJSl {
  padding-top: 279px; }

.QL12JrRn9CoWrjoi3zJSl ._1p5prUE8Cl7YqvtiDsOB3D {
  padding-bottom: 232px; }
  @media (max-width: 1280px) {
    .QL12JrRn9CoWrjoi3zJSl ._1p5prUE8Cl7YqvtiDsOB3D {
      padding-bottom: 32px; } }

.lwf1LIo4oDYHvepirW88Y {
  padding-top: 204px; }
  @media (max-width: 768px) {
    .lwf1LIo4oDYHvepirW88Y h1 {
      padding-bottom: 60px;
      padding-left: 10px; } }

.ie .lwf1LIo4oDYHvepirW88Y {
  padding-top: 279px; }

.lwf1LIo4oDYHvepirW88Y ._1p5prUE8Cl7YqvtiDsOB3D {
  padding-bottom: 232px; }
  @media (max-width: 1280px) {
    .lwf1LIo4oDYHvepirW88Y ._1p5prUE8Cl7YqvtiDsOB3D {
      padding-bottom: 32px; } }

@media (max-width: 768px) {
  ._2FCgVNzieCVDFyVygILYbZ .Akxcdgiz4K7BKXh2upngG {
    order: 2; } }

._2FCgVNzieCVDFyVygILYbZ ._2dqnS81nIFNY-GiCxJTcMe {
  padding-left: calc(344px + 54px);
  margin-left: -9px;
  padding-bottom: 110px;
  max-width: calc(100% - 44px); }
  @media (max-width: 1280px) {
    ._2FCgVNzieCVDFyVygILYbZ ._2dqnS81nIFNY-GiCxJTcMe {
      padding-left: 0;
      margin-left: 0;
      margin-bottom: 32px; } }
  @media (max-width: 768px) {
    ._2FCgVNzieCVDFyVygILYbZ ._2dqnS81nIFNY-GiCxJTcMe {
      padding-bottom: 0;
      margin-left: 0;
      margin-bottom: 0;
      padding-left: 0; } }

._1PVBNBN_bzX6smKYrZcvO6 ._1p5prUE8Cl7YqvtiDsOB3D {
  padding-left: 339px;
  width: auto;
  width: initial; }
  @media (max-width: 900px) {
    ._1PVBNBN_bzX6smKYrZcvO6 ._1p5prUE8Cl7YqvtiDsOB3D {
      padding-left: 0;
      padding-left: initial; } }

._1PVBNBN_bzX6smKYrZcvO6 .Akxcdgiz4K7BKXh2upngG {
  max-width: 734px; }

._2FCgVNzieCVDFyVygILYbZ ._1p5prUE8Cl7YqvtiDsOB3D {
  padding-bottom: 32px; }
  @media (max-width: 768px) {
    ._2FCgVNzieCVDFyVygILYbZ ._1p5prUE8Cl7YqvtiDsOB3D {
      display: flex;
      flex-flow: column;
      align-items: flex-start; } }

._3bhPDoEQV4ILq1AuH-rz-l {
  position: absolute;
  height: 100%;
  width: 177.77777778vh;
  min-width: 100%;
  min-height: 56.25vw;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.FXNxECU-IGGR6xbNR0--2 {
  position: absolute;
  right: -6px;
  bottom: 110px;
  margin-bottom: 84px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  opacity: 0;
  -webkit-transform: translateY(10px);
          transform: translateY(10px); }
  @media (max-width: 1280px) {
    .FXNxECU-IGGR6xbNR0--2 {
      bottom: 0;
      margin-bottom: 32px; } }
  @media (max-width: 768px) {
    .FXNxECU-IGGR6xbNR0--2 {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      flex-direction: row;
      right: auto;
      right: initial;
      position: relative;
      margin-top: 32px;
      margin-bottom: 0;
      order: 3; } }

._2Q6_k6zkRAC42pKaOsqfOX {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px); }

._3G8yVbDL7fnM120YhhRU-c {
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transform: rotate(90deg) translate(0, 0%);
          transform: rotate(90deg) translate(0, 0%);
  text-transform: uppercase;
  font-weight: 400;
  white-space: nowrap;
  margin-right: 100%;
  display: flex;
  align-items: center;
  align-self: flex-start;
  position: relative;
  right: 12px;
  font-size: 0.875em;
  letter-spacing: 1;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  flex-direction: row-reverse;
  justify-content: flex-end; }
  @media (max-width: 768px) {
    ._3G8yVbDL7fnM120YhhRU-c {
      right: 0;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      margin-right: 16px;
      margin-top: 4px; } }
  ._3G8yVbDL7fnM120YhhRU-c::before {
    content: '';
    width: 0;
    margin-left: 24px;
    background: white;
    height: 2px;
    display: inline-block;
    opacity: 0;
    transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1); }
    @media (max-width: 768px) {
      ._3G8yVbDL7fnM120YhhRU-c::before {
        width: 39px; } }

.q6t7dkAXcfcoaps0A3jnq {
  opacity: 1; }
  .q6t7dkAXcfcoaps0A3jnq::before {
    opacity: 1;
    width: 39px; }

._15sf4GOUMIl1wb3CVLCVoV {
  color: white;
  margin-bottom: 32px;
  transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0); }
  @media (max-width: 768px) {
    ._15sf4GOUMIl1wb3CVLCVoV {
      margin-right: 16px; } }
  ._15sf4GOUMIl1wb3CVLCVoV:first-of-type {
    margin-top: 24px; }
    @media (max-width: 768px) {
      ._15sf4GOUMIl1wb3CVLCVoV:first-of-type {
        margin-top: 0px; } }
  ._15sf4GOUMIl1wb3CVLCVoV:last-of-type {
    margin-bottom: 0;
    margin-right: 0; }
  @media (max-width: 768px) {
    ._15sf4GOUMIl1wb3CVLCVoV {
      margin-bottom: 0px; } }

._15sf4GOUMIl1wb3CVLCVoV svg {
  width: 22px;
  height: 22px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease; }

._15sf4GOUMIl1wb3CVLCVoV:hover svg {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px); }

._18InpcoWuz59wPptuWszYy {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

._1nZwuwbz5Zpt3bVzaJtwdR {
  left: 0;
  right: auto;
  bottom: 53px;
  margin-bottom: 0;
  display: flex;
  flex-flow: row; }
  @media (max-width: 1280px) {
    ._1nZwuwbz5Zpt3bVzaJtwdR {
      bottom: auto;
      margin-top: 32px;
      position: relative; } }

._1nZwuwbz5Zpt3bVzaJtwdR ._15sf4GOUMIl1wb3CVLCVoV {
  margin-right: 16px;
  margin-bottom: 0; }
  ._1nZwuwbz5Zpt3bVzaJtwdR ._15sf4GOUMIl1wb3CVLCVoV:first-of-type {
    margin-top: 0; }

._1nZwuwbz5Zpt3bVzaJtwdR ._3G8yVbDL7fnM120YhhRU-c {
  -webkit-transform: rotate(0) translate(0, 0%);
          transform: rotate(0) translate(0, 0%);
  margin-right: 34px;
  top: 5px;
  right: 0; }

._3FEkeWSbufa99H_-565CoH {
  display: flex;
  margin-top: 20px;
  cursor: pointer;
  align-items: center;
  align-self: flex-start; }

._3W9ff8BzFVeSMiC2HRB7Kf {
  display: inline-block;
  border-top: 2px solid #FFFFFF;
  content: "";
  width: 30px;
  margin-left: 10px;
  margin-right: 10px; }

._31kxuFdU9Hi49ziza3IOvG {
  text-transform: uppercase;
  font-weight: 400; }

.modal-video {
  position: fixed;
  width: 100%;
  z-index: 999;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100vh;
  top: 0;
  align-items: center; }

.modal-video-movie-wrap {
  padding-bottom: 0 !important; }

.modal-video-close-btn {
  position: relative;
  z-index: 999;
  top: -230px;
  left: 950px;
  display: inline-block;
  width: 35px;
  height: 35px;
  border: none;
  background: transparent; }

.modal-video-close-btn:after, .modal-video-close-btn:before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px; }

.modal-video-close-btn:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.modal-video-close-btn:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.modal-video-movie-wrap iframe {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 8px 22px 0 rgba(0, 0, 0, 0.19);
  height: 460px;
  width: 920px; }

.no-scroll {
  overflow: hidden; }

@media (max-width: 920px) {
  .modal-video-movie-wrap iframe {
    width: 100%; } }

._10dMoEYekzLT1_rtM1M-Rp {
  z-index: 2;
  position: relative; }
  @media (max-width: 1024px) {
    ._10dMoEYekzLT1_rtM1M-Rp {
      padding: 118px 0 calc(110px + 32px); } }
  @media (max-width: 768px) {
    ._10dMoEYekzLT1_rtM1M-Rp {
      padding: 24px 0 32px; } }

._5xyeit4QcVSj-AkJj58Ky {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; }
  ._5xyeit4QcVSj-AkJj58Ky::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    mix-blend-mode: multiply;
    background-blend-mode: multiply, multiply;
    background-image: linear-gradient(to bottom, rgba(0, 120, 230, 0.5), rgba(0, 120, 230, 0.5)), linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)); }

._1tnoZqz9ZQFBn4WtOVWDcj {
  font-size: 4.125em;
  font-weight: 900;
  margin: 0 0 24px 0;
  padding: 117px 0 0 0;
  line-height: 0.8;
  text-transform: uppercase; }
  @media (max-width: 1024px) {
    ._1tnoZqz9ZQFBn4WtOVWDcj {
      padding-top: 0; } }
  @media (max-width: 768px) {
    ._1tnoZqz9ZQFBn4WtOVWDcj {
      font-size: 3em;
      line-height: 0.79;
      width: 250px; } }

._3HHHZ-rcJgzI_uzYqUcbtM {
  font-size: 24px;
  color: #ffffff;
  max-width: 793px;
  margin-bottom: 88px; }
  @media (max-width: 768px) {
    ._3HHHZ-rcJgzI_uzYqUcbtM {
      font-size: 1.125em;
      line-height: 1.33;
      margin-bottom: 24px; } }

._15rarAQnbl5iCajP0CIXAS {
  display: flex; }
  @media (max-width: 1024px) {
    ._15rarAQnbl5iCajP0CIXAS {
      flex-direction: column; } }

._3hL62JAKsEN9kwenXefJbd {
  margin-right: 76px; }
  @media (max-width: 1024px) {
    ._3hL62JAKsEN9kwenXefJbd {
      margin-right: 0; } }
  ._3hL62JAKsEN9kwenXefJbd h2 {
    margin: 0;
    font-weight: 800;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      ._3hL62JAKsEN9kwenXefJbd h2 {
        font-size: 1.75em;
        line-height: 1.14; } }
  ._3hL62JAKsEN9kwenXefJbd p {
    margin: 0 0 15px 0;
    font-size: 1.5em;
    line-height: 1.75; }
    ._3hL62JAKsEN9kwenXefJbd p:last-of-type {
      margin-bottom: 95px; }
      @media (max-width: 1024px) {
        ._3hL62JAKsEN9kwenXefJbd p:last-of-type {
          margin-bottom: 48px; } }
      @media (max-width: 768px) {
        ._3hL62JAKsEN9kwenXefJbd p:last-of-type {
          margin-bottom: 32px; } }
    @media (max-width: 768px) {
      ._3hL62JAKsEN9kwenXefJbd p {
        font-size: 1.124em;
        line-height: 1.33; } }
  ._3hL62JAKsEN9kwenXefJbd:last-of-type {
    margin-right: 0; }
    @media (max-width: 1024px) {
      ._3hL62JAKsEN9kwenXefJbd:last-of-type {
        margin-bottom: 0; } }

._2047ASxXPSqMuKIc-Oqt43 {
  font-size: 24px;
  color: #ffffff;
  max-width: 317px; }
  ._2047ASxXPSqMuKIc-Oqt43 a {
    font-weight: bolder;
    position: relative;
    text-decoration: underline; }
    ._2047ASxXPSqMuKIc-Oqt43 a:hover {
      text-decoration: none; }
    @media (max-width: 768px) {
      ._2047ASxXPSqMuKIc-Oqt43 a {
        font-weight: bold; } }

._3rnK3Rhn5yKJsioouK6JcJ {
  display: flex;
  justify-content: flex-end; }

._15rarAQnbl5iCajP0CIXAS .button {
  margin-bottom: 32px; }
  @media (max-width: 1024px) {
    ._15rarAQnbl5iCajP0CIXAS .button {
      margin-bottom: 0; } }
  @media (max-width: 486px) {
    ._15rarAQnbl5iCajP0CIXAS .button {
      width: 100%; } }

._1iiTld6aCOJCQlHkciOB0p {
  background: #0078e6;
  position: relative; }

.VBJm5-GKpplCZLmZoSCMJ {
  z-index: 2;
  position: relative;
  display: flex;
  padding-top: 197px;
  padding-bottom: 216px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  @media (max-width: 1024px) {
    .VBJm5-GKpplCZLmZoSCMJ {
      padding-top: 64px;
      padding-bottom: 174px;
      flex-direction: column;
      padding-bottom: 64px; } }
  @media (max-width: 768px) {
    .VBJm5-GKpplCZLmZoSCMJ {
      padding-top: 32px;
      padding-bottom: 32px; } }

._2ci7cBdkTQVmLiuRf_0oeF {
  color: white; }
  ._2ci7cBdkTQVmLiuRf_0oeF h1 {
    font-size: 4.125em;
    font-weight: 900;
    margin: 0 0 24px 0;
    line-height: 0.8;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      ._2ci7cBdkTQVmLiuRf_0oeF h1 {
        font-size: 3em;
        line-height: 0.79; } }
  ._2ci7cBdkTQVmLiuRf_0oeF p {
    margin: 0 0 32px 0;
    font-size: 1.5em;
    font-weight: normal; }
    @media (max-width: 768px) {
      ._2ci7cBdkTQVmLiuRf_0oeF p {
        width: calc(100% - 54px);
        font-size: 1.125em;
        line-height: 1.33; } }
  @media (max-width: 486px) {
    ._2ci7cBdkTQVmLiuRf_0oeF .button {
      width: 100%; } }

._3TO8u_6sWLmpTvGn0MWtY4 {
  margin-right: 52px;
  position: relative;
  left: -28px; }
  @media (max-width: 1024px) {
    ._3TO8u_6sWLmpTvGn0MWtY4 {
      margin-bottom: 32px; } }
  @media (max-width: 768px) {
    ._3TO8u_6sWLmpTvGn0MWtY4 {
      left: 0;
      margin-right: 0; } }
  @media (max-width: 1024px) {
    ._3TO8u_6sWLmpTvGn0MWtY4 svg {
      width: 250px;
      height: auto; } }
  @media (max-width: 768px) {
    ._3TO8u_6sWLmpTvGn0MWtY4 svg {
      width: 192px;
      height: auto; } }

._7xCSsMegPn6nPeV_Bi0J8 {
  position: relative;
  color: black;
  height: 111px;
  top: -110px;
  overflow: hidden;
  pointer-events: none; }
  @media (max-width: 768px) {
    ._7xCSsMegPn6nPeV_Bi0J8 {
      display: none; } }

._21aONGYi5I4YydKAOeT4ve {
  position: relative;
  height: 100%;
  min-width: 1174px; }
  @media (max-width: 1200px) {
    ._21aONGYi5I4YydKAOeT4ve {
      min-width: 1000px;
      width: 1000px; } }
  ._21aONGYi5I4YydKAOeT4ve::after {
    content: '';
    width: 100vw;
    height: 110px;
    position: absolute;
    right: calc(425px - 100vw);
    top: 0;
    background: white;
    transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1);
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
    @media (max-width: 1024px) {
      ._21aONGYi5I4YydKAOeT4ve::after {
        right: calc(365px - 100vw); } }

._3-v6cRD6baYJJeWdBzwOTm {
  position: absolute;
  right: 424px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  top: 0px;
  width: 110px;
  height: 111px;
  background-color: white;
  -webkit-clip-path: polygon(100% 0, 100% 0%, 100% 100%, 0 100%);
          clip-path: polygon(100% 0, 100% 0%, 100% 100%, 0 100%);
  transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1; }
  @media (max-width: 1024px) {
    ._3-v6cRD6baYJJeWdBzwOTm {
      right: 364px; } }

.hyFCUKmHMpSY7EmuiP2Le {
  width: 100%;
  max-width: 1174px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  height: calc(100%);
  z-index: 3;
  background-repeat: repeat;
  background-repeat: initial;
  background-size: 196px;
  background-position: left;
  background-image: linear-gradient(to right, rgba(151, 151, 151, 0.4) 1px, transparent 1px); }
  .hyFCUKmHMpSY7EmuiP2Le::before {
    content: '';
    width: 100vw;
    height: calc(100%);
    position: absolute;
    left: calc(-100vw - 196px);
    top: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    background-repeat: repeat;
    background-repeat: initial;
    background-size: 196px;
    background-position: left;
    background-image: linear-gradient(to right, rgba(151, 151, 151, 0.4) 1px, transparent 1px); }
  .hyFCUKmHMpSY7EmuiP2Le::after {
    content: '';
    width: 100vw;
    height: calc(100%);
    right: -100vw;
    position: absolute;
    top: 0;
    background-repeat: repeat;
    background-repeat: initial;
    background-size: 196px;
    background-position: left;
    background-image: linear-gradient(to right, rgba(151, 151, 151, 0.4) 1px, transparent 1px); }

.B_F2Opu8j-jdUhXT51-Rz {
  top: 0; }

.B_F2Opu8j-jdUhXT51-Rz ._3-v6cRD6baYJJeWdBzwOTm {
  top: -1px; }

._25PCRbCCiqMVX9Lwoq5_Ea ._21aONGYi5I4YydKAOeT4ve::after {
  left: calc(324px + 110px);
  right: auto; }

._25PCRbCCiqMVX9Lwoq5_Ea ._3-v6cRD6baYJJeWdBzwOTm {
  left: 325px;
  right: auto; }

.a9fy_dOC869vBnubLn4XT ._21aONGYi5I4YydKAOeT4ve::after {
  left: calc(259px + 110px); }

.a9fy_dOC869vBnubLn4XT ._3-v6cRD6baYJJeWdBzwOTm {
  left: 260px;
  right: auto; }

._3f35Rsq-1TEHSysxiBgp7S ._21aONGYi5I4YydKAOeT4ve::after {
  left: calc(479px - 100vw);
  right: 0; }

._3f35Rsq-1TEHSysxiBgp7S ._3-v6cRD6baYJJeWdBzwOTm {
  -webkit-transform: translateY(0) rotate(90deg);
          transform: translateY(0) rotate(90deg);
  left: 479px;
  right: 0; }

._3-gWsQYFYOT6M3wwD2jo9B ._3-v6cRD6baYJJeWdBzwOTm {
  -webkit-clip-path: polygon(100% 100%, 100% 100%, 0% 0%, 100% 0%);
          clip-path: polygon(100% 100%, 100% 100%, 0% 0%, 100% 0%); }

._27lar0X4Woeo3P6nRH13w7 ._3-v6cRD6baYJJeWdBzwOTm {
  background-color: #0078e6; }

._27lar0X4Woeo3P6nRH13w7 ._21aONGYi5I4YydKAOeT4ve::after {
  background-color: #0078e6; }

._1bH1S92V9PrkE9pG29nyxT ._3-v6cRD6baYJJeWdBzwOTm {
  background-color: #143264; }

._1bH1S92V9PrkE9pG29nyxT ._21aONGYi5I4YydKAOeT4ve::after {
  background-color: #143264; }

._3saiha-hYp_hZFMavTF8Ot {
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
  top: -631px; }

._3lC_eUPD4WFBQ-rE1TRMTu {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  z-index: -5; }
  ._3lC_eUPD4WFBQ-rE1TRMTu span {
    border-right: 1px solid #eee;
    width: calc((100% / 9.736) - 1px); }
    ._3lC_eUPD4WFBQ-rE1TRMTu span:last-of-type {
      border-right: 0; }
    ._3lC_eUPD4WFBQ-rE1TRMTu span:first-of-type {
      width: 10%; }

.Yeq98ssx6a3uQf_XZN3h6 ._21aONGYi5I4YydKAOeT4ve::after {
  right: calc(204px - 100vw); }

.Yeq98ssx6a3uQf_XZN3h6 ._3-v6cRD6baYJJeWdBzwOTm {
  right: 204px; }

.Yeq98ssx6a3uQf_XZN3h6._3f35Rsq-1TEHSysxiBgp7S ._21aONGYi5I4YydKAOeT4ve::after {
  left: calc(310px - 100vw);
  right: auto; }

.Yeq98ssx6a3uQf_XZN3h6._3f35Rsq-1TEHSysxiBgp7S ._3-v6cRD6baYJJeWdBzwOTm {
  left: 310px;
  right: auto; }

.XIKNXZYuUlozXrHH3FeHi {
  position: relative;
  background: white; }

@media (max-width: 1024px) {
  .XIKNXZYuUlozXrHH3FeHi .notch {
    display: none; } }

.bno_zWtg44YTm5f3LxJPG {
  position: relative;
  padding-top: 32px;
  padding-bottom: 191px; }
  @media (max-width: 1280px) {
    .bno_zWtg44YTm5f3LxJPG {
      padding-top: 0;
      padding-bottom: 44px; } }
  @media (max-width: 1024px) {
    .bno_zWtg44YTm5f3LxJPG {
      padding-top: 42px; } }

._2mGqvAP_SBl6131PyBH0oK {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  height: 100%; }
  @media (max-width: 768px) {
    ._2mGqvAP_SBl6131PyBH0oK {
      position: static; } }

._1UKLal6HCC0WzOkfLz3vf3 {
  width: 588px; }
  @media (max-width: 1280px) {
    ._1UKLal6HCC0WzOkfLz3vf3 {
      width: 379px; } }
  @media (max-width: 468px) {
    ._1UKLal6HCC0WzOkfLz3vf3 {
      width: 100%; } }

@media (max-width: 468px) {
  ._1UKLal6HCC0WzOkfLz3vf3 .button {
    width: 100%; } }

._34TwTYrubH6Y2LwIOt_OUJ {
  color: #0078e6;
  font-weight: 500;
  margin: 4px;
  text-transform: uppercase; }
  @media (max-width: 768px) {
    ._34TwTYrubH6Y2LwIOt_OUJ {
      font-size: 1.125em; } }

._2cC5fdvOjvRXSWSxAWcbhS {
  color: #143264;
  font-size: 4.125em;
  font-weight: 900;
  margin: 0 0 24px 0;
  line-height: 53px;
  text-transform: uppercase;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto; }
  @media (max-width: 768px) {
    ._2cC5fdvOjvRXSWSxAWcbhS {
      font-size: 3em;
      line-height: 0.79; } }

._1v6pF2cVqIrLedBevG67YA {
  margin: 32px 0;
  font-size: 1.5em;
  font-weight: normal; }
  @media (max-width: 768px) {
    ._1v6pF2cVqIrLedBevG67YA {
      font-size: 1.125em;
      line-height: 1.33;
      width: calc(100% - 54px); } }

._1cRPSwICeRHZPWphdmwKeP {
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  position: relative;
  left: calc(784px);
  top: 0; }
  @media (max-width: 1280px) {
    ._1cRPSwICeRHZPWphdmwKeP {
      left: calc(379px + 41px);
      width: 800px; } }
  @media (max-width: 1024px) {
    ._1cRPSwICeRHZPWphdmwKeP {
      width: 600px; } }
  @media (max-width: 768px) {
    ._1cRPSwICeRHZPWphdmwKeP {
      height: 475px;
      width: calc(100% + 32px);
      left: -16px; } }

._2c-swDCi5lvlr8GBuqSuoH .bno_zWtg44YTm5f3LxJPG {
  display: flex;
  justify-content: flex-end; }
  @media (max-width: 768px) {
    ._2c-swDCi5lvlr8GBuqSuoH .bno_zWtg44YTm5f3LxJPG {
      justify-content: flex-start; } }

._2c-swDCi5lvlr8GBuqSuoH ._1cRPSwICeRHZPWphdmwKeP {
  left: calc(-100% + 391px); }
  @media (max-width: 1280px) {
    ._2c-swDCi5lvlr8GBuqSuoH ._1cRPSwICeRHZPWphdmwKeP {
      right: calc(379px + 41px);
      left: auto;
      width: 100%; } }
  @media (max-width: 768px) {
    ._2c-swDCi5lvlr8GBuqSuoH ._1cRPSwICeRHZPWphdmwKeP {
      width: calc(100% + 32px);
      left: -16px;
      right: auto; } }

._3EX0GRbtkYSBGZ7fvFLLSK {
  background: #0078e6;
  color: white;
  padding-bottom: 140px;
  position: relative;
  z-index: 2; }
  @media (max-width: 768px) {
    ._3EX0GRbtkYSBGZ7fvFLLSK {
      padding-bottom: 23px; } }

@media (max-width: 1280px) {
  ._3EX0GRbtkYSBGZ7fvFLLSK .notch {
    display: none; } }

._1tbrGJZNldZioh76R3Cm89 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 100px;
  padding-top: 16px; }
  @media (max-width: 1280px) {
    ._1tbrGJZNldZioh76R3Cm89 {
      justify-content: flex-start;
      margin-bottom: 64px; } }
  @media (max-width: 768px) {
    ._1tbrGJZNldZioh76R3Cm89 {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 28px;
      margin-bottom: 40px; } }

.IDC1MFR9hhukl9KB_ZZh0 {
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: 100px; }
  @media (max-width: 1024px) {
    .IDC1MFR9hhukl9KB_ZZh0 {
      margin-left: 32px; } }
  @media (max-width: 768px) {
    .IDC1MFR9hhukl9KB_ZZh0 {
      margin-left: 0; } }
  .IDC1MFR9hhukl9KB_ZZh0:first-of-type {
    margin-left: 0; }
  .IDC1MFR9hhukl9KB_ZZh0:last-of-type {
    margin-top: 40px;
    margin-right: 48px; }
    @media (max-width: 1024px) {
      .IDC1MFR9hhukl9KB_ZZh0:last-of-type {
        margin-right: 0; } }
  .IDC1MFR9hhukl9KB_ZZh0 span {
    font-size: 2em;
    line-height: 0.8;
    font-weight: 900;
    margin-right: 6px;
    min-width: 122px;
    text-align: right; }
    @media (max-width: 384px) {
      .IDC1MFR9hhukl9KB_ZZh0 span {
        font-size: 1.875em; } }
    @media (max-width: 342px) {
      .IDC1MFR9hhukl9KB_ZZh0 span {
        font-size: 1.75em;
        min-width: 100px; } }
  .IDC1MFR9hhukl9KB_ZZh0 b {
    font-size: 1.125em;
    font-weight: 800;
    line-height: 0.9; }

._2k7fuX7xoE0XjCA613U1Dk {
  position: relative;
  z-index: 2; }

._3nfgZvTCHZ8R3MYYN1a99b {
  margin-top: -220px;
  pointer-events: none;
  position: relative;
  z-index: 2; }
  @media (max-width: 1280px) {
    ._3nfgZvTCHZ8R3MYYN1a99b {
      margin-top: 0; } }

._2lOKk4iLUjx-bYSt-NZ1l6 {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
  z-index: 3; }
  @media (max-width: 1280px) {
    ._2lOKk4iLUjx-bYSt-NZ1l6 {
      flex-wrap: wrap; } }
  @media (max-width: 768px) {
    ._2lOKk4iLUjx-bYSt-NZ1l6 {
      flex-flow: column;
      margin-left: -16px;
      margin-right: -16px; } }

._3YchJ781SnLazecV51E68R {
  cursor: pointer;
  background: #143264;
  color: white;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125em;
  font-weight: bold;
  min-width: 175px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  @media (max-width: 768px) {
    ._3YchJ781SnLazecV51E68R {
      width: 100%;
      height: 63px; } }
  ._3YchJ781SnLazecV51E68R span {
    max-width: 102px; }
    @media (max-width: 768px) {
      ._3YchJ781SnLazecV51E68R span {
        max-width: 100%; } }
  ._3YchJ781SnLazecV51E68R svg {
    margin-right: 8px;
    min-width: 15px;
    height: 15px; }

.qaKJH2d6R_AxVtNUzCabu {
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center; }

._3yTa8UUSAmgt4FbzILxEUS {
  flex-wrap: wrap; }
  @media (max-width: 768px) {
    ._3yTa8UUSAmgt4FbzILxEUS {
      flex-flow: wrap;
      margin-left: 0;
      margin-right: 0; } }

.jo3swlKmzR7E0nB9sFgEn {
  height: 50px;
  width: 100%;
  display: block;
  position: relative; }

._2sjPLv9O05IprYumpEhGdW {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px; }
  ._2sjPLv9O05IprYumpEhGdW {
    -webkit-animation: rotate 2s linear infinite;
            animation: rotate 2s linear infinite; }
  ._2sjPLv9O05IprYumpEhGdW circle {
    stroke: white;
    stroke-linecap: round; }
    ._2sjPLv9O05IprYumpEhGdW circle {
      -webkit-animation: dash 1.5s ease-in-out infinite;
              animation: dash 1.5s ease-in-out infinite; }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124; } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124; } }

._2h0GPcfEA9nX-8NiGCaLs- {
  background: white;
  border-right: 1px solid #e4e4e4;
  color: #000;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 230px;
  min-width: 230px; }
  @media (max-width: 768px) {
    ._2h0GPcfEA9nX-8NiGCaLs- {
      width: 100%;
      min-width: 100%;
      border-bottom: 1px solid #e4e4e4;
      border-right: 0; } }
  ._2h0GPcfEA9nX-8NiGCaLs-:last-of-type {
    border-right: 0; }
  ._2h0GPcfEA9nX-8NiGCaLs-:nth-child(1) {
    width: 251px;
    min-width: 251px; }
    @media (max-width: 768px) {
      ._2h0GPcfEA9nX-8NiGCaLs-:nth-child(1) {
        width: 100%;
        min-width: 100%; } }
  ._2h0GPcfEA9nX-8NiGCaLs-:nth-child(2) {
    width: 200px;
    min-width: 200px; }
    @media (max-width: 1024px) {
      ._2h0GPcfEA9nX-8NiGCaLs-:nth-child(2) {
        width: 200px;
        min-width: 200px; } }
    @media (max-width: 768px) {
      ._2h0GPcfEA9nX-8NiGCaLs-:nth-child(2) {
        width: 100%;
        min-width: 100%; } }
  ._2h0GPcfEA9nX-8NiGCaLs-:nth-child(3) {
    width: 274px;
    min-width: 274px; }
    @media (max-width: 1280px) {
      ._2h0GPcfEA9nX-8NiGCaLs-:nth-child(3) {
        clear: left;
        width: 274px;
        min-width: 274px; } }
    @media (max-width: 1024px) {
      ._2h0GPcfEA9nX-8NiGCaLs-:nth-child(3) {
        width: 251px;
        min-width: 251px; } }
    @media (max-width: 768px) {
      ._2h0GPcfEA9nX-8NiGCaLs-:nth-child(3) {
        width: 100%;
        min-width: 100%; } }
  ._2h0GPcfEA9nX-8NiGCaLs-:nth-child(4) {
    width: 274px;
    min-width: 274px; }
    @media (max-width: 1280px) {
      ._2h0GPcfEA9nX-8NiGCaLs-:nth-child(4) {
        width: 240px;
        min-width: 240px; } }
    @media (max-width: 768px) {
      ._2h0GPcfEA9nX-8NiGCaLs-:nth-child(4) {
        width: 100%;
        min-width: 100%; } }

._2xUvKnKbPb0NUbivNETHZx {
  z-index: 4; }

._1pMRGAUJo7YP4DkiFEiLVq {
  pointer-events: none; }
  ._1pMRGAUJo7YP4DkiFEiLVq * {
    opacity: 0.6; }

._1csfVIuuRa034LPTXO6Cwc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.125em;
  color: #afafaf;
  height: 90px;
  padding: 0 24px;
  cursor: pointer;
  background: white;
  position: relative;
  z-index: 2; }
  @media (max-width: 768px) {
    ._1csfVIuuRa034LPTXO6Cwc {
      z-index: 2;
      height: 60px; } }

._1csfVIuuRa034LPTXO6Cwc ._1BFVwGRYasvN9nSwHHTzgw {
  color: #0078e6;
  width: 17px;
  height: 10px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease; }

.nxwERxCGmDOFv9D2oYWat ._1BFVwGRYasvN9nSwHHTzgw {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

._2gTp_-hSLD3SVoQRVnooen {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;
  width: calc(100% - 17px - 8px); }

._2HLDtjUBL2-m4CZBqNA2ct {
  font-weight: bold;
  color: #000000; }

._1gpFKVF_0Y5cLWRXbi8OUS {
  list-style: none;
  margin: 0;
  padding: 0;
  background: white;
  width: 100%;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.2s ease, padding 0.3s ease;
  position: absolute;
  top: 90px;
  left: 0;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5); }
  @media (max-width: 768px) {
    ._1gpFKVF_0Y5cLWRXbi8OUS {
      top: 0;
      box-shadow: none; } }
  @media (max-width: 768px) {
    ._1gpFKVF_0Y5cLWRXbi8OUS li:last-child {
      border-bottom: none; } }

._3DXEzGBcJ4fYr2CQBwulwj {
  max-height: 400px; }
  @media (max-width: 768px) {
    ._3DXEzGBcJ4fYr2CQBwulwj {
      position: relative; } }

._1gpFKVF_0Y5cLWRXbi8OUS li {
  padding: 18px 24px;
  font-size: 1.125em;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background 0.2s cubic-bezier(0.35, 0, 0.65, 1); }

._1gpFKVF_0Y5cLWRXbi8OUS li svg,
._1gpFKVF_0Y5cLWRXbi8OUS li svg g,
._1gpFKVF_0Y5cLWRXbi8OUS li svg path {
  fill: #0078e6; }

._1gpFKVF_0Y5cLWRXbi8OUS li:hover ._2TIoudFBLM5rwawLCKx8fO {
  font-weight: bold; }

._1gpFKVF_0Y5cLWRXbi8OUS li:active {
  background: #f1f1f1; }

._1H45rXzAC-5Ji6Jf33nRTB {
  font-weight: bold; }

.jDj07UYSeJw3EQz7i29ol {
  width: 16px;
  height: 11px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative; }

._3C5PEPtWoNGtv0uZFP2FVe {
  width: 11px;
  height: 11px;
  pointer-events: none;
  opacity: 0;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto; }

.ntnXcfqHtxLQzTO41t4_b {
  width: 16px;
  height: 11px;
  pointer-events: none;
  opacity: 0;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto; }

._1H45rXzAC-5Ji6Jf33nRTB .ntnXcfqHtxLQzTO41t4_b {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0); }

._1H45rXzAC-5Ji6Jf33nRTB:hover ._3C5PEPtWoNGtv0uZFP2FVe {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0); }

._1H45rXzAC-5Ji6Jf33nRTB:hover .ntnXcfqHtxLQzTO41t4_b {
  opacity: 0;
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px); }

._3h9_F9b-amq8Za1Vx_xNpI {
  width: calc(50% - 16px) !important;
  min-width: 1px;
  margin-right: 32px;
  margin-bottom: 24px;
  border-right: none;
  border-bottom: 0; }
  ._3h9_F9b-amq8Za1Vx_xNpI:nth-of-type(2n + 2) {
    margin-right: 0; }
  @media (max-width: 768px) {
    ._3h9_F9b-amq8Za1Vx_xNpI {
      order: 2;
      margin-right: 0;
      width: 100% !important; } }
  @media (max-width: 486px) {
    ._3h9_F9b-amq8Za1Vx_xNpI {
      width: calc(100% - 34px) !important; } }

._3h9_F9b-amq8Za1Vx_xNpI ._1csfVIuuRa034LPTXO6Cwc {
  border: 1px solid #d5d5d5;
  height: 48px;
  font-size: 1em;
  padding: 0 10px; }

._3h9_F9b-amq8Za1Vx_xNpI ._1csfVIuuRa034LPTXO6Cwc ._1BFVwGRYasvN9nSwHHTzgw {
  width: 13px; }

._3h9_F9b-amq8Za1Vx_xNpI ._1gpFKVF_0Y5cLWRXbi8OUS {
  top: calc(48px + 16px + 3px);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid #d5d5d5; }

@media (max-width: 768px) {
  ._3h9_F9b-amq8Za1Vx_xNpI ._3DXEzGBcJ4fYr2CQBwulwj {
    position: absolute; } }

._3h9_F9b-amq8Za1Vx_xNpI ._1gpFKVF_0Y5cLWRXbi8OUS li {
  padding: 8px 10px;
  font-size: 1em; }
  ._3h9_F9b-amq8Za1Vx_xNpI ._1gpFKVF_0Y5cLWRXbi8OUS li:last-of-type {
    border-bottom: 0; }

._3h9_F9b-amq8Za1Vx_xNpI .jDj07UYSeJw3EQz7i29ol {
  width: 13px; }

._3h9_F9b-amq8Za1Vx_xNpI .ntnXcfqHtxLQzTO41t4_b {
  width: 13px; }

._82Ilum1HSy292Ypv7NmvS {
  margin-bottom: 4px; }

._2Jd_agIrDnLo4z6Lw428m- {
  display: flex;
  flex-flow: wrap;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: -16px; }
  @media (max-width: 740px) {
    ._2Jd_agIrDnLo4z6Lw428m- {
      flex-direction: column;
      margin-left: -16px;
      margin-right: 0; } }

._2as3mcUvihufAdCuiF98-Z {
  background-color: white;
  padding: 34px 40px;
  width: calc(50% - 16px);
  margin: 16px 8px;
  margin-top: 0;
  display: flex;
  align-items: flex-start;
  transition: background 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: background 0.2s cubic-bezier(0.35, 0, 0.65, 1), transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: background 0.2s cubic-bezier(0.35, 0, 0.65, 1), transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1); }
  @media (max-width: 1024px) {
    ._2as3mcUvihufAdCuiF98-Z {
      padding: 23px 20px; } }
  @media (max-width: 680px) {
    ._2as3mcUvihufAdCuiF98-Z {
      width: 100%; } }
  ._2as3mcUvihufAdCuiF98-Z:hover {
    background-color: #eaeaea;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px); }

.iWAp26vyI604d8AJLY_G7 {
  width: 120px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 1024px) {
    .iWAp26vyI604d8AJLY_G7 {
      width: 70px;
      height: 70px; } }
  @media (max-width: 660px) {
    .iWAp26vyI604d8AJLY_G7 {
      width: 100px;
      height: 100px; } }

.S_J8tesiG28AY10-pLRAz {
  width: calc(100% - 120px - 34px);
  margin-left: 34px;
  padding-top: 24px; }
  @media (max-width: 1024px) {
    .S_J8tesiG28AY10-pLRAz {
      margin-left: 22px;
      width: calc(100% - 70px - 22px); } }
  @media (max-width: 768px) {
    .S_J8tesiG28AY10-pLRAz {
      padding-top: 0;
      width: calc(100% - 100px - 22px); } }

._38y2zaa6bNePrOoBnglUkm {
  text-transform: uppercase;
  color: #143264;
  font-weight: 800;
  line-height: 0.84;
  margin: 0;
  font-size: 1.75em; }
  @media (max-width: 660px) {
    ._38y2zaa6bNePrOoBnglUkm {
      font-size: 1.75em;
      line-height: 1.14; } }

._1q3J0K9f2DJXzXAMjYZCj- {
  margin: 0;
  margin-top: 6px;
  line-height: 1.17;
  font-size: 1.5em;
  color: #143264; }
  @media (max-width: 1024px) {
    ._1q3J0K9f2DJXzXAMjYZCj- {
      font-size: 1.125em;
      line-height: 1.33; } }
  @media (max-width: 486px) {
    ._1q3J0K9f2DJXzXAMjYZCj- {
      margin-top: 0; } }

.HsLVjlqWXJCiGgmNrc69d {
  padding: 0;
  background: none;
  pointer-events: none;
  margin-bottom: 72px; }

.HsLVjlqWXJCiGgmNrc69d ._38y2zaa6bNePrOoBnglUkm {
  color: white; }

.HsLVjlqWXJCiGgmNrc69d ._1q3J0K9f2DJXzXAMjYZCj- {
  color: white; }

.HsLVjlqWXJCiGgmNrc69d svg,
.HsLVjlqWXJCiGgmNrc69d svg g,
.HsLVjlqWXJCiGgmNrc69d svg g path,
.HsLVjlqWXJCiGgmNrc69d path,
.HsLVjlqWXJCiGgmNrc69d polygon {
  fill: white; }

._2pupdCofLxKQzNWSzk1tFN {
  position: relative; }
  ._2pupdCofLxKQzNWSzk1tFN h2 {
    text-transform: uppercase;
    position: relative;
    left: 0;
    top: 0;
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.42;
    color: #0078e6;
    margin: 0;
    margin-bottom: -63px; }
    @media (max-width: 486px) {
      ._2pupdCofLxKQzNWSzk1tFN h2 {
        font-size: 1.125em;
        margin-left: 16px;
        margin-top: 56px; } }

.C2t9Kbdgji5zQ1bo6SpY6 {
  display: flex; }

._2yrBOjHcAo6VtSkWBNEFNN {
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 120px; }
  @media (max-width: 960px) {
    ._2yrBOjHcAo6VtSkWBNEFNN {
      justify-content: flex-start;
      flex-direction: column;
      height: auto; } }
  @media (max-width: 768px) {
    ._2yrBOjHcAo6VtSkWBNEFNN {
      margin-bottom: 56px; } }

._1b_zv7u0urSjkYVaKegNul {
  max-width: 524px;
  margin-top: 155px;
  margin-right: 32px; }
  @media (max-width: 960px) {
    ._1b_zv7u0urSjkYVaKegNul {
      margin-right: 0;
      margin-bottom: 48px; } }
  ._1b_zv7u0urSjkYVaKegNul h3 {
    color: #0078e6;
    font-weight: 500;
    margin: 4px;
    text-transform: uppercase;
    font-size: 1.5em; }
    @media (max-width: 768px) {
      ._1b_zv7u0urSjkYVaKegNul h3 {
        font-size: 1.125em; } }
  ._1b_zv7u0urSjkYVaKegNul h1 {
    color: #143264;
    font-size: 4.125em;
    font-weight: 900;
    margin: 0 0 24px 0;
    line-height: 53px;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      ._1b_zv7u0urSjkYVaKegNul h1 {
        font-size: 3em;
        line-height: 0.79; } }
  ._1b_zv7u0urSjkYVaKegNul p {
    margin: 32px 0;
    font-size: 1.5em;
    font-weight: normal; }
    @media (max-width: 768px) {
      ._1b_zv7u0urSjkYVaKegNul p {
        font-size: 1.125em;
        line-height: 1.33;
        width: calc(100% - 54px); } }
    @media (max-width: 486px) {
      ._1b_zv7u0urSjkYVaKegNul p {
        width: 100%; } }
  @media (max-width: 768px) {
    ._1b_zv7u0urSjkYVaKegNul {
      margin-top: 48px; } }

._3jg9a9_IlhNDsGIsh7qptR {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0;
  align-items: center; }

._3ql027gUgVlyYYfd-z1pyJ {
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 90px;
  background: #0078e6;
  z-index: 2;
  margin-top: 240px; }
  @media (max-width: 369px) {
    ._3ql027gUgVlyYYfd-z1pyJ {
      width: 30px;
      height: 30px; } }

._1MacQ_QJueeK9_OB5tnCmR {
  background: #0078e6;
  width: 48px;
  height: 48px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.375em;
  font-weight: 700;
  position: relative; }
  @media (max-width: 369px) {
    ._1MacQ_QJueeK9_OB5tnCmR {
      width: 30px;
      height: 30px; } }

.VgW-JuU2GvuDHmI9BZDBW::after {
  content: '';
  background: #0078e6;
  width: 3px;
  height: 330px;
  position: absolute;
  top: 48px; }
  @media (max-width: 369px) {
    .VgW-JuU2GvuDHmI9BZDBW::after {
      top: 30px;
      height: 312px; } }

._33Sy_fyymgY6vDYmQTspwq {
  margin-top: 42px; }

._1vt4IPkhawtr5Q_cfzynyC {
  width: 0px;
  height: 192px;
  border-color: #b2b1b1;
  border-style: dashed;
  border-width: 2px;
  position: relative; }
  ._1vt4IPkhawtr5Q_cfzynyC::after {
    content: '';
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9));
    position: absolute;
    height: 208px;
    width: 8px;
    bottom: -4px;
    left: -4px; }
  @media (max-width: 486px) {
    ._1vt4IPkhawtr5Q_cfzynyC {
      align-self: center; } }

._3q8ne3exkB3fjNmd9wPHjB {
  display: flex;
  flex-direction: column;
  margin-left: calc(48px + 24px); }
  @media (max-width: 768px) {
    ._3q8ne3exkB3fjNmd9wPHjB {
      margin-left: 24px; } }

._3NtkhBs5dKQorfusx-uIWn {
  width: 350px;
  height: 345px;
  position: relative; }
  ._3NtkhBs5dKQorfusx-uIWn::after {
    position: absolute;
    content: '';
    background: #143264;
    width: 62px;
    height: 2px;
    left: -62px;
    top: 21px; }
    @media (max-width: 768px) {
      ._3NtkhBs5dKQorfusx-uIWn::after {
        width: 15px;
        left: -15px; } }
  @media (max-width: 486px) {
    ._3NtkhBs5dKQorfusx-uIWn {
      width: calc(100%);
      height: 324px; } }
  @media (max-width: 369px) {
    ._3NtkhBs5dKQorfusx-uIWn {
      width: 227px;
      height: 100%; } }

._387M9v5-mzJwiKE3BKDoy9 {
  background: #143264;
  color: white;
  padding: 33px 40px; }
  ._387M9v5-mzJwiKE3BKDoy9 h2 {
    font-size: 1.5em;
    line-height: 24px;
    margin: 0 0 16px 0;
    font-weight: 800;
    text-transform: uppercase; }
    @media (max-width: 486px) {
      ._387M9v5-mzJwiKE3BKDoy9 h2 {
        font-size: 1.375em;
        line-height: 1; } }
  ._387M9v5-mzJwiKE3BKDoy9 p {
    font-size: 1.125em;
    line-height: 24px;
    margin: 0; }
    @media (max-width: 486px) {
      ._387M9v5-mzJwiKE3BKDoy9 p {
        font-size: 1em;
        line-height: 1.25; } }
  @media (max-width: 486px) {
    ._387M9v5-mzJwiKE3BKDoy9 {
      padding: 24px 24px; } }
  @media (max-width: 369px) {
    ._387M9v5-mzJwiKE3BKDoy9 {
      padding: 10px 10px; } }

._3n2Hzn2RELR5NWp7kpL-W1 {
  margin-top: 36px; }
  ._3n2Hzn2RELR5NWp7kpL-W1 h2 {
    color: white;
    position: absolute;
    z-index: 2;
    bottom: 0;
    margin-left: 32px;
    margin-right: 32px;
    font-size: 1.75em;
    text-transform: uppercase;
    font-weight: 800; }
    @media (max-width: 768px) {
      ._3n2Hzn2RELR5NWp7kpL-W1 h2 {
        margin-left: 24px;
        margin-right: 24px; } }
  ._3n2Hzn2RELR5NWp7kpL-W1 img {
    width: 100%;
    height: 100%; }
  ._3n2Hzn2RELR5NWp7kpL-W1::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    mix-blend-mode: multiply;
    background-blend-mode: multiply, multiply;
    background-image: linear-gradient(to bottom, rgba(0, 120, 230, 0.8), rgba(0, 120, 230, 0.8)), linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); }
  @media (max-width: 486px) {
    ._3n2Hzn2RELR5NWp7kpL-W1 {
      margin-top: 52px; } }
  @media (max-width: 369px) {
    ._3n2Hzn2RELR5NWp7kpL-W1 {
      margin-top: 42px; } }

.Z9HikGBncZ-eVAdHfooFV {
  min-height: 600px;
  padding-top: 110px; }

._3QsEcPfL2AxwsadW9LUtDq {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  color: white; }
  ._3QsEcPfL2AxwsadW9LUtDq::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    mix-blend-mode: multiply;
    background-blend-mode: multiply, multiply;
    background-image: linear-gradient(to bottom, rgba(0, 120, 230, 0.5), rgba(0, 120, 230, 0.5)), linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)); }

._3QsEcPfL2AxwsadW9LUtDq .notch {
  z-index: 1; }

._18__LsgAopTgXXtZLM7f7s {
  position: relative;
  z-index: 2;
  padding-top: 78px;
  padding-bottom: 96px; }
  @media (max-width: 768px) {
    ._18__LsgAopTgXXtZLM7f7s {
      padding-top: 40px;
      padding-bottom: 48px; } }

._2s-mDoeKVMgDMQ4xJkJw94 {
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto; }

._2SgaJeHGEwOZH9wAAYLdvk {
  font-size: 1.5em;
  line-height: 1.75;
  max-width: 550px;
  margin-top: 8px;
  margin-bottom: 22px; }
  @media (max-width: 768px) {
    ._2SgaJeHGEwOZH9wAAYLdvk {
      font-size: 1.125em;
      line-height: 1.33; } }

@media (max-width: 480px) {
  ._3QsEcPfL2AxwsadW9LUtDq .button {
    width: 100%; } }

@media (max-width: 768px) {
  ._3QsEcPfL2AxwsadW9LUtDq .notch {
    display: none; } }

._3cldm11wPFNv9AHQiRhDBD {
  position: relative;
  top: 0;
  left: 0;
  width: 457px;
  display: flex;
  background-color: #0078e6;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media (max-width: 486px) {
    ._3cldm11wPFNv9AHQiRhDBD {
      display: none; } }

._3y_a3lmJ0b9kxzIiHtue_v {
  height: 100%;
  width: 100%; }

._21DxoDoVwfi7SZ4G7X334X {
  left: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 0 51px 32px 32px; }

._28_pzR_a3Feoi1GkCQQ3zy {
  text-transform: uppercase;
  z-index: 3;
  font-size: 1em;
  font-weight: 500;
  letter-spacing: -0.4px;
  margin: 0; }

._1ZWmTRMzX6hYEdibseqcwh {
  text-transform: uppercase;
  z-index: 3;
  font-size: 2em;
  font-weight: 800;
  line-height: 0.84;
  margin: 8px 0 31px 0; }

._1CuaBHr60sf3vhO2WCQPCb {
  width: 170px;
  height: 34px;
  z-index: 3; }
  ._1CuaBHr60sf3vhO2WCQPCb g {
    fill: white; }

._3yfAPFRx2vmRmXOfNwrMx_ {
  background-color: none; }
  ._3yfAPFRx2vmRmXOfNwrMx_::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    mix-blend-mode: multiply;
    background-blend-mode: multiply, multiply;
    background-image: linear-gradient(to bottom, rgba(0, 120, 230, 0.8), rgba(0, 120, 230, 0.8)), linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); }

.VgA0tDRs6FmPczVENWwXt {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

._28_pzR_a3Feoi1GkCQQ3zy,
._1ZWmTRMzX6hYEdibseqcwh,
._1CuaBHr60sf3vhO2WCQPCb {
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  -webkit-transform: translateY(10px);
          transform: translateY(10px); }

._28_pzR_a3Feoi1GkCQQ3zy._11DEidzf05BpWu2Od8-Sa-,
._1ZWmTRMzX6hYEdibseqcwh._3JqZeICgsXB_yEJtKbxxEB,
._1CuaBHr60sf3vhO2WCQPCb._3yeDlzh9WG9l-7TZjSNhSB {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0); }

._3Cv7cDZg8vsKHc7018b2VN {
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }
  @media (max-width: 1024px) {
    ._3Cv7cDZg8vsKHc7018b2VN {
      align-items: flex-start;
      padding-top: 24px; } }
  @media (max-width: 600px) {
    ._3Cv7cDZg8vsKHc7018b2VN {
      padding-top: 0; } }

.P8vuKRSYU3ow188Pdb93D {
  width: calc(100% - 457px);
  position: relative;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1); }
  @media (max-width: 1024px) {
    .P8vuKRSYU3ow188Pdb93D {
      width: 100%; } }

._3wSMCQEyIfh4ZqN1o85A-d {
  width: 1160px;
  z-index: 3;
  overflow: hidden;
  display: flex;
  background: white;
  opacity: 0;
  margin-top: 0px;
  transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.53);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100% - 54px); }
  @media (max-width: 1192px) {
    ._3wSMCQEyIfh4ZqN1o85A-d {
      width: 1000px; } }
  @media (max-width: 1024px) {
    ._3wSMCQEyIfh4ZqN1o85A-d {
      width: 700px;
      max-height: calc(100% - 24px); } }
  @media (max-width: 732px) {
    ._3wSMCQEyIfh4ZqN1o85A-d {
      width: 568px; } }
  @media (max-width: 600px) {
    ._3wSMCQEyIfh4ZqN1o85A-d {
      width: 100%;
      left: 0;
      top: 0;
      height: 100vh;
      max-height: 100vh;
      overflow: auto;
      -webkit-overflow-scrolling: touch; } }
  ._3wSMCQEyIfh4ZqN1o85A-d ._2NycJvcP06QXapd6dQB2Ho {
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
    transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
    transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    cursor: pointer;
    z-index: 3;
    background: #143264; }
    ._3wSMCQEyIfh4ZqN1o85A-d ._2NycJvcP06QXapd6dQB2Ho svg {
      width: 25px;
      height: 25px; }
      @media (max-width: 768px) {
        ._3wSMCQEyIfh4ZqN1o85A-d ._2NycJvcP06QXapd6dQB2Ho svg {
          width: 19px;
          height: 19px; } }
      ._3wSMCQEyIfh4ZqN1o85A-d ._2NycJvcP06QXapd6dQB2Ho svg g {
        fill: white; }
    @media (max-width: 600px) {
      ._3wSMCQEyIfh4ZqN1o85A-d ._2NycJvcP06QXapd6dQB2Ho {
        width: 60px;
        height: 60px; } }
  ._3wSMCQEyIfh4ZqN1o85A-d ._1krI7KW7qimX2zMulVS20k {
    background: #143264; }
  ._3wSMCQEyIfh4ZqN1o85A-d ._2HpTpW5z4eDz0_gacvFiL8 {
    background: #0078e6; }

._1uHDD8tN1b8ffvf60j1-qZ {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: background-color 0.2s cubic-bezier(0.35, 0, 0.65, 1); }

.vlDogKgrF98CjfqdUXeIW ._3wSMCQEyIfh4ZqN1o85A-d {
  opacity: 1;
  transition: all 250ms ease-out; }
  @media (max-width: 600px) {
    .vlDogKgrF98CjfqdUXeIW ._3wSMCQEyIfh4ZqN1o85A-d {
      margin-top: 0;
      margin-top: initial; } }

.vlDogKgrF98CjfqdUXeIW ._1uHDD8tN1b8ffvf60j1-qZ {
  background-color: rgba(0, 0, 0, 0.34); }

.P8vuKRSYU3ow188Pdb93D.z6aAQMKtn60vLSw6oZx7I {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1; }

._2NycJvcP06QXapd6dQB2Ho._2PdZIDSrqjyRg4SmrWXe4 {
  -webkit-transform: translateY(0);
          transform: translateY(0); }

._3WfiLpqGAf6eHkjBGjChI6 {
  padding: 0 48px;
  position: relative;
  color: black;
  font-weight: bold;
  font-size: 1em;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 32px;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  @media (max-width: 486px) {
    ._3WfiLpqGAf6eHkjBGjChI6 {
      width: 100%;
      padding: 0 16px;
      height: 100%; } }
  ._3WfiLpqGAf6eHkjBGjChI6 ._1C9UtwVreR2o_9GlKFGr4j {
    display: flex;
    justify-content: space-between; }
    ._3WfiLpqGAf6eHkjBGjChI6 ._1C9UtwVreR2o_9GlKFGr4j label input {
      width: 180px; }
      @media (max-width: 486px) {
        ._3WfiLpqGAf6eHkjBGjChI6 ._1C9UtwVreR2o_9GlKFGr4j label input {
          width: auto;
          width: initial; } }
    @media (max-width: 486px) {
      ._3WfiLpqGAf6eHkjBGjChI6 ._1C9UtwVreR2o_9GlKFGr4j {
        flex-direction: column; } }
  ._3WfiLpqGAf6eHkjBGjChI6 label {
    display: flex;
    flex-direction: column; }
    @media (max-width: 486px) {
      ._3WfiLpqGAf6eHkjBGjChI6 label {
        width: calc(100% - 34px); } }

._1u8FGjWxniN9XIaNc8RHGm {
  background: #0078e6; }

.Tyof_TJxXvmplkxD2Wm-f {
  width: 400px; }
  @media (max-width: 486px) {
    .Tyof_TJxXvmplkxD2Wm-f {
      width: 100%; } }

._2Nf0NZmpoWscnDHhgIfXHF {
  content: "";
  position: absolute;
  transition: width 100ms ease;
  background: #0078e6;
  height: 3px;
  bottom: 0;
  width: 0;
  z-index: 3; }
  @media (max-width: 786px) {
    ._2Nf0NZmpoWscnDHhgIfXHF {
      display: none; } }

._5CohdxvmLf4SMGcxxooHN {
  width: 50%; }

._1tkaFGh5vFj6SXu-xnYoyF {
  width: 100%; }

._3f9U4l4AL_SA6UKb9_cwhT {
  width: 85px;
  font-size: 1.125em;
  color: #0078e6;
  font-weight: bold;
  cursor: pointer;
  margin-top: 24px;
  margin-left: 7px; }
  ._3f9U4l4AL_SA6UKb9_cwhT svg {
    position: relative;
    margin-right: 8px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    right: 0;
    transition: right 100ms ease; }
  ._3f9U4l4AL_SA6UKb9_cwhT:hover svg {
    right: 8px; }

._1QjeWCByQTkfUPCCaNL65e {
  color: #ff7373;
  font-size: 1em;
  line-height: 1;
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: normal;
  margin-bottom: 24px; }
  ._1QjeWCByQTkfUPCCaNL65e span {
    display: block;
    margin-bottom: 4px; }
    ._1QjeWCByQTkfUPCCaNL65e span:last-of-type {
      margin-bottom: 0; }
  ._1QjeWCByQTkfUPCCaNL65e span svg {
    margin-right: 8px; }

._2cyCJXFYrelNL4ExENW0dt {
  padding: 0 48px;
  position: relative;
  color: black;
  font-weight: bold;
  font-size: 1em;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 32px; }
  @media (max-width: 486px) {
    ._2cyCJXFYrelNL4ExENW0dt {
      width: 100%;
      padding: 0 16px;
      height: 100%; } }
  ._2cyCJXFYrelNL4ExENW0dt ._3LNUEWamSVWXPxQjrannvF {
    display: flex;
    justify-content: space-between; }
    ._2cyCJXFYrelNL4ExENW0dt ._3LNUEWamSVWXPxQjrannvF label input {
      width: 180px; }
      @media (max-width: 486px) {
        ._2cyCJXFYrelNL4ExENW0dt ._3LNUEWamSVWXPxQjrannvF label input {
          width: auto;
          width: initial; } }
    @media (max-width: 486px) {
      ._2cyCJXFYrelNL4ExENW0dt ._3LNUEWamSVWXPxQjrannvF {
        flex-direction: column; } }
  ._2cyCJXFYrelNL4ExENW0dt label {
    display: flex;
    flex-direction: column; }
    @media (max-width: 486px) {
      ._2cyCJXFYrelNL4ExENW0dt label {
        width: calc(100% - 34px); } }
  ._2cyCJXFYrelNL4ExENW0dt ._2FKkMiaiyleGgR8T8EOcaI {
    border-color: #ff7373; }
  ._2cyCJXFYrelNL4ExENW0dt ._1rgJqQe4x70Shfh-HU6d50 {
    border-color: #00dc32; }

.w33yw7LQHY8IPcDC_iviM {
  background: #0078e6; }

._3GLl409u4JFgKSMS43uEGL {
  width: 400px; }
  @media (max-width: 486px) {
    ._3GLl409u4JFgKSMS43uEGL {
      width: 100%; } }

._3exIbgWOAQmQcmBMrP3izk { }

._1-d_A4xNzm1hgxmCjeos5E { }

.Nvl1bBkbJUO70-GWrZQ9H { }

._2FKkMiaiyleGgR8T8EOcaI { }

._19KX89HHgSB84wpm1EZ08b {
  margin-bottom: -24px; }

._2w-JU2NcGTQsmVOhD3AbHi {
  display: flex;
  flex-direction: column; }

._3sBq5pvnsbw_dROZbiqdV3 {
  padding: 10px;
  font-size: 1.125em;
  margin-top: 8px;
  border: #d5d5d5 solid 1px;
  height: 48px;
  outline: none;
  border-radius: 0; }
  ._3sBq5pvnsbw_dROZbiqdV3:last-of-type {
    margin-bottom: 24px; }
    @media (max-width: 486px) {
      ._3sBq5pvnsbw_dROZbiqdV3:last-of-type {
        margin-bottom: 16px; } }

._3jLyBEgZ8hfmUHCcYOjO9k {
  border-color: #ff7373; }

.HmauCFwvFfVwhNj0ow9ZY {
  border-color: #00dc32; }

._32lHVAV8xFGw9FUw5-oqb_ {
  width: 534px; }
  @media (max-width: 1192px) {
    ._32lHVAV8xFGw9FUw5-oqb_ {
      width: 100%; } }
  @media (max-width: 486px) {
    ._32lHVAV8xFGw9FUw5-oqb_ {
      width: 100%; } }

._1uULFUJZ8FH_3XEMdRAeAl {
  text-transform: uppercase;
  font-size: 1.125em;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: #0078e6;
  margin: 65px 0 8px 0; }
  @media (max-width: 768px) {
    ._1uULFUJZ8FH_3XEMdRAeAl {
      margin: 64px 0 0 0; } }

.sMn_1TqXcaYGroAC_D4l4 {
  text-transform: uppercase;
  font-size: 2.375em;
  font-weight: 800;
  line-height: 0.84;
  color: #143264;
  margin: 0;
  margin-bottom: 16px; }
  @media (max-width: 486px) {
    .sMn_1TqXcaYGroAC_D4l4 {
      font-size: 1.75em;
      line-height: 0.93; } }

.r6fPUhXy7-q3-auAxtDb8 {
  font-size: 1.125em;
  line-height: 1.56;
  color: #000000;
  font-weight: normal; }
  @media (max-width: 486px) {
    .r6fPUhXy7-q3-auAxtDb8 {
      margin-bottom: 14px; } }

.coYJCwfABwp1H3Pqs4u6S {
  background: #0078e6;
  width: 100%;
  height: 721px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (max-width: 486px) {
    .coYJCwfABwp1H3Pqs4u6S {
      height: 100%; } }

._4cBf3dKrqRhrXJY02QW6g {
  text-transform: uppercase;
  margin: 35px 0 56px 0;
  width: 534px;
  height: 64px;
  font-size: 2.375em;
  font-weight: 800;
  line-height: 0.84;
  letter-spacing: -0.1px;
  text-align: center; }
  @media (max-width: 486px) {
    ._4cBf3dKrqRhrXJY02QW6g {
      width: 100%;
      font-size: 1.75em;
      line-height: 0.93; } }

._2zLPhRmb51l8xNdiWhyCDu {
  background: #0078e6;
  color: white;
  padding-bottom: 80px;
  position: relative;
  z-index: 2; }
  @media (max-width: 768px) {
    ._2zLPhRmb51l8xNdiWhyCDu {
      padding-bottom: 23px; } }

@media (max-width: 1280px) {
  ._2zLPhRmb51l8xNdiWhyCDu .notch {
    display: none; } }

._3uepwmbjAyVK5ZRZFvDoor {
  padding-bottom: 1rem; }

._3eVN55MeulG33t4jrhhbPC {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 100px;
  padding-top: 16px; }
  @media (max-width: 1280px) {
    ._3eVN55MeulG33t4jrhhbPC {
      justify-content: flex-start;
      margin-bottom: 64px; } }
  @media (max-width: 768px) {
    ._3eVN55MeulG33t4jrhhbPC {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 28px;
      margin-bottom: 40px; } }

._1nGgfnBDivokBmYcNA2qIp {
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: 100px; }
  @media (max-width: 1024px) {
    ._1nGgfnBDivokBmYcNA2qIp {
      margin-left: 32px; } }
  @media (max-width: 768px) {
    ._1nGgfnBDivokBmYcNA2qIp {
      margin-left: 0; } }
  ._1nGgfnBDivokBmYcNA2qIp:first-of-type {
    margin-left: 0; }
  ._1nGgfnBDivokBmYcNA2qIp:last-of-type {
    margin-top: 40px;
    margin-right: 48px; }
    @media (max-width: 1024px) {
      ._1nGgfnBDivokBmYcNA2qIp:last-of-type {
        margin-right: 0; } }
  ._1nGgfnBDivokBmYcNA2qIp span {
    font-size: 2em;
    line-height: 0.8;
    font-weight: 900;
    margin-right: 6px;
    min-width: 122px;
    text-align: right; }
    @media (max-width: 384px) {
      ._1nGgfnBDivokBmYcNA2qIp span {
        font-size: 1.875em; } }
    @media (max-width: 342px) {
      ._1nGgfnBDivokBmYcNA2qIp span {
        font-size: 1.75em;
        min-width: 100px; } }
  ._1nGgfnBDivokBmYcNA2qIp b {
    font-size: 1.125em;
    font-weight: 800;
    line-height: 0.9; }

._2dLUGRJYwOcX0Gta1Sc533 {
  color: #143264;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase; }
  ._2dLUGRJYwOcX0Gta1Sc533 span {
    padding-right: 3px; }
    ._2dLUGRJYwOcX0Gta1Sc533 span._2fdnVXexZpdDATwAyj_0hY {
      color: white;
      display: block;
      font-size: 66px;
      font-weight: 900;
      padding: .5rem 0 1rem; }
      @media (max-width: 768px) {
        ._2dLUGRJYwOcX0Gta1Sc533 span._2fdnVXexZpdDATwAyj_0hY {
          line-height: .8em; } }

._8sRzuhmuzqf3TYho8nz0Z {
  position: relative;
  z-index: 2; }
  ._8sRzuhmuzqf3TYho8nz0Z p {
    font-size: 24px;
    line-height: 42px;
    padding: 2rem 0 3rem;
    width: 85%; }
    @media (max-width: 768px) {
      ._8sRzuhmuzqf3TYho8nz0Z p {
        margin-top: -2rem;
        padding-top: 0; } }
    ._8sRzuhmuzqf3TYho8nz0Z p._2dLUGRJYwOcX0Gta1Sc533 {
      padding: 0; }

._28a5v68KBinvkaFz0CcpX7 {
  margin-top: -220px;
  pointer-events: none;
  position: relative;
  z-index: 2; }
  @media (max-width: 1280px) {
    ._28a5v68KBinvkaFz0CcpX7 {
      margin-top: 0; } }

._3NyTcEX5T8jY1umBVrZz3u {
  display: flex;
  margin-top: 40px;
  color: white; }
  @media (max-width: 768px) {
    ._3NyTcEX5T8jY1umBVrZz3u {
      margin-top: 32px; } }

._3NyTcEX5T8jY1umBVrZz3u a,
._3NyTcEX5T8jY1umBVrZz3u span {
  color: white;
  width: auto;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1); }
  @media (max-width: 560px) {
    ._3NyTcEX5T8jY1umBVrZz3u a,
    ._3NyTcEX5T8jY1umBVrZz3u span {
      height: 39px; } }

._21LoxsfJwkNMIYgHZluC6c {
  font-size: 1.5em;
  font-weight: 900;
  display: flex;
  align-items: center; }
  @media (max-width: 560px) {
    ._21LoxsfJwkNMIYgHZluC6c {
      font-size: 1.125em;
      overflow: auto; } }

._21LoxsfJwkNMIYgHZluC6c li {
  position: relative;
  margin-right: 22px; }
  @media (max-width: 560px) {
    ._21LoxsfJwkNMIYgHZluC6c li {
      margin-right: 22px; } }
  @media (max-width: 360px) {
    ._21LoxsfJwkNMIYgHZluC6c li {
      margin-right: 10px; } }
  ._21LoxsfJwkNMIYgHZluC6c li:last-of-type {
    margin-right: 0; }

._21LoxsfJwkNMIYgHZluC6c li a {
  white-space: nowrap;
  cursor: pointer; }

._21LoxsfJwkNMIYgHZluC6c li._3AER-ObDwst3Vuzrp0bfOo {
  opacity: 1; }

._21LoxsfJwkNMIYgHZluC6c li._3AER-ObDwst3Vuzrp0bfOo a {
  color: #0078e6;
  background: white;
  width: 49px; }
  @media (max-width: 560px) {
    ._21LoxsfJwkNMIYgHZluC6c li._3AER-ObDwst3Vuzrp0bfOo a {
      width: 39px; } }

._3NyTcEX5T8jY1umBVrZz3u ._1ah86WaRNwjS0kgiv__-aK {
  background: white;
  width: 49px;
  height: 49px;
  cursor: pointer;
  margin-left: 72px; }
  @media (max-width: 560px) {
    ._3NyTcEX5T8jY1umBVrZz3u ._1ah86WaRNwjS0kgiv__-aK {
      width: 39px;
      height: 39px; } }
  @media (max-width: 768px) {
    ._3NyTcEX5T8jY1umBVrZz3u ._1ah86WaRNwjS0kgiv__-aK {
      margin-left: 22px; } }

._3NyTcEX5T8jY1umBVrZz3u ._1ah86WaRNwjS0kgiv__-aK svg {
  width: 21.7px;
  height: 15.7px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  fill: currentColor;
  color: #0078e6; }
  @media (max-width: 560px) {
    ._3NyTcEX5T8jY1umBVrZz3u ._1ah86WaRNwjS0kgiv__-aK svg {
      width: 17.3px;
      height: 12.5px; } }

._3NyTcEX5T8jY1umBVrZz3u ._1OfOJOAlMhWx_myDQ27EBn {
  margin-right: 72px;
  margin-left: 0; }
  @media (max-width: 768px) {
    ._3NyTcEX5T8jY1umBVrZz3u ._1OfOJOAlMhWx_myDQ27EBn {
      margin-right: 22px; } }

._3NyTcEX5T8jY1umBVrZz3u ._1OfOJOAlMhWx_myDQ27EBn svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.Y_vCkic-Y6DfX-rpmXA4x {
  width: 448px;
  min-height: 271px;
  border-style: dashed;
  border-color: #dcdcdc;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  transition: background-color 300ms ease; }
  @media (max-width: 486px) {
    .Y_vCkic-Y6DfX-rpmXA4x {
      width: 100%; } }
  .Y_vCkic-Y6DfX-rpmXA4x svg {
    margin-bottom: 8px; }
  .Y_vCkic-Y6DfX-rpmXA4x ._1MUDuTWlK-OLZ-9ob6gkCj {
    font-size: 1.125em;
    font-weight: bold;
    width: 235px;
    line-height: 1.56; }
  .Y_vCkic-Y6DfX-rpmXA4x ._3rIW70j0plyoAjWXcVNE8G {
    color: #0078e6; }
  .Y_vCkic-Y6DfX-rpmXA4x:hover {
    background-color: #dcdcdc;
    border-color: #c1c1c1; }

._23Q2GIx0Djxbsmdj7eoxKp {
  background-color: #dcdcdc; }

._Yw99RF1QfABye2ggbr2T {
  width: 448px;
  min-height: 271px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0078e6; }
  @media (max-width: 486px) {
    ._Yw99RF1QfABye2ggbr2T {
      width: 100%; } }
  ._Yw99RF1QfABye2ggbr2T svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    margin-bottom: 8px; }
  ._Yw99RF1QfABye2ggbr2T ._1MUDuTWlK-OLZ-9ob6gkCj {
    font-size: 1.125em;
    font-weight: bold;
    color: white;
    line-height: 1.56;
    text-align: center;
    display: flex;
    align-items: center;
    flex-flow: column; }
  ._Yw99RF1QfABye2ggbr2T ._14TWCkorJeD40TZMDKb5-l {
    color: white;
    text-decoration: underline;
    cursor: pointer;
    font-weight: normal; }

._2j5A3Gz84uMBtQOEq_wHXc {
  max-width: 390px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block; }
  @media (max-width: 486px) {
    ._2j5A3Gz84uMBtQOEq_wHXc {
      max-width: 80%; } }

._1W7QCYETzhAYY4tg7nLtdm {
  margin-top: 26px;
  margin-bottom: 42px;
  display: flex;
  align-items: flex-start;
  color: black;
  font-size: 1.125em;
  cursor: pointer; }
  ._1W7QCYETzhAYY4tg7nLtdm label {
    display: inline-block !important; }
  ._1W7QCYETzhAYY4tg7nLtdm span {
    position: relative;
    width: 20px;
    min-width: 20px;
    cursor: pointer;
    height: 20px;
    display: inline-block;
    border-radius: 20px;
    background-color: white;
    margin-right: 10px;
    border: 2px solid #d6d6d6; }
    ._1W7QCYETzhAYY4tg7nLtdm span::before {
      position: absolute;
      transition: all 0.2s ease;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
      margin: auto;
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 20px;
      background: #0078e6; }
  ._1W7QCYETzhAYY4tg7nLtdm a {
    color: #0078e6;
    font-weight: bold;
    text-decoration: underline;
    position: relative; }
  ._1W7QCYETzhAYY4tg7nLtdm input {
    -webkit-appearance: none;
            appearance: none;
    position: absolute;
    left: -9999px;
    top: -9999px; }
  ._1W7QCYETzhAYY4tg7nLtdm._2smXYqH-g8KVNglfydE6tk span::before {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }

._13-zZawrkZGxVr0VVsBSk2 {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex: 1 1;
  flex-wrap: wrap;
  margin-bottom: -20px; }
  @media (max-width: 768px) {
    ._13-zZawrkZGxVr0VVsBSk2 {
      margin-left: -16px;
      margin-right: -16px;
      margin-bottom: -8px; } }

._3rqYumrcdfAw2pJK-dbtCz {
  background: white;
  padding: 32px;
  display: block;
  width: calc(33.333333% - 20px);
  margin: 0 10px;
  margin-bottom: 20px;
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  max-width: 372px;
  min-height: 400px; }
  @media (max-width: 1180px) {
    ._3rqYumrcdfAw2pJK-dbtCz {
      width: calc(50% - 20px);
      max-width: calc(50% - 20px); } }
  @media (max-width: 768px) {
    ._3rqYumrcdfAw2pJK-dbtCz {
      width: calc(100%);
      max-width: calc(100%);
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 8px;
      min-height: 100px; } }

._3G9EOWbQgNweSQzjeIDThc {
  color: #143264;
  font-weight: 900;
  line-height: 0.84;
  text-transform: uppercase;
  font-size: 2em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 16px;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto; }
  @media (max-width: 1024px) {
    ._3G9EOWbQgNweSQzjeIDThc {
      font-size: 1.75em; } }

._2kkFma4FzGOHLvesU2ewRf {
  color: #000;
  text-transform: uppercase;
  font-size: 1.125em;
  margin-bottom: 12px;
  display: block; }

._2ttsfj5mZ_T4kRQa-peXJe {
  text-transform: uppercase;
  font-size: 1.125em;
  margin-bottom: 12px;
  display: block;
  color: #ffa800;
  font-weight: 500; }

._1bNlEYjwu8C2s6DH2UGvK1 {
  color: #565656;
  font-weight: 300;
  margin: 0;
  font-size: 1.125em; }

._1bNlEYjwu8C2s6DH2UGvK1 p {
  margin: 0;
  font-size: 1em;
  line-height: 1.78;
  padding-bottom: 0;
  padding-top: 0; }

._1bNlEYjwu8C2s6DH2UGvK1 span {
  font-size: 1em; }

._3_0-nuLVVLcIY5omyjo55i {
  position: absolute;
  right: -13px;
  top: -13px; }
  @media (max-width: 768px) {
    ._3_0-nuLVVLcIY5omyjo55i {
      right: 32px;
      top: 32px; } }

.Ugf-R_K3LvMQKa4VOwnyI {
  color: #0078e6;
  font-weight: bold;
  display: block;
  margin-top: 8px; }
  .Ugf-R_K3LvMQKa4VOwnyI svg {
    margin-left: 5px;
    position: relative;
    top: -1px;
    transition: -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
    transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
    transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1); }

._1uH_W4qzgAgmZ9ko7-PxVj {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1; }

@media (min-width: 769px) {
  ._3rqYumrcdfAw2pJK-dbtCz:hover {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px); } }

@media (min-width: 769px) {
  ._3rqYumrcdfAw2pJK-dbtCz:hover .Ugf-R_K3LvMQKa4VOwnyI svg {
    -webkit-transform: translateX(4px);
            transform: translateX(4px); } }

._1A0i68qMkkjj_V-FRQtID5 {
  display: flex; }

._3-DiVFwRsuRL2yJk_ms7yr {
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 120px; }
  @media (max-width: 960px) {
    ._3-DiVFwRsuRL2yJk_ms7yr {
      justify-content: flex-start;
      flex-direction: column;
      height: auto; } }
  @media (max-width: 768px) {
    ._3-DiVFwRsuRL2yJk_ms7yr {
      margin-bottom: 56px; } }

._2l2jcEZmXfOxKQJTM_Uprg {
  width: 80%;
  margin-top: 32px;
  margin-right: 32px; }
  @media (max-width: 960px) {
    ._2l2jcEZmXfOxKQJTM_Uprg {
      margin-right: 0;
      margin-bottom: 48px; } }
  ._2l2jcEZmXfOxKQJTM_Uprg h3 {
    color: #0078e6;
    font-weight: 500;
    margin: 4px;
    text-transform: uppercase;
    font-size: 1.5em; }
    @media (max-width: 768px) {
      ._2l2jcEZmXfOxKQJTM_Uprg h3 {
        font-size: 1.125em; } }
  ._2l2jcEZmXfOxKQJTM_Uprg h1 {
    color: #143264;
    font-size: 4.125em;
    font-weight: 900;
    margin: 0 0 24px 0;
    line-height: 53px;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      ._2l2jcEZmXfOxKQJTM_Uprg h1 {
        font-size: 3em;
        line-height: 0.79; } }
  ._2l2jcEZmXfOxKQJTM_Uprg p {
    margin: 32px 0;
    font-size: 1.5em;
    font-weight: normal; }
    @media (max-width: 768px) {
      ._2l2jcEZmXfOxKQJTM_Uprg p {
        font-size: 1.125em;
        line-height: 1.33;
        width: calc(100% - 54px); } }
    @media (max-width: 486px) {
      ._2l2jcEZmXfOxKQJTM_Uprg p {
        width: 100%; } }
  @media (max-width: 768px) {
    ._2l2jcEZmXfOxKQJTM_Uprg {
      margin-top: 48px; } }

._3G79IIBmvJ0D7zFp_tKWuz {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0;
  align-items: center; }

._3LxQMknHs1inbV2bFg0QtI {
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 90px;
  background: #0078e6;
  z-index: 2;
  margin-top: 240px; }
  @media (max-width: 369px) {
    ._3LxQMknHs1inbV2bFg0QtI {
      width: 30px;
      height: 30px; } }

._3FbGvnNM1z7NdYi37MXWoW {
  background: #0078e6;
  width: 48px;
  height: 48px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.375em;
  font-weight: 700;
  position: relative; }
  @media (max-width: 369px) {
    ._3FbGvnNM1z7NdYi37MXWoW {
      width: 30px;
      height: 30px; } }

._3WjK316MLDPjUXOtWd3gyl::after {
  content: '';
  background: #0078e6;
  width: 3px;
  height: 330px;
  position: absolute;
  top: 48px; }
  @media (max-width: 369px) {
    ._3WjK316MLDPjUXOtWd3gyl::after {
      top: 30px;
      height: 312px; } }

.sObiW40gZ5nCg-f9sRrt_ {
  margin-top: 42px; }

._3mAHsJaaC4FjJh_26j1WEu {
  width: 0px;
  height: 192px;
  border-color: #b2b1b1;
  border-style: dashed;
  border-width: 2px;
  position: relative; }
  ._3mAHsJaaC4FjJh_26j1WEu::after {
    content: '';
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9));
    position: absolute;
    height: 208px;
    width: 8px;
    bottom: -4px;
    left: -4px; }
  @media (max-width: 486px) {
    ._3mAHsJaaC4FjJh_26j1WEu {
      align-self: center; } }

._3bN1zABBeXVqrdHfZCwBcL {
  display: flex;
  flex-direction: column;
  margin-left: calc(48px + 24px); }
  @media (max-width: 768px) {
    ._3bN1zABBeXVqrdHfZCwBcL {
      margin-left: 24px; } }

._3AZRC7ldHTNEQhmdr4LkiH {
  width: 350px;
  height: 345px;
  position: relative; }
  ._3AZRC7ldHTNEQhmdr4LkiH::after {
    position: absolute;
    content: '';
    background: #143264;
    width: 62px;
    height: 2px;
    left: -62px;
    top: 21px; }
    @media (max-width: 768px) {
      ._3AZRC7ldHTNEQhmdr4LkiH::after {
        width: 15px;
        left: -15px; } }
  @media (max-width: 486px) {
    ._3AZRC7ldHTNEQhmdr4LkiH {
      width: calc(100%);
      height: 324px; } }
  @media (max-width: 369px) {
    ._3AZRC7ldHTNEQhmdr4LkiH {
      width: 227px;
      height: 100%; } }

._1QPZSz-JuOnDJZam5st4Oi {
  background: #143264;
  color: white;
  padding: 33px 40px; }
  ._1QPZSz-JuOnDJZam5st4Oi h2 {
    font-size: 1.5em;
    line-height: 24px;
    margin: 0 0 16px 0;
    font-weight: 800;
    text-transform: uppercase; }
    @media (max-width: 486px) {
      ._1QPZSz-JuOnDJZam5st4Oi h2 {
        font-size: 1.375em;
        line-height: 1; } }
  ._1QPZSz-JuOnDJZam5st4Oi p {
    font-size: 1.125em;
    line-height: 24px;
    margin: 0; }
    @media (max-width: 486px) {
      ._1QPZSz-JuOnDJZam5st4Oi p {
        font-size: 1em;
        line-height: 1.25; } }
  @media (max-width: 486px) {
    ._1QPZSz-JuOnDJZam5st4Oi {
      padding: 24px 24px; } }
  @media (max-width: 369px) {
    ._1QPZSz-JuOnDJZam5st4Oi {
      padding: 10px 10px; } }

._2stg9d2c66-q-aveivhV29 {
  margin-top: 36px; }
  ._2stg9d2c66-q-aveivhV29 h2 {
    color: white;
    position: absolute;
    z-index: 2;
    bottom: 0;
    margin-left: 32px;
    margin-right: 32px;
    font-size: 1.75em;
    text-transform: uppercase;
    font-weight: 800; }
    @media (max-width: 768px) {
      ._2stg9d2c66-q-aveivhV29 h2 {
        margin-left: 24px;
        margin-right: 24px; } }
  ._2stg9d2c66-q-aveivhV29 img {
    width: 100%;
    height: 100%; }
  ._2stg9d2c66-q-aveivhV29::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    mix-blend-mode: multiply;
    background-blend-mode: multiply, multiply;
    background-image: linear-gradient(to bottom, rgba(0, 120, 230, 0.8), rgba(0, 120, 230, 0.8)), linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); }
  @media (max-width: 486px) {
    ._2stg9d2c66-q-aveivhV29 {
      margin-top: 52px; } }
  @media (max-width: 369px) {
    ._2stg9d2c66-q-aveivhV29 {
      margin-top: 42px; } }

._26Vl3dpwOm-Aj9_Ztd70EO {
  align-items: center;
  background-color: #eef;
  background-image: url(/static/media/bedrijfsfilmpreview.403f8402.jpg);
  background-size: cover;
  display: flex;
  height: 517px;
  justify-content: center;
  margin-top: 32px;
  width: 397px; }
  ._26Vl3dpwOm-Aj9_Ztd70EO ._22Bbg9ctIu563-fpk4rhiZ {
    background-color: white;
    background-image: url(/static/media/playbutton.4209e920.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px auto;
    display: block;
    height: 50px;
    width: 50px; }

.blueprint {
  display: none !important; }

._1K8YIGqbhTViKBtuh9CD3p {
  position: relative;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 258px; }
  @media (max-width: 768px) {
    ._1K8YIGqbhTViKBtuh9CD3p {
      margin-top: 56px;
      margin-bottom: 56px; } }
  @media (max-width: 486px) {
    ._1K8YIGqbhTViKBtuh9CD3p {
      margin-top: 32px; } }
  ._1K8YIGqbhTViKBtuh9CD3p h3 {
    text-transform: uppercase;
    color: #0078e6;
    font-weight: 500;
    margin: 4px; }
    @media (max-width: 768px) {
      ._1K8YIGqbhTViKBtuh9CD3p h3 {
        font-size: 1.125em; } }
  ._1K8YIGqbhTViKBtuh9CD3p h1 {
    font-size: 4.125em;
    font-weight: 900;
    line-height: 0.8;
    color: #143264;
    margin: 0 0 24px 0;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      ._1K8YIGqbhTViKBtuh9CD3p h1 {
        font-size: 3em;
        line-height: 0.79; } }
  ._1K8YIGqbhTViKBtuh9CD3p p {
    max-width: 968px;
    font-size: 1.5em;
    line-height: 1.75;
    color: black; }
    @media (max-width: 768px) {
      ._1K8YIGqbhTViKBtuh9CD3p p {
        width: auto;
        font-size: 1.125em;
        line-height: 1.56; } }
  ._1K8YIGqbhTViKBtuh9CD3p ._3KTUpDE8Zn7DMA_sQX0dIa {
    margin: 0 0 40px 0; }

._308uE3Rx2syw6VwOaZlSyx {
  padding: 0 48px;
  position: relative;
  color: black;
  font-weight: bold;
  font-size: 1em;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 32px;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  @media (max-width: 486px) {
    ._308uE3Rx2syw6VwOaZlSyx {
      width: 100%;
      padding: 0 16px;
      height: 100%; } }
  ._308uE3Rx2syw6VwOaZlSyx ._3l56SyMlIzmuOvnDvmIQ-E {
    display: flex;
    justify-content: space-between; }
    ._308uE3Rx2syw6VwOaZlSyx ._3l56SyMlIzmuOvnDvmIQ-E label input {
      width: 180px; }
      @media (max-width: 486px) {
        ._308uE3Rx2syw6VwOaZlSyx ._3l56SyMlIzmuOvnDvmIQ-E label input {
          width: auto;
          width: initial; } }
    @media (max-width: 486px) {
      ._308uE3Rx2syw6VwOaZlSyx ._3l56SyMlIzmuOvnDvmIQ-E {
        flex-direction: column; } }
  ._308uE3Rx2syw6VwOaZlSyx label {
    display: flex;
    flex-direction: column; }
    @media (max-width: 486px) {
      ._308uE3Rx2syw6VwOaZlSyx label {
        width: calc(100% - 34px); } }

._-5L8v-8nZN53BV9PJQQ-x {
  background: #0078e6; }

.mTHq-SBpeqUGW0odDKm7y {
  width: 400px; }
  @media (max-width: 486px) {
    .mTHq-SBpeqUGW0odDKm7y {
      width: 100%; } }

._9YgreQJ62O2M9Ct3K8bt- {
  content: "";
  position: absolute;
  transition: width 100ms ease;
  background: #0078e6;
  height: 3px;
  bottom: 0;
  width: 0;
  z-index: 3; }
  @media (max-width: 786px) {
    ._9YgreQJ62O2M9Ct3K8bt- {
      display: none; } }

._36gc4bobo_AW5ity4Cja1q {
  width: 50%; }

._188KuFZx3kP0rwAFqHzjWQ {
  width: 100%; }

._379WsCvjFfOe4hob58eGSt {
  width: 85px;
  font-size: 1.125em;
  color: #0078e6;
  font-weight: bold;
  cursor: pointer;
  margin-top: 24px;
  margin-left: 7px; }
  ._379WsCvjFfOe4hob58eGSt svg {
    position: relative;
    margin-right: 8px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    right: 0;
    transition: right 100ms ease; }
  ._379WsCvjFfOe4hob58eGSt:hover svg {
    right: 8px; }

._1_aBmpGQbBPB7doR5TEc8o {
  color: #ff7373;
  font-size: 1em;
  line-height: 1;
  margin-bottom: 24px;
  font-weight: normal; }

._1_aBmpGQbBPB7doR5TEc8o span {
  display: block;
  margin-bottom: 4px; }
  ._1_aBmpGQbBPB7doR5TEc8o span:last-of-type {
    margin-bottom: 0; }

._1_aBmpGQbBPB7doR5TEc8o span svg {
  margin-right: 8px; }

._2eo0-klFJ9nwrw0kGasday h3 {
  text-transform: uppercase;
  color: #0078e6;
  font-weight: 500;
  margin: 4px;
  margin-left: -1px; }
  @media (max-width: 768px) {
    ._2eo0-klFJ9nwrw0kGasday h3 {
      font-size: 1.125em;
      margin-left: 1px; } }

._2eo0-klFJ9nwrw0kGasday h1 {
  font-size: 4.125em;
  font-weight: 900;
  line-height: 0.8;
  color: #143264;
  margin: 0 0 24px 0;
  text-transform: uppercase;
  max-width: 760px; }
  @media (max-width: 768px) {
    ._2eo0-klFJ9nwrw0kGasday h1 {
      font-size: 3em;
      line-height: 0.79; } }

._2eo0-klFJ9nwrw0kGasday p {
  max-width: 968px;
  font-size: 1.5em;
  line-height: 1.75;
  color: #000000; }
  @media (max-width: 768px) {
    ._2eo0-klFJ9nwrw0kGasday p {
      width: auto;
      font-size: 1.125em;
      line-height: 1.56; } }

._2eo0-klFJ9nwrw0kGasday p a {
  color: #0078e6; }
  ._2eo0-klFJ9nwrw0kGasday p a:hover {
    text-decoration: underline; }

._1Ijv8S2GIYbAhxw37DZT_B {
  margin: 0 0 40px 0; }

._2QfcKmCgZ2NkOghD_HXSh- {
  display: flex; }
  ._2QfcKmCgZ2NkOghD_HXSh- a:first-of-type {
    margin-right: 50px; }
  @media (max-width: 470px) {
    ._2QfcKmCgZ2NkOghD_HXSh- {
      flex-direction: column;
      height: 200px;
      justify-content: space-evenly; } }

._2UPJCx-4v6su71JkcB-G0f p {
  color: white; }

._2UPJCx-4v6su71JkcB-G0f p a {
  color: #143264; }

._2UPJCx-4v6su71JkcB-G0f h1 {
  color: white; }

._2UPJCx-4v6su71JkcB-G0f h3 {
  color: #143264; }

.UO3GVOh6ZjrZlRUG6GhA3 p {
  color: white; }

.UO3GVOh6ZjrZlRUG6GhA3 h1 {
  color: white; }

.UO3GVOh6ZjrZlRUG6GhA3 h3 {
  color: #0078e6; }

._3RfrQVBlYxCSPD674Z-TKB {
  position: relative;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  background: #0078e6;
  padding-bottom: 90px; }
  @media (max-width: 768px) {
    ._3RfrQVBlYxCSPD674Z-TKB {
      padding-top: 56px;
      padding-bottom: 56px; } }
  @media (max-width: 486px) {
    ._3RfrQVBlYxCSPD674Z-TKB {
      padding-top: 32px; } }

.yf_VPh1yiBns_lVbDuP33 {
  display: flex;
  flex-flow: wrap;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -48px;
  margin-top: 88px; }
  @media (max-width: 1024px) {
    .yf_VPh1yiBns_lVbDuP33 {
      flex-flow: column;
      margin-bottom: 0;
      margin-top: 56px; } }
  @media (max-width: 768px) {
    .yf_VPh1yiBns_lVbDuP33 {
      margin-bottom: -24px; } }

._4cySQW7j1r2qocZouhf2D {
  display: flex;
  width: calc(50% - 48px);
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 76px; }
  @media (max-width: 1024px) {
    ._4cySQW7j1r2qocZouhf2D {
      width: calc(100% - 48px);
      margin-bottom: 24px; } }
  @media (max-width: 443px) {
    ._4cySQW7j1r2qocZouhf2D {
      flex-flow: column;
      justify-content: flex-start; } }

.nlPJ6I6JwoBk6vMda7Q1J {
  margin-top: 4px;
  min-width: 110px; }
  @media (max-width: 768px) {
    .nlPJ6I6JwoBk6vMda7Q1J {
      min-width: 78px;
      height: auto; } }

._1owupHIh1nncdKqOM0V1Qv {
  margin-left: 40px;
  display: flex;
  flex-flow: column;
  width: calc(100% - 110px - 40px);
  text-transform: uppercase; }
  @media (max-width: 1024px) {
    ._1owupHIh1nncdKqOM0V1Qv {
      max-width: 407px; } }
  @media (max-width: 768px) {
    ._1owupHIh1nncdKqOM0V1Qv {
      margin-left: 24px;
      width: calc(100% - 110px - 24px); } }
  @media (max-width: 443px) {
    ._1owupHIh1nncdKqOM0V1Qv {
      margin-left: 0;
      width: 100%;
      margin-top: 16px; } }

._2LbIEU4PP1tk35QoFdPaAB {
  font-size: 2.375em;
  color: white;
  font-weight: 800;
  line-height: 0.84;
  display: block; }
  @media (max-width: 768px) {
    ._2LbIEU4PP1tk35QoFdPaAB {
      font-size: 1.5em; } }

._3UaAWgcer8ChZU-7zyb4Xr {
  display: block;
  margin-top: 6px;
  font-weight: 500;
  line-height: 0.88;
  color: #143264;
  font-size: 1.5em; }
  @media (max-width: 768px) {
    ._3UaAWgcer8ChZU-7zyb4Xr {
      font-size: 1.125em; } }

._1OGPey1MFeyLhCeuZooScl {
  position: relative;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  background: #143264;
  padding-bottom: 145px; }
  @media (max-width: 768px) {
    ._1OGPey1MFeyLhCeuZooScl {
      padding-top: 56px;
      padding-bottom: 8px; } }
  @media (max-width: 486px) {
    ._1OGPey1MFeyLhCeuZooScl {
      padding-top: 32px; } }
  ._1OGPey1MFeyLhCeuZooScl ._3VpVeZcZV8-YADiEY5yQk9 {
    display: flex;
    flex-flow: wrap; }
    ._1OGPey1MFeyLhCeuZooScl ._3VpVeZcZV8-YADiEY5yQk9 div {
      display: flex;
      align-items: flex-start; }
    ._1OGPey1MFeyLhCeuZooScl ._3VpVeZcZV8-YADiEY5yQk9 span {
      width: 120px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
    ._1OGPey1MFeyLhCeuZooScl ._3VpVeZcZV8-YADiEY5yQk9 h2 {
      width: 324px;
      height: 96px;
      font-size: 38px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.84;
      letter-spacing: normal;
      margin-left: 40px;
      margin-right: 32px;
      display: flex;
      align-items: center; }
  ._1OGPey1MFeyLhCeuZooScl ._1GKQEzvY3PZORiODP3sNkZ {
    display: flex;
    flex-flow: wrap;
    width: 110%;
    padding-bottom: 16px; }

._2uvo_5_42CKRIIjggOWLtl {
  width: 550px;
  margin-bottom: 48px;
  margin-right: 47px; }
  ._2uvo_5_42CKRIIjggOWLtl h2 {
    font-size: 38px;
    margin-top: 24px;
    margin-bottom: 0;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.84;
    letter-spacing: normal;
    color: #ffffff;
    text-transform: uppercase; }
  ._2uvo_5_42CKRIIjggOWLtl ._1S6IzQTJyHuB-QyOoWKePw {
    font-size: 24px;
    font-weight: normal;
    color: #0078e6;
    text-transform: uppercase;
    margin-top: 8px;
    display: inline-block; }
  ._2uvo_5_42CKRIIjggOWLtl p {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 0; }

._3x9tAY3SQTPDDUMtV8YMZc {
  display: inline-block;
  color: white;
  margin-top: 16px; }

._3x9tAY3SQTPDDUMtV8YMZc svg {
  width: 32px;
  height: 32px;
  margin-right: 16px; }

._3x9tAY3SQTPDDUMtV8YMZc svg rect {
  fill: #0078e6; }

._3x9tAY3SQTPDDUMtV8YMZc svg path {
  fill: white; }

._3DyukxkQ-PPjStRwiRGpej {
  position: relative;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px; }
  @media (max-width: 768px) {
    ._3DyukxkQ-PPjStRwiRGpej {
      margin-top: 56px;
      margin-bottom: 56px; } }
  @media (max-width: 486px) {
    ._3DyukxkQ-PPjStRwiRGpej {
      margin-top: 32px; } }

._2S_ouN3MgDZEevFuo51OTk {
  position: relative;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 112px; }
  @media (max-width: 768px) {
    ._2S_ouN3MgDZEevFuo51OTk {
      margin-top: 56px;
      padding-bottom: 56px; } }
  @media (max-width: 486px) {
    ._2S_ouN3MgDZEevFuo51OTk {
      margin-top: 32px; } }
  ._2S_ouN3MgDZEevFuo51OTk .OVUnHV2QbhS848dQ6Th8A {
    display: flex;
    flex-flow: wrap;
    margin-top: 48px;
    justify-content: flex-start;
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: -28px; }

.XXj7uPcvyJIYRRu2GBZmH {
  margin-bottom: 28px;
  width: calc(25% - 16px);
  margin-left: 8px;
  margin-right: 8px; }
  @media (max-width: 1024px) {
    .XXj7uPcvyJIYRRu2GBZmH {
      width: calc(50% - 16px); } }
  @media (max-width: 520px) {
    .XXj7uPcvyJIYRRu2GBZmH {
      width: calc(100% - 16px); } }
  .XXj7uPcvyJIYRRu2GBZmH h4 {
    font-size: 2.375em;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.84;
    letter-spacing: normal;
    color: #143264;
    text-transform: uppercase;
    margin: 0; }
  .XXj7uPcvyJIYRRu2GBZmH span {
    font-size: 1.5em;
    font-weight: normal;
    margin-top: 12px;
    display: block;
    color: #0078e6;
    text-transform: uppercase; }

._2NPp3X-I2HWe3ijmbvw4Id {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative; }
  @media (max-width: 1024px) {
    ._2NPp3X-I2HWe3ijmbvw4Id {
      flex-flow: column; } }

._3UdSwaDYpLG6vyDue5pLES {
  position: relative;
  margin-left: auto;
  display: flex;
  z-index: 2;
  width: 50%;
  max-width: 586px;
  padding-top: 190px; }
  @media (max-width: 1278px) {
    ._3UdSwaDYpLG6vyDue5pLES {
      margin-left: 50px;
      width: calc(50% - 50px); } }
  @media (max-width: 1024px) {
    ._3UdSwaDYpLG6vyDue5pLES {
      width: 100%;
      max-width: 100%;
      padding-top: 80px; } }
  @media (max-width: 768px) {
    ._3UdSwaDYpLG6vyDue5pLES {
      padding-top: 43px;
      margin-left: 0; } }

._1Ob6_WvVgUpaFJgoGnLumS {
  padding-bottom: 133px;
  opacity: 1;
  -webkit-transform: translateX(-200px);
          transform: translateX(-200px);
  transition: opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1), transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1), transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  padding-right: 32px; }
  @media (max-width: 1024px) {
    ._1Ob6_WvVgUpaFJgoGnLumS {
      padding-bottom: 42px;
      width: 100%;
      padding-right: 0; } }
  ._1Ob6_WvVgUpaFJgoGnLumS h1 {
    font-size: 4.125em;
    font-weight: 900;
    margin-bottom: 24px;
    margin-top: 0;
    text-transform: uppercase;
    line-height: 0.8; }
    @media (max-width: 768px) {
      ._1Ob6_WvVgUpaFJgoGnLumS h1 {
        font-size: 3em;
        line-height: 0.94;
        margin-bottom: 16px; } }
  ._1Ob6_WvVgUpaFJgoGnLumS p {
    font-size: 1.5em;
    line-height: 1.75;
    color: #ffffff;
    max-width: 518px;
    margin: 0; }
    @media (max-width: 768px) {
      ._1Ob6_WvVgUpaFJgoGnLumS p {
        font-size: 1.25em;
        line-height: 1.5;
        width: 100%; } }
  ._1Ob6_WvVgUpaFJgoGnLumS h4 {
    font-size: 1.5em;
    font-weight: 800;
    margin-top: 42px;
    margin-bottom: 8px;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      ._1Ob6_WvVgUpaFJgoGnLumS h4 {
        line-height: 0.86;
        margin-top: 32px;
        margin-bottom: 14px; } }
    @media (max-width: 486px) {
      ._1Ob6_WvVgUpaFJgoGnLumS h4 {
        max-width: 250px; } }
  ._1Ob6_WvVgUpaFJgoGnLumS .contact {
    padding-top: 16px;
    margin-bottom: 8px; }
    @media (max-width: 768px) {
      ._1Ob6_WvVgUpaFJgoGnLumS .contact {
        font-size: 1.25em; } }
  ._1Ob6_WvVgUpaFJgoGnLumS .button {
    margin-bottom: 0; }
    @media (max-width: 486px) {
      ._1Ob6_WvVgUpaFJgoGnLumS .button {
        width: 100%; } }

.y2_iKsIvWtpiYor45HA5_ {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0); }

._1RTQaHSdHaEItv-VylieOy {
  position: absolute;
  border-style: none;
  z-index: 3;
  bottom: 0;
  width: 50%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: flex;
  flex-direction: column; }
  @media (max-width: 1024px) {
    ._1RTQaHSdHaEItv-VylieOy {
      position: relative;
      height: 450px;
      width: 100%; } }

._1RTQaHSdHaEItv-VylieOy iframe {
  height: 100%;
  width: 100%; }

._9nkymGdHmtJsyPsuIvOJ- {
  position: relative;
  z-index: 2;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 768px) {
    ._9nkymGdHmtJsyPsuIvOJ- {
      margin-top: 56px; } }
  @media (max-width: 486px) {
    ._9nkymGdHmtJsyPsuIvOJ- {
      margin-top: 32px; } }
  ._9nkymGdHmtJsyPsuIvOJ- h1 {
    font-size: 4.125em;
    font-weight: 900;
    line-height: 0.8;
    color: #143264;
    margin: 0 0 24px 0;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      ._9nkymGdHmtJsyPsuIvOJ- h1 {
        font-size: 3em;
        line-height: 0.79; } }
    @media (max-width: 486px) {
      ._9nkymGdHmtJsyPsuIvOJ- h1 {
        font-size: 2em;
        line-height: 0.79; } }
  ._9nkymGdHmtJsyPsuIvOJ- p {
    max-width: 968px;
    font-size: 1.5em;
    line-height: 1.75;
    color: black;
    margin: 0 0 40px 0; }
    @media (max-width: 768px) {
      ._9nkymGdHmtJsyPsuIvOJ- p {
        width: auto;
        font-size: 1.125em;
        line-height: 1.56; } }

._2FXYWDpYqqmJNwzizxKGCM {
  display: flex;
  margin-bottom: 24px; }
  @media (max-width: 800px) {
    ._2FXYWDpYqqmJNwzizxKGCM {
      flex-flow: column; } }
  ._2FXYWDpYqqmJNwzizxKGCM:last-of-type {
    margin-bottom: 0; }
  ._2FXYWDpYqqmJNwzizxKGCM ._1zDxvDB8f5GME67_f2aEJp {
    max-width: 550px;
    width: 100%;
    height: 100%;
    position: relative; }
    @media (max-width: 1024px) {
      ._2FXYWDpYqqmJNwzizxKGCM ._1zDxvDB8f5GME67_f2aEJp {
        width: 434px; } }
    @media (max-width: 768px) {
      ._2FXYWDpYqqmJNwzizxKGCM ._1zDxvDB8f5GME67_f2aEJp {
        width: 534px; } }
    @media (max-width: 690px) {
      ._2FXYWDpYqqmJNwzizxKGCM ._1zDxvDB8f5GME67_f2aEJp {
        width: 100%; } }
    ._2FXYWDpYqqmJNwzizxKGCM ._1zDxvDB8f5GME67_f2aEJp h3 {
      width: 100%;
      margin: 16px;
      text-align: right;
      text-transform: uppercase;
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 1.75em;
      font-weight: 300;
      line-height: 0.96;
      letter-spacing: 0.6px;
      color: #ffffff;
      z-index: 2; }
      @media (max-width: 768px) {
        ._2FXYWDpYqqmJNwzizxKGCM ._1zDxvDB8f5GME67_f2aEJp h3 {
          font-size: 1.5em;
          line-height: 1.33;
          letter-spacing: 0.5px; } }
    ._2FXYWDpYqqmJNwzizxKGCM ._1zDxvDB8f5GME67_f2aEJp::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      mix-blend-mode: multiply;
      background-blend-mode: multiply, multiply;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)); }
  ._2FXYWDpYqqmJNwzizxKGCM ._2K4sdNS9Y1AuFFGy-hJX-m {
    margin-left: 45px; }
    @media (max-width: 1024px) {
      ._2FXYWDpYqqmJNwzizxKGCM ._2K4sdNS9Y1AuFFGy-hJX-m {
        margin-left: 24px;
        width: calc(100% - 434px - 24px); } }
    @media (max-width: 800px) {
      ._2FXYWDpYqqmJNwzizxKGCM ._2K4sdNS9Y1AuFFGy-hJX-m {
        margin-left: 0;
        width: 100%; } }
    ._2FXYWDpYqqmJNwzizxKGCM ._2K4sdNS9Y1AuFFGy-hJX-m h3 {
      text-transform: uppercase;
      font-size: 2.375em;
      font-weight: 800;
      line-height: 0.96;
      letter-spacing: 0.6px;
      line-height: 0.84;
      color: #143264;
      margin: 28px 0 24px 0; }
      @media (max-width: 768px) {
        ._2FXYWDpYqqmJNwzizxKGCM ._2K4sdNS9Y1AuFFGy-hJX-m h3 {
          width: 100%;
          font-size: 1.75em;
          line-height: 0.86;
          letter-spacing: normal; } }
    ._2FXYWDpYqqmJNwzizxKGCM ._2K4sdNS9Y1AuFFGy-hJX-m p {
      max-width: 489px;
      font-size: 1.125em;
      line-height: 1.33;
      color: #000000;
      margin: 0; }
      @media (max-width: 768px) {
        ._2FXYWDpYqqmJNwzizxKGCM ._2K4sdNS9Y1AuFFGy-hJX-m p {
          line-height: 1.56;
          width: 100%; } }
  @media (max-width: 486px) {
    ._2FXYWDpYqqmJNwzizxKGCM {
      flex-direction: column; } }

._12GwoKnCilwjYnMfILS73C {
  display: flex;
  flex-direction: column;
  padding-bottom: 127px;
  position: relative;
  z-index: 2; }
  @media (max-width: 768px) {
    ._12GwoKnCilwjYnMfILS73C {
      margin-bottom: 32px;
      padding-bottom: 0; } }

._15thd8A9OWq2mhEbBjVo9L {
  margin-top: 40px; }
  ._15thd8A9OWq2mhEbBjVo9L .button:first-of-type {
    margin-right: 16px; }
    @media (max-width: 900px) {
      ._15thd8A9OWq2mhEbBjVo9L .button:first-of-type {
        margin-bottom: 16px; } }

._1ZCm4v1vEsYyWw-OjSVd6u {
  position: absolute;
  top: -90px;
  z-index: 2; }
  @media (max-width: 900px) {
    ._1ZCm4v1vEsYyWw-OjSVd6u {
      position: static;
      position: initial;
      top: auto;
      top: initial;
      margin-bottom: 25px; } }

._3Jqfjjqr_tbv_7dqFH6W0Z {
  width: 344px;
  min-width: 344px;
  margin-right: 48px;
  z-index: 2;
  position: relative; }
  @media (max-width: 1180px) {
    ._3Jqfjjqr_tbv_7dqFH6W0Z {
      width: 244px;
      min-width: 244px;
      margin-right: 32px; } }
  @media (max-width: 900px) {
    ._3Jqfjjqr_tbv_7dqFH6W0Z {
      width: 100%;
      margin-right: 0;
      order: 2;
      margin-top: 24px; } }

._3Jqfjjqr_tbv_7dqFH6W0Z .button {
  width: 100%;
  height: 63px;
  margin-top: 30px;
  max-width: 344px; }
  @media (max-width: 486px) {
    ._3Jqfjjqr_tbv_7dqFH6W0Z .button {
      max-width: 100%; } }

._22LqhOLfAOyPvKGZwmd9YV {
  width: 186px;
  height: 186px;
  margin: 0; }

._1-sUKiZWK7LjiA92AAQvOU {
  width: 197px;
  height: 17px;
  font-size: 1.5em;
  font-weight: 900;
  line-height: 0.71;
  letter-spacing: normal;
  color: #143264;
  margin-bottom: 8px;
  text-transform: uppercase; }

._3H3cF0ljb-qa-vJrK-i5VU {
  font-size: 18px;
  font-weight: 800;
  line-height: 0.94;
  letter-spacing: normal;
  color: #0078e6;
  margin-top: 24px; }

._2j56K-3KfdEVadOK9tReov {
  width: 226px;
  line-height: 1;
  letter-spacing: normal;
  color: #000000; }

._2t3PIrRGdd3GKPE4C-aSZ1 {
  display: flex;
  margin-bottom: 101px; }
  @media (max-width: 900px) {
    ._2t3PIrRGdd3GKPE4C-aSZ1 {
      flex-flow: column;
      margin-bottom: 32px; } }

._1X0bq-CXkcin-s2sBRhBml {
  margin-top: -10px;
  position: relative; }
  @media (max-width: 900px) {
    ._1X0bq-CXkcin-s2sBRhBml {
      margin-top: 0;
      width: 100%;
      order: 1; } }

@media (max-width: 900px) {
  ._3JchSTzec78sMqVpb5OTcj {
    order: 3; } }

._2kKST_riFtada9flRXR_i9 {
  display: flex; }
  @media (max-width: 1024px) {
    ._2kKST_riFtada9flRXR_i9 {
      flex-flow: column; } }

.ubk2lGxzITuT9eGXDSJoJ {
  background-color: #143264;
  width: 380px;
  padding: 42px;
  margin-right: 16px;
  z-index: 2; }
  @media (max-width: 1280px) {
    .ubk2lGxzITuT9eGXDSJoJ {
      padding: 32px;
      width: calc(50% - 8px); } }
  @media (max-width: 1024px) {
    .ubk2lGxzITuT9eGXDSJoJ {
      width: 100%;
      margin-bottom: 16px;
      padding: 48px; } }
  @media (max-width: 768px) {
    .ubk2lGxzITuT9eGXDSJoJ {
      padding: 24px; } }
  .ubk2lGxzITuT9eGXDSJoJ:last-of-type {
    margin-right: 0; }
    @media (max-width: 1024px) {
      .ubk2lGxzITuT9eGXDSJoJ:last-of-type {
        margin-bottom: 0; } }

._Daod57UMg8v3ZYEuIR5P {
  font-size: 2.375em;
  font-weight: 900;
  line-height: 0.84;
  color: #ffffff;
  margin: 0;
  margin-bottom: 57px;
  text-transform: uppercase; }
  @media (max-width: 768px) {
    ._Daod57UMg8v3ZYEuIR5P {
      font-size: 1.75em;
      margin-bottom: 16px; } }

._3fv00FRiTU7XrkcFoS401F {
  width: 297px;
  font-family: Mark;
  font-size: 1.125em;
  color: #ffffff;
  text-transform: uppercase; }

._3SQrPsDKIOmXOHuUjvTku2 {
  margin-bottom: 25px; }
  @media (max-width: 768px) {
    ._3SQrPsDKIOmXOHuUjvTku2 {
      margin-bottom: 20px; } }

._38wiBBhn4fwKH_9GPDhgu8 {
  margin-bottom: 9px; }

._29ohzAk9UW7zGn0zdB-mZP {
  padding: 10px;
  text-transform: uppercase; }

._35TqijjTokR8hjbHq3TyeC {
  width: 500px;
  height: 43px;
  font-family: Mark;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000; }

._1zgML1qNTkUt7CUMpQhZLh {
  background-color: #143264;
  width: 380px;
  margin-right: 16px;
  z-index: 2; }
  @media (max-width: 1280px) {
    ._1zgML1qNTkUt7CUMpQhZLh {
      width: calc(50% - 8px); } }
  @media (max-width: 1024px) {
    ._1zgML1qNTkUt7CUMpQhZLh {
      width: 100%;
      margin-bottom: 16px;
      padding: 48px; } }
  @media (max-width: 768px) {
    ._1zgML1qNTkUt7CUMpQhZLh {
      padding: 24px; } }
  ._1zgML1qNTkUt7CUMpQhZLh:last-of-type {
    margin-right: 0; }
    @media (max-width: 1024px) {
      ._1zgML1qNTkUt7CUMpQhZLh:last-of-type {
        margin-bottom: 0; } }

.w6Lyi6-IHtxwo5N2cPuVG {
  padding: 32px; }

._2U7UvSEjC-2E9MJdQMc1Bs {
  color: white;
  font-weight: 900;
  line-height: 0.84;
  text-transform: uppercase;
  font-size: 2em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 16px;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto; }
  @media (max-width: 1024px) {
    ._2U7UvSEjC-2E9MJdQMc1Bs {
      font-size: 1.75em; } }

._4IHdD11S9z_SCirHXp20W {
  text-transform: uppercase;
  font-size: 1.125em;
  margin-bottom: 12px;
  display: block; }

.NB2ccqxRdbt5tCOX-vzYp {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 224px;
  object-fit: cover; }

._2cndtDdqyG3eZ9eCzhsPJx {
  color: white;
  font-weight: 300;
  margin: 0;
  font-size: 1.125em; }

._2cndtDdqyG3eZ9eCzhsPJx p {
  margin: 0;
  font-size: 1em;
  line-height: 1.78; }

._2cndtDdqyG3eZ9eCzhsPJx span {
  font-size: 1em; }

._2hkwSBsd0NoIJ8GU-P3QX8 {
  color: white;
  font-weight: bold;
  display: block;
  margin-top: 8px; }
  ._2hkwSBsd0NoIJ8GU-P3QX8 svg {
    margin-left: 5px;
    position: relative;
    top: -1px;
    transition: -webkit-transform 0.2s defaultTransition;
    transition: transform 0.2s defaultTransition;
    transition: transform 0.2s defaultTransition, -webkit-transform 0.2s defaultTransition; }

._1ORcbRjcMoP-V2a6hEBCtL {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1; }

@media (min-width: 769px) {
  ._1zgML1qNTkUt7CUMpQhZLh:hover {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px); } }

@media (min-width: 769px) {
  ._1zgML1qNTkUt7CUMpQhZLh:hover ._2hkwSBsd0NoIJ8GU-P3QX8 svg {
    -webkit-transform: translateX(4px);
            transform: translateX(4px); } }

.Kt5XONPQF9SXEkiUSuw3W {
  margin-bottom: 25px; }
  @media (max-width: 768px) {
    .Kt5XONPQF9SXEkiUSuw3W {
      margin-bottom: 20px; } }

.Kt5XONPQF9SXEkiUSuw3W,
.Kt5XONPQF9SXEkiUSuw3W path,
.Kt5XONPQF9SXEkiUSuw3W g {
  fill: white; }

._2VTnIq8R8ZKH_cLZ6V0tjx {
  margin-top: 74px; }
  @media (max-width: 900px) {
    ._2VTnIq8R8ZKH_cLZ6V0tjx {
      margin-top: 32px;
      order: 3; } }

._17Lbl-VOZq9zU3lfCOZZV2 {
  display: flex; }
  @media (max-width: 1024px) {
    ._17Lbl-VOZq9zU3lfCOZZV2 {
      flex-flow: column; } }
  @media (max-width: 768px) {
    ._17Lbl-VOZq9zU3lfCOZZV2 {
      margin-top: 0; } }

.ssbP_StEQk647qjDRm99y {
  font-size: 2.375em;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  font-weight: 900;
  line-height: 0.84;
  letter-spacing: normal;
  color: #143264;
  margin-top: 0;
  text-transform: uppercase; }
  @media (max-width: 768px) {
    .ssbP_StEQk647qjDRm99y {
      display: none; } }

.ODWklXljZfnGtHHHR5yGf {
  position: relative;
  z-index: 2;
  font-size: 1.5em;
  left: -1px;
  font-weight: 300;
  line-height: 1.67;
  color: #000000;
  margin: 0;
  margin-bottom: 48px; }
  @media (max-width: 900px) {
    .ODWklXljZfnGtHHHR5yGf {
      font-size: 1.375em;
      line-height: 1.56; } }
  @media (max-width: 486px) {
    .ODWklXljZfnGtHHHR5yGf {
      font-size: 1.25em; } }

._54yVXYqjRqwnuOKi7zUAJ {
  position: relative;
  z-index: 2;
  left: -1px;
  display: flex;
  flex-direction: column; }
  ._54yVXYqjRqwnuOKi7zUAJ h2 {
    font-family: Mark;
    font-size: 2.375em;
    font-weight: 900;
    line-height: 0.84;
    color: #143264;
    margin-top: 0;
    text-transform: uppercase;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto; }
    ._54yVXYqjRqwnuOKi7zUAJ h2:not(:first-of-type) {
      margin-top: 44px; }
      @media (max-width: 486px) {
        ._54yVXYqjRqwnuOKi7zUAJ h2:not(:first-of-type) {
          margin-top: 24px; } }
  ._54yVXYqjRqwnuOKi7zUAJ p {
    font-family: Mark;
    font-size: 1.125em;
    line-height: 1.56;
    color: #000000;
    margin: 0; }
    @media (max-width: 900px) {
      ._54yVXYqjRqwnuOKi7zUAJ p {
        line-height: 1.56; } }
  ._54yVXYqjRqwnuOKi7zUAJ a {
    text-decoration: underline; }
    ._54yVXYqjRqwnuOKi7zUAJ a:hover {
      text-decoration: none; }
  ._54yVXYqjRqwnuOKi7zUAJ ul {
    margin-bottom: 24px;
    margin-left: 24px; }
    ._54yVXYqjRqwnuOKi7zUAJ ul li {
      min-height: 28px;
      margin-bottom: 5px;
      font-size: 1.125em;
      color: #000000;
      position: relative;
      padding-left: 44px; }
      ._54yVXYqjRqwnuOKi7zUAJ ul li:last-of-type {
        margin-bottom: 0px; }
      ._54yVXYqjRqwnuOKi7zUAJ ul li span {
        position: absolute;
        left: 0;
        top: 0; }
      ._54yVXYqjRqwnuOKi7zUAJ ul li svg {
        margin-right: 16px; }

._2GlN_DgolhMsawG736qCjE {
  margin-right: 16px; }

.ZIW6ZTxgjia6vcm4jYlMw {
  max-width: 525px;
  height: 322px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 24px; }
  @media (max-width: 520px) {
    .ZIW6ZTxgjia6vcm4jYlMw {
      height: 250px; } }
  .ZIW6ZTxgjia6vcm4jYlMw::after {
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    transition: background 0.2s cubic-bezier(0.35, 0, 0.65, 1);
    mix-blend-mode: overlay;
    background-blend-mode: overlay;
    background-image: linear-gradient(to bottom, #0078e6, #0078e6); }

._25j4kJS6JGoXiRHFJkDWjp {
  transition: -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column; }

._2f-APrBMhn6Bz-7tlChP5c {
  width: 52px;
  height: 52px;
  background: white;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center; }

.mxQ38zGzlMrapi-DDN-q- {
  font-weight: bold;
  font-size: 1em;
  color: white; }

._1wMDURuTdg2mhFs8nTP0ZE {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1); }

.ZIW6ZTxgjia6vcm4jYlMw:hover ._1wMDURuTdg2mhFs8nTP0ZE {
  -webkit-transform: scale(1.05);
          transform: scale(1.05); }

._1cDwESmTYVVM9IEF4uKMXc {
  width: 100%;
  height: 768px;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  display: block;
  background: black; }

._1cDwESmTYVVM9IEF4uKMXc iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

._1O-rQSu6t3mdaBxenQKK7m {
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }
  @media (max-width: 600px) {
    ._1O-rQSu6t3mdaBxenQKK7m {
      padding-top: 0; } }

._1tt4b_Ne99egp5wPqhBBq2 {
  width: 100%;
  position: relative;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1); }

.cDnImJx006kN4LshbyfIc {
  width: 1160px;
  z-index: 3;
  overflow: hidden;
  display: flex;
  background: white;
  opacity: 0;
  margin-top: 0px;
  transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.53);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100% - 54px); }
  @media (max-width: 1192px) {
    .cDnImJx006kN4LshbyfIc {
      width: 1000px; } }
  @media (max-width: 1024px) {
    .cDnImJx006kN4LshbyfIc {
      width: 700px;
      max-height: calc(100% - 24px); } }
  @media (max-width: 732px) {
    .cDnImJx006kN4LshbyfIc {
      width: 568px; } }
  @media (max-width: 600px) {
    .cDnImJx006kN4LshbyfIc {
      width: 100%;
      left: 0;
      top: 0;
      overflow: auto;
      -webkit-overflow-scrolling: touch; } }
  .cDnImJx006kN4LshbyfIc .tCllV04b6Lkcm1M6uuOUj {
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
    transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
    transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    cursor: pointer;
    z-index: 3;
    background: #143264; }
    .cDnImJx006kN4LshbyfIc .tCllV04b6Lkcm1M6uuOUj svg {
      width: 25px;
      height: 25px; }
      @media (max-width: 768px) {
        .cDnImJx006kN4LshbyfIc .tCllV04b6Lkcm1M6uuOUj svg {
          width: 19px;
          height: 19px; } }
      .cDnImJx006kN4LshbyfIc .tCllV04b6Lkcm1M6uuOUj svg g {
        fill: white; }
    @media (max-width: 600px) {
      .cDnImJx006kN4LshbyfIc .tCllV04b6Lkcm1M6uuOUj {
        width: 60px;
        height: 60px; } }
  .cDnImJx006kN4LshbyfIc ._3RROAAnAb-375EQzQOJKXg {
    background: #143264; }
  .cDnImJx006kN4LshbyfIc ._135lRHsF4R_TGNi_L6kDNB {
    background: #0078e6; }

._2GaoOWcm44YoYWXvZsWmXT {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: background-color 0.2s cubic-bezier(0.35, 0, 0.65, 1); }

._2G-lYa5Vm9W_5-Nq_1Iebc .cDnImJx006kN4LshbyfIc {
  opacity: 1;
  transition: all 250ms ease-out; }
  @media (max-width: 600px) {
    ._2G-lYa5Vm9W_5-Nq_1Iebc .cDnImJx006kN4LshbyfIc {
      margin-top: 0;
      margin-top: initial; } }

._2G-lYa5Vm9W_5-Nq_1Iebc ._2GaoOWcm44YoYWXvZsWmXT {
  background-color: rgba(0, 0, 0, 0.34); }

._1tt4b_Ne99egp5wPqhBBq2._2jooqi3pIv-ExGIFKfXVCb {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1; }

.tCllV04b6Lkcm1M6uuOUj._16PE-psUGt8F4qrKbcvHbu {
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.G0ioxtNQeKv4szK7g6dr6 {
  width: 186px;
  height: 186px;
  margin: 0;
  background: #eee; }

._1LO5sXFELAOa5y_B_NgNwC {
  font-size: 1.5em;
  font-weight: 900;
  line-height: 0.71;
  letter-spacing: normal;
  color: #143264;
  margin-bottom: 8px;
  text-transform: uppercase; }

.UtraKbE_GnC2ZiX_B0_uT {
  font-size: 18px;
  font-weight: 800;
  line-height: 0.94;
  letter-spacing: normal;
  color: #0078e6;
  margin-top: 24px; }

.UtraKbE_GnC2ZiX_B0_uT li {
  margin-bottom: 8px; }
  .UtraKbE_GnC2ZiX_B0_uT li:last-of-type {
    margin-bottom: 0; }

.UtraKbE_GnC2ZiX_B0_uT li a {
  display: flex;
  align-items: center; }

.UtraKbE_GnC2ZiX_B0_uT li svg {
  margin-right: 8px; }

.UtraKbE_GnC2ZiX_B0_uT li span {
  text-transform: uppercase; }

.UtraKbE_GnC2ZiX_B0_uT li .button span {
  text-transform: none; }

._1_6n8gGcNn_TqLBWUA5E95 {
  width: 226px;
  line-height: 1;
  letter-spacing: normal;
  color: #000000; }

.tWKHOhpV_c5brIw3eGAFD {
  width: 344px;
  min-width: 344px;
  margin-right: 48px;
  z-index: 2;
  position: relative; }
  @media (max-width: 1180px) {
    .tWKHOhpV_c5brIw3eGAFD {
      width: 244px;
      min-width: 244px;
      margin-right: 32px; } }
  @media (max-width: 900px) {
    .tWKHOhpV_c5brIw3eGAFD {
      width: 100%;
      margin-right: 0;
      order: 2;
      margin-top: 24px; } }

.tWKHOhpV_c5brIw3eGAFD .button {
  width: 100%;
  height: 63px;
  margin-top: 30px;
  max-width: 344px; }
  @media (max-width: 486px) {
    .tWKHOhpV_c5brIw3eGAFD .button {
      max-width: 100%; } }

._1GarQGKf8wOywyA53HcEnA {
  width: 186px;
  height: 186px;
  margin: 0; }

._36hNhqysjSrD0RGXbwt4D7 {
  width: 197px;
  height: 17px;
  font-size: 1.5em;
  font-weight: 900;
  line-height: 0.71;
  letter-spacing: normal;
  color: #143264;
  margin-bottom: 8px;
  text-transform: uppercase; }

._3zbtBscj5E2stWHP-U5kYX {
  font-size: 18px;
  font-weight: 800;
  line-height: 0.94;
  letter-spacing: normal;
  color: #0078e6;
  margin-top: 24px; }

._3559TglwthMVig0ervZ-wR {
  width: 226px;
  line-height: 1;
  letter-spacing: normal;
  color: #000000; }

._2MrrkknLQQjtck2owpXTDa {
  display: flex;
  margin-bottom: 101px; }
  @media (max-width: 900px) {
    ._2MrrkknLQQjtck2owpXTDa {
      flex-flow: column;
      margin-bottom: 32px; } }

._3lXAAKc6v1tqNlaz8Z6b_a {
  margin-top: -10px; }
  @media (max-width: 900px) {
    ._3lXAAKc6v1tqNlaz8Z6b_a {
      margin-top: 0;
      width: 100%;
      order: 1; } }

@media (max-width: 900px) {
  ._3oguKE6xdmNNCSjCGts4_w {
    order: 3; } }

._30GI8VIOtlaez3EdTb5pmU {
  display: flex; }
  @media (max-width: 1024px) {
    ._30GI8VIOtlaez3EdTb5pmU {
      flex-flow: column; } }

._2I2yygIfAUKAYbUrQGWzOV {
  background-color: #143264;
  width: 380px;
  padding: 42px;
  margin-right: 16px;
  z-index: 2; }
  @media (max-width: 1280px) {
    ._2I2yygIfAUKAYbUrQGWzOV {
      padding: 32px;
      width: calc(50% - 8px); } }
  @media (max-width: 1024px) {
    ._2I2yygIfAUKAYbUrQGWzOV {
      width: 100%;
      margin-bottom: 16px;
      padding: 48px; } }
  @media (max-width: 768px) {
    ._2I2yygIfAUKAYbUrQGWzOV {
      padding: 24px; } }
  ._2I2yygIfAUKAYbUrQGWzOV:last-of-type {
    margin-right: 0; }
    @media (max-width: 1024px) {
      ._2I2yygIfAUKAYbUrQGWzOV:last-of-type {
        margin-bottom: 0; } }

._1aBF2UkWmsIsleeK0AZJ-_ {
  font-size: 2.375em;
  font-weight: 900;
  line-height: 0.84;
  color: #ffffff;
  margin: 0;
  margin-bottom: 57px;
  text-transform: uppercase; }
  @media (max-width: 768px) {
    ._1aBF2UkWmsIsleeK0AZJ-_ {
      font-size: 1.75em;
      margin-bottom: 16px; } }

._3j5B1wt12RtMKv4zBuRieu {
  width: 297px;
  font-family: Mark;
  font-size: 1.125em;
  color: #ffffff;
  text-transform: uppercase; }

._2GX8dKW2D8ALrwgAhTP7ft {
  margin-bottom: 25px; }
  @media (max-width: 768px) {
    ._2GX8dKW2D8ALrwgAhTP7ft {
      margin-bottom: 20px; } }

._3CGrfd6WmctTJbHkgv8_nd {
  margin-bottom: 9px; }

._1fuo6nT0exKtKjZ87qc5km {
  padding: 10px;
  text-transform: uppercase; }

._3pWdgVGBzFxr6OgkE9-yEF {
  width: 500px;
  height: 43px;
  font-family: Mark;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000; }

._2HA-2IeGg5N0GK5Oqzu8V7 {
  pointer-events: none;
  position: relative;
  z-index: 2; }
  @media (max-width: 768px) {
    ._2HA-2IeGg5N0GK5Oqzu8V7 {
      pointer-events: initial; } }

@media (max-width: 486px) {
  ._2HA-2IeGg5N0GK5Oqzu8V7 .button {
    width: 100%; } }

@media (max-width: 1280px) {
  ._2HA-2IeGg5N0GK5Oqzu8V7 .notch {
    display: none; } }

._1CJp11bxz6X8DboHQeh6w3 { }
  @media (max-width: 1280px) {
    ._1CJp11bxz6X8DboHQeh6w3 {
      display: none; } }

._3l2W18vMaB0Kb1bBBPme5d {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 100px;
  padding-top: 58px; }
  @media (max-width: 1024px) {
    ._3l2W18vMaB0Kb1bBBPme5d {
      margin-bottom: 56px; } }
  @media (max-width: 768px) {
    ._3l2W18vMaB0Kb1bBBPme5d {
      flex-direction: column;
      align-items: initial;
      margin-bottom: 24px;
      padding-top: 34px; } }
  @media (max-width: 486px) {
    ._3l2W18vMaB0Kb1bBBPme5d {
      width: 100%; } }

._3Be0YeO9_FrbbtT6dJbvLc {
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 196px; }
  @media (max-width: 768px) {
    ._3Be0YeO9_FrbbtT6dJbvLc {
      margin-bottom: 18px; } }
  ._3Be0YeO9_FrbbtT6dJbvLc:first-of-type {
    margin-left: 0; }
  ._3Be0YeO9_FrbbtT6dJbvLc:last-of-type {
    margin-right: 48px; }
    @media (max-width: 768px) {
      ._3Be0YeO9_FrbbtT6dJbvLc:last-of-type {
        margin-bottom: 34px; } }
  ._3Be0YeO9_FrbbtT6dJbvLc span {
    font-size: 4.125em;
    line-height: 0.8;
    font-weight: 900;
    margin-right: 6px; }
  ._3Be0YeO9_FrbbtT6dJbvLc b {
    font-size: 1.125em;
    font-weight: 800;
    line-height: 0.83; }

.Ru723zI56woDCNRfA3Dk6 {
  display: flex;
  position: relative;
  z-index: 2;
  margin-top: -220px; }
  @media (max-width: 1280px) {
    .Ru723zI56woDCNRfA3Dk6 {
      margin-top: 0; } }

._1VGQVaxe49NX11ODxrewSx {
  display: flex;
  flex-flow: wrap;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: -16px; }

._2Eu4aXJK56dbE8syO5780H {
  background-color: white;
  padding: 34px 40px;
  width: calc(50% - 16px);
  margin: 16px 8px;
  margin-top: 0;
  display: flex;
  transition: background 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: background 0.2s cubic-bezier(0.35, 0, 0.65, 1), transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: background 0.2s cubic-bezier(0.35, 0, 0.65, 1), transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1); }
  ._2Eu4aXJK56dbE8syO5780H:hover {
    background-color: #eaeaea;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px); }

.vZKSI7YCXd7KNAHaCiqtq {
  margin-left: 65px; }

._1CaYZrQNT2VqBXC-tSzW4D {
  text-transform: uppercase;
  color: #143264;
  font-weight: 800;
  line-height: 0.84;
  margin: 0;
  font-size: 2.375em; }

._39k7MVu_Krk50Sfm1vAsLH {
  margin: 0;
  margin-top: 6px;
  line-height: 1.17;
  font-size: 1.5em;
  color: #143264; }

._3Yoyeg6LGoCXccZuL9wlqP {
  width: -webkit-fit-content;
  width: fit-content;
  font-size: 1.125em;
  font-weight: 800;
  line-height: 0.94;
  color: #143264;
  padding: 11px; }

._2IvdO5nweDLb8qY8QK63iM {
  background: white;
  margin-bottom: 196px;
  position: relative;
  z-index: 2; }
  @media (max-width: 1024px) {
    ._2IvdO5nweDLb8qY8QK63iM {
      margin-bottom: 114px; } }
  @media (max-width: 768px) {
    ._2IvdO5nweDLb8qY8QK63iM {
      margin-bottom: 80px;
      margin-top: 24px; } }

@media (max-width: 768px) {
  ._2IvdO5nweDLb8qY8QK63iM .notch {
    display: none; } }

._2wDNSLKKMRBFhw7KJC12E_ {
  z-index: 2;
  position: relative;
  display: flex; }
  @media (max-width: 1024px) {
    ._2wDNSLKKMRBFhw7KJC12E_ {
      flex-direction: column-reverse; } }

._1IXvC3uYM-Ub9U12nHZiBx h3 {
  text-transform: uppercase;
  color: #0078e6;
  font-weight: 500;
  margin: 4px; }
  @media (max-width: 768px) {
    ._1IXvC3uYM-Ub9U12nHZiBx h3 {
      font-size: 1.125em; } }

._1IXvC3uYM-Ub9U12nHZiBx h1 {
  max-width: 554px;
  text-transform: uppercase;
  color: #143264;
  font-size: 4.125em;
  font-weight: 900;
  margin: 0 0 24px 0;
  line-height: 53px; }
  @media (max-width: 768px) {
    ._1IXvC3uYM-Ub9U12nHZiBx h1 {
      font-size: 3em;
      line-height: 0.79;
      width: 100%; } }
  @media (max-width: 486px) {
    ._1IXvC3uYM-Ub9U12nHZiBx h1 {
      font-size: 2em; } }

._1IXvC3uYM-Ub9U12nHZiBx p {
  margin: 32px 0;
  font-size: 1.5em;
  font-weight: normal; }
  @media (max-width: 768px) {
    ._1IXvC3uYM-Ub9U12nHZiBx p {
      font-size: 1.125em;
      line-height: 1.56; } }

@media (max-width: 486px) {
  ._1IXvC3uYM-Ub9U12nHZiBx .button {
    width: 100%; } }

._3EJRCxMdg6UrxfgVN_vHpX {
  margin-left: 52px;
  position: relative; }
  @media (max-width: 1280px) {
    ._3EJRCxMdg6UrxfgVN_vHpX {
      margin-left: 24px; } }
  @media (max-width: 1024px) {
    ._3EJRCxMdg6UrxfgVN_vHpX {
      margin-left: 0;
      margin-bottom: 16px; } }
  ._3EJRCxMdg6UrxfgVN_vHpX img {
    max-width: 397px;
    width: 397px; }
    @media (max-width: 1024px) {
      ._3EJRCxMdg6UrxfgVN_vHpX img {
        width: 596px; } }
    @media (max-width: 768px) {
      ._3EJRCxMdg6UrxfgVN_vHpX img {
        width: 100%;
        height: auto; } }

._3seIVFEV18YqUwbNNwWhA5 {
  background: #143264; }

@media (max-width: 768px) {
  ._3seIVFEV18YqUwbNNwWhA5 .notch {
    display: none; } }

._2fLjCZyBlTU_yPAJ6SzYDT {
  z-index: 2;
  position: relative;
  padding-bottom: 114px; }
  @media (max-width: 768px) {
    ._2fLjCZyBlTU_yPAJ6SzYDT {
      padding-top: 56px;
      padding-bottom: 44px; } }

._2uuXrCz06lTjasSZGX3g8k {
  color: white;
  max-width: 760px; }
  @media (max-width: 768px) {
    ._2uuXrCz06lTjasSZGX3g8k {
      width: 100%; } }
  ._2uuXrCz06lTjasSZGX3g8k h3 {
    font-weight: 500;
    margin: 4px;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      ._2uuXrCz06lTjasSZGX3g8k h3 {
        font-size: 1.125em; } }
  ._2uuXrCz06lTjasSZGX3g8k h1 {
    font-size: 4.125em;
    font-weight: 900;
    margin: 0 0 30px 0;
    line-height: 53px;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      ._2uuXrCz06lTjasSZGX3g8k h1 {
        font-size: 3em;
        line-height: 0.79; } }
    @media (max-width: 486px) {
      ._2uuXrCz06lTjasSZGX3g8k h1 {
        font-size: 2em; } }
  ._2uuXrCz06lTjasSZGX3g8k p {
    margin: 32px 0;
    font-size: 1.5em;
    font-weight: normal; }
    @media (max-width: 768px) {
      ._2uuXrCz06lTjasSZGX3g8k p {
        font-size: 1.125em;
        line-height: 1.56; } }

._3SIw3Xjn9rVLoAV7BwQEXV {
  margin-top: 75px;
  margin-bottom: -72px; }

._2xEDGYYE8RarQfPtqVRwLH {
  background: white;
  margin-bottom: 282px; }
  @media (max-width: 1024px) {
    ._2xEDGYYE8RarQfPtqVRwLH {
      margin-bottom: 182px; } }
  @media (max-width: 768px) {
    ._2xEDGYYE8RarQfPtqVRwLH {
      margin-bottom: 52px; } }

._11l-NSOs6N1DwCIEjqA1Ey {
  z-index: 2;
  display: flex;
  position: relative; }
  @media (max-width: 1024px) {
    ._11l-NSOs6N1DwCIEjqA1Ey {
      flex-wrap: wrap;
      justify-content: space-between; } }
  @media (max-width: 600px) {
    ._11l-NSOs6N1DwCIEjqA1Ey {
      flex-direction: column;
      justify-content: flex-start; } }

._1ypGruOyVKj6hbKKigClat {
  min-width: 392px; }
  @media (max-width: 1024px) {
    ._1ypGruOyVKj6hbKKigClat {
      min-width: 1px;
      margin-top: 69px;
      margin-bottom: 80px; } }
  @media (max-width: 768px) {
    ._1ypGruOyVKj6hbKKigClat {
      margin-top: 32px;
      margin-bottom: 32px; } }
  @media (max-width: 768px) {
    ._1ypGruOyVKj6hbKKigClat:last-of-type {
      margin-bottom: 0; } }
  ._1ypGruOyVKj6hbKKigClat span {
    font-size: 1.9375em;
    font-weight: 900;
    line-height: 0.45;
    text-transform: uppercase;
    position: relative; }
    @media (max-width: 768px) {
      ._1ypGruOyVKj6hbKKigClat span {
        font-size: 1.5em;
        line-height: 0.58; } }
    ._1ypGruOyVKj6hbKKigClat span ._1xWdDghh5ZOhQSuRCTJkrU {
      -webkit-transform-origin: left;
              transform-origin: left;
      -webkit-transform: rotate(270deg) translate(0, 0%);
              transform: rotate(270deg) translate(0, 0%);
      text-transform: uppercase;
      font-weight: 900;
      white-space: nowrap;
      margin-right: 100%;
      display: flex;
      align-items: center;
      align-self: flex-start;
      position: absolute;
      left: calc(100% + 18px);
      bottom: 0; }
    ._1ypGruOyVKj6hbKKigClat span ._9CzRYbFYJJtuvEBgKNY9- {
      margin: 0 0 79px 0;
      color: black;
      font-size: 200px;
      font-weight: 800;
      display: inline-block;
      left: -5px; }
      @media (max-width: 768px) {
        ._1ypGruOyVKj6hbKKigClat span ._9CzRYbFYJJtuvEBgKNY9- {
          font-size: 150px;
          margin: 0 0 60px 0; } }
  ._1ypGruOyVKj6hbKKigClat h2 {
    width: 276px;
    margin: 0;
    margin-top: -10px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 38px;
    color: #0078e6;
    line-height: 0.84; }
    @media (max-width: 768px) {
      ._1ypGruOyVKj6hbKKigClat h2 {
        font-size: 1.75em; } }
    @media (max-width: 486px) {
      ._1ypGruOyVKj6hbKKigClat h2 {
        width: 100%; } }
    @media (max-width: 1024px) {
      ._1ypGruOyVKj6hbKKigClat h2 br {
        display: none; } }

._1vnCGNuUr3l3EFZQARvtlB {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  height: calc(100%);
  z-index: 1;
  background-repeat: repeat;
  background-repeat: initial;
  background-size: 196px;
  background-position: left;
  background-image: linear-gradient(to right, rgba(151, 151, 151, 0.4) 1px, transparent 1px); }
  @media (max-width: 768px) {
    ._1vnCGNuUr3l3EFZQARvtlB {
      display: none; } }
  ._1vnCGNuUr3l3EFZQARvtlB::before {
    content: '';
    width: 100vw;
    height: calc(100%);
    position: absolute;
    left: calc(-100vw - 196px);
    top: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    background-repeat: repeat;
    background-repeat: initial;
    background-size: 196px;
    background-position: left;
    background-image: linear-gradient(to right, rgba(151, 151, 151, 0.4) 1px, transparent 1px); }
  ._1vnCGNuUr3l3EFZQARvtlB::after {
    content: '';
    width: 100vw;
    height: calc(100%);
    right: -100vw;
    position: absolute;
    top: 0;
    background-repeat: repeat;
    background-repeat: initial;
    background-size: 196px;
    background-position: left;
    background-image: linear-gradient(to right, rgba(151, 151, 151, 0.4) 1px, transparent 1px); }

._3XW_MkD2hF37ywFb-tZp_8 {
  background: #0078e6;
  padding-top: 148px;
  padding-bottom: 80px; }
  @media (max-width: 1024px) {
    ._3XW_MkD2hF37ywFb-tZp_8 {
      padding-top: 48px; } }
  @media (max-width: 768px) {
    ._3XW_MkD2hF37ywFb-tZp_8 {
      padding-top: 0;
      padding-bottom: 40px; } }

.dtdCylbO8n2zZvh26-aSt {
  position: relative;
  z-index: 3; }

._1tPX4PW_qXGmX0luU9KPQq {
  font-weight: 900;
  line-height: 0.8;
  text-transform: uppercase;
  font-size: 4.125em;
  margin-top: 64px;
  margin-bottom: 42px; }
  @media (max-width: 1024px) {
    ._1tPX4PW_qXGmX0luU9KPQq {
      font-size: 3em;
      line-height: 1.1; } }
  @media (max-width: 768px) {
    ._1tPX4PW_qXGmX0luU9KPQq {
      margin-top: 16px;
      margin-bottom: 32px; } }
  ._1tPX4PW_qXGmX0luU9KPQq em {
    font-weight: 300;
    font-style: normal; }

.qw7nRHIVmQO1Q-YP9SLI3 {
  margin-bottom: 0; }

._1vSYqxMRkbY0MCaFUvjLj1 {
  height: 100%;
  position: absolute; }

._1kxu1tIdUTfqfsTsMjJaBC {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex: 1 1;
  flex-wrap: wrap;
  margin-bottom: -20px; }
  @media (max-width: 768px) {
    ._1kxu1tIdUTfqfsTsMjJaBC {
      margin-left: -16px;
      margin-right: -16px;
      margin-bottom: -8px; } }

._1y-Nx3XlaOScqvHk7qpnmu {
  background: white;
  display: block;
  width: calc(33.333333% - 20px);
  margin: 0 10px;
  margin-bottom: 20px;
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  max-width: 372px;
  min-height: 400px; }
  @media (max-width: 1180px) {
    ._1y-Nx3XlaOScqvHk7qpnmu {
      width: calc(50% - 20px);
      max-width: calc(50% - 20px); } }
  @media (max-width: 768px) {
    ._1y-Nx3XlaOScqvHk7qpnmu {
      width: calc(100%);
      max-width: calc(100%);
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 8px;
      min-height: 100px; } }

._2Phug6ALLOIqrdjOAs1cMz {
  padding: 32px; }

._1n0OxrWKpmmh4sS1cNfZ0C {
  width: 100%;
  height: 100%;
  max-height: 224px;
  object-fit: cover; }

._3NPg77iXlqsntFmxcHyc4M {
  color: #143264;
  font-weight: 900;
  line-height: 0.84;
  text-transform: uppercase;
  font-size: 2em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 16px;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto; }
  @media (max-width: 1024px) {
    ._3NPg77iXlqsntFmxcHyc4M {
      font-size: 1.75em; } }

.MbqoFI_qFUL7wRdujkaxd {
  text-transform: uppercase;
  font-size: 1.125em;
  margin-bottom: 12px;
  display: block; }

.aDrwvcXdo2O40ADwq20sm {
  color: #565656;
  font-weight: 300;
  margin: 0;
  font-size: 1.125em; }

.aDrwvcXdo2O40ADwq20sm p {
  margin: 0;
  font-size: 1em;
  line-height: 1.78; }

.aDrwvcXdo2O40ADwq20sm span {
  font-size: 1em; }

._3tvCmBjmyao_GKdHm1uFxW {
  color: #0078e6;
  font-weight: bold;
  display: block;
  margin-top: 8px; }
  ._3tvCmBjmyao_GKdHm1uFxW svg {
    margin-left: 5px;
    position: relative;
    top: -1px;
    transition: -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
    transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
    transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1); }

._1W1by-2Efy_fA0O9IgUbBC {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1; }

@media (min-width: 769px) {
  ._1y-Nx3XlaOScqvHk7qpnmu:hover {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px); } }

@media (min-width: 769px) {
  ._1y-Nx3XlaOScqvHk7qpnmu:hover ._3tvCmBjmyao_GKdHm1uFxW svg {
    -webkit-transform: translateX(4px);
            transform: translateX(4px); } }

._9XNiyi4HqHDvXYfWPWeWO {
  background: #0078e6;
  padding-top: 148px;
  padding-bottom: 80px; }
  @media (max-width: 1024px) {
    ._9XNiyi4HqHDvXYfWPWeWO {
      padding-top: 48px; } }
  @media (max-width: 768px) {
    ._9XNiyi4HqHDvXYfWPWeWO {
      padding-top: 0;
      padding-bottom: 40px; } }

.KNSrbPIdv3L9sbi646tNz {
  position: relative;
  z-index: 3; }

._2AnxEUyh5x_JzUY4lI1vwz {
  font-weight: 900;
  line-height: 0.8;
  text-transform: uppercase;
  font-size: 4.125em;
  margin-top: 64px;
  margin-bottom: 42px; }
  @media (max-width: 1024px) {
    ._2AnxEUyh5x_JzUY4lI1vwz {
      font-size: 3em;
      line-height: 1.1; } }
  @media (max-width: 768px) {
    ._2AnxEUyh5x_JzUY4lI1vwz {
      margin-top: 16px;
      margin-bottom: 32px; } }
  ._2AnxEUyh5x_JzUY4lI1vwz em {
    font-weight: 300;
    font-style: normal; }

._39mZ-SdpGBew06sqbTDPKJ {
  margin-bottom: 0; }

._2vMh-VjQejgKNsBNwoh6Wf {
  height: 100%;
  position: absolute; }

._2ke15-FAGK2b0sfITtawrX {
  background-color: #143264;
  width: 380px;
  padding: 42px;
  margin-right: 16px;
  z-index: 2; }
  @media (max-width: 1280px) {
    ._2ke15-FAGK2b0sfITtawrX {
      padding: 32px;
      width: calc(50% - 8px); } }
  @media (max-width: 1024px) {
    ._2ke15-FAGK2b0sfITtawrX {
      width: 100%;
      margin-bottom: 16px;
      padding: 48px; } }
  @media (max-width: 768px) {
    ._2ke15-FAGK2b0sfITtawrX {
      padding: 24px; } }
  ._2ke15-FAGK2b0sfITtawrX:last-of-type {
    margin-right: 0; }
    @media (max-width: 1024px) {
      ._2ke15-FAGK2b0sfITtawrX:last-of-type {
        margin-bottom: 0; } }

._13zXzd8eEkyBbB0BVAmVpv {
  font-size: 2.375em;
  font-weight: 900;
  line-height: 0.84;
  color: #ffffff;
  margin: 0;
  margin-bottom: 57px;
  text-transform: uppercase;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto; }
  @media (max-width: 768px) {
    ._13zXzd8eEkyBbB0BVAmVpv {
      font-size: 1.75em;
      margin-bottom: 16px; } }

._2szbthg40jZERMpLOqS-Kq {
  width: 297px;
  font-family: Mark;
  font-size: 1.125em;
  color: #ffffff;
  text-transform: uppercase; }

._17BPGxP4iQAy5YmoVzFuYT {
  margin-bottom: 25px; }
  @media (max-width: 768px) {
    ._17BPGxP4iQAy5YmoVzFuYT {
      margin-bottom: 20px; } }

._17BPGxP4iQAy5YmoVzFuYT,
._17BPGxP4iQAy5YmoVzFuYT path,
._17BPGxP4iQAy5YmoVzFuYT g {
  fill: white; }

._37Qzi7g0peU3-N2jCN8kD- {
  margin-top: 74px; }
  @media (max-width: 900px) {
    ._37Qzi7g0peU3-N2jCN8kD- {
      margin-top: 32px;
      order: 3; } }

._3UZMawMJ1tSjTu6OwXmT5n {
  display: flex; }
  @media (max-width: 1024px) {
    ._3UZMawMJ1tSjTu6OwXmT5n {
      flex-flow: column; } }
  @media (max-width: 768px) {
    ._3UZMawMJ1tSjTu6OwXmT5n {
      margin-top: 0; } }

._2hDpuHs3tfIRxYN_795pI7 {
  font-size: 2.375em;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  font-weight: 900;
  line-height: 0.84;
  letter-spacing: normal;
  color: #143264;
  margin-top: 0;
  text-transform: uppercase; }
  @media (max-width: 768px) {
    ._2hDpuHs3tfIRxYN_795pI7 {
      display: none; } }

._2v0kHVmG1Z0qbD06GN8mF6 {
  width: 186px;
  height: 186px;
  margin: 0;
  background: #eee; }

._2YEfsJcRDDJwsaZ2oiqzlF {
  font-size: 1.5em;
  font-weight: 900;
  line-height: 0.71;
  letter-spacing: normal;
  color: #143264;
  margin-bottom: 8px;
  text-transform: uppercase; }

.u7iFcTcX-pe35neqRTSTc {
  font-size: 18px;
  font-weight: 800;
  line-height: 0.94;
  letter-spacing: normal;
  color: #0078e6;
  margin-top: 24px; }

.u7iFcTcX-pe35neqRTSTc li {
  margin-bottom: 8px; }
  .u7iFcTcX-pe35neqRTSTc li:last-of-type {
    margin-bottom: 0; }

.u7iFcTcX-pe35neqRTSTc li a {
  display: flex;
  align-items: center; }

.u7iFcTcX-pe35neqRTSTc li svg {
  margin-right: 8px; }

.u7iFcTcX-pe35neqRTSTc li span {
  text-transform: uppercase; }

.u7iFcTcX-pe35neqRTSTc li .button span {
  text-transform: none; }

._3iOJ0i1lFnXz1JNOH9hlvu {
  width: 226px;
  line-height: 1;
  letter-spacing: normal;
  color: #000000; }

._3M1BCqwM_tc5dIWuARGoY2 {
  max-width: 924px; }

._2GIXr33-6g2_V7JbmWVCfc {
  margin-bottom: 174px;
  z-index: 2;
  position: relative;
  padding-top: 154px; }
  @media (max-width: 1024px) {
    ._2GIXr33-6g2_V7JbmWVCfc {
      margin-bottom: 32px;
      padding-top: 80px; } }
  @media (max-width: 768px) {
    ._2GIXr33-6g2_V7JbmWVCfc {
      padding-top: 43px; } }
  ._2GIXr33-6g2_V7JbmWVCfc h1 {
    text-transform: uppercase;
    max-width: 857px;
    font-size: 4.125em;
    font-weight: 900;
    line-height: 0.8;
    margin: 0 0 24px 0; }
    @media (max-width: 768px) {
      ._2GIXr33-6g2_V7JbmWVCfc h1 {
        font-size: 3em;
        line-height: 0.94;
        width: 100%; } }
  ._2GIXr33-6g2_V7JbmWVCfc h3 {
    font-size: 2.375em;
    font-weight: 800;
    margin: 50px 0 16px 0;
    line-height: 0.84;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      ._2GIXr33-6g2_V7JbmWVCfc h3 {
        font-size: 1.75em;
        line-height: 1.14;
        margin: 25px 0 16px 0; } }
  ._2GIXr33-6g2_V7JbmWVCfc p {
    margin: 0;
    font-size: 1.5em;
    line-height: 1.75;
    color: #ffffff; }
    @media (max-width: 486px) {
      ._2GIXr33-6g2_V7JbmWVCfc p {
        font-size: 1.25em;
        line-height: 1.5; } }
    ._2GIXr33-6g2_V7JbmWVCfc p strong,
    ._2GIXr33-6g2_V7JbmWVCfc p a {
      font-weight: bold; }

.V-ZhD6GMU7rEbErm7-byk {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 1134px; }
  @media (max-width: 1024px) {
    .V-ZhD6GMU7rEbErm7-byk {
      flex-wrap: wrap;
      justify-content: flex-start; } }
  @media (max-width: 768px) {
    .V-ZhD6GMU7rEbErm7-byk {
      flex-direction: column;
      width: 100%;
      justify-content: initial; } }
  .V-ZhD6GMU7rEbErm7-byk ._2pkf3ZKPw2nolL1BmyCSaC {
    width: 275px;
    align-self: flex-end; }
    @media (max-width: 1024px) {
      .V-ZhD6GMU7rEbErm7-byk ._2pkf3ZKPw2nolL1BmyCSaC {
        align-self: initial;
        margin-right: 100px; } }
    @media (max-width: 1024px) {
      .V-ZhD6GMU7rEbErm7-byk ._2pkf3ZKPw2nolL1BmyCSaC:nth-of-type(2n + 2) {
        margin-right: 0; } }
    .V-ZhD6GMU7rEbErm7-byk ._2pkf3ZKPw2nolL1BmyCSaC p {
      width: 300px;
      line-height: normal;
      font-size: 1.125em;
      font-weight: 300;
      color: #ffffff; }
      .V-ZhD6GMU7rEbErm7-byk ._2pkf3ZKPw2nolL1BmyCSaC p b {
        font-weight: bolder; }

._6cxPekfXN6p84_F65xgB2 {
  position: relative;
  z-index: 2;
  display: flex;
  padding-top: 0;
  padding-bottom: 138px; }
  @media (max-width: 832px) {
    ._6cxPekfXN6p84_F65xgB2 {
      padding-top: 32px;
      padding-bottom: 70px; } }

._1PWN5YBCxuIpq9tHvSw5AG {
  font-weight: 900;
  font-size: 4.125em;
  line-height: 0.8;
  color: white;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 88px;
  margin-top: 0; }
  @media (max-width: 832px) {
    ._1PWN5YBCxuIpq9tHvSw5AG {
      font-size: 3em;
      line-height: 0.79;
      margin-bottom: 48px;
      text-align: left; } }
  @media (max-width: 486px) {
    ._1PWN5YBCxuIpq9tHvSw5AG {
      font-size: 2em; } }

._3Ie25Nn9fMHYhnHuYB3bGL {
  position: relative;
  margin-bottom: 90px;
  display: flex;
  justify-content: center; }
  @media (max-width: 832px) {
    ._3Ie25Nn9fMHYhnHuYB3bGL {
      justify-content: flex-start;
      flex-flow: column;
      margin-bottom: 32px; } }
  ._3Ie25Nn9fMHYhnHuYB3bGL:last-of-type {
    margin-bottom: 0; }
  ._3Ie25Nn9fMHYhnHuYB3bGL::before {
    content: '';
    width: 3px;
    background: white;
    height: calc(100% + 90px);
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    margin: auto;
    z-index: 1; }
    @media (max-width: 832px) {
      ._3Ie25Nn9fMHYhnHuYB3bGL::before {
        right: auto;
        top: 17px;
        left: 16px;
        height: calc(100% + 32px); } }

._3NtnkEtZ_ijWLvuuiND0q8 {
  background: white;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.375em;
  font-weight: 900;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  color: #0078e6;
  z-index: 2;
  top: 40px; }
  @media (max-width: 832px) {
    ._3NtnkEtZ_ijWLvuuiND0q8 {
      right: auto;
      width: 34px;
      height: 34px;
      font-size: 1em;
      top: 0; } }

._1rpvQJqai-chXdQ_-aG6n_ {
  border-radius: 100%;
  background: #00dc32;
  width: 67px;
  height: 67px;
  top: 29px; }
  @media (max-width: 832px) {
    ._1rpvQJqai-chXdQ_-aG6n_ {
      width: 50px;
      height: 50px;
      top: -8px;
      left: -9px; } }

._3Ie25Nn9fMHYhnHuYB3bGL ._1apluV-uTU2VcxdfsRUwLU {
  max-width: 380px;
  min-width: 380px;
  background: white;
  padding: 32px;
  position: relative;
  margin-right: calc(136px + 40px + 24px);
  margin-left: auto;
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1); }
  @media (max-width: 1220px) {
    ._3Ie25Nn9fMHYhnHuYB3bGL ._1apluV-uTU2VcxdfsRUwLU {
      margin-right: calc(41px + 12px + 24px); } }
  @media (max-width: 1024px) {
    ._3Ie25Nn9fMHYhnHuYB3bGL ._1apluV-uTU2VcxdfsRUwLU {
      max-width: 324px;
      min-width: 324px; } }
  @media (max-width: 832px) {
    ._3Ie25Nn9fMHYhnHuYB3bGL ._1apluV-uTU2VcxdfsRUwLU {
      margin-right: 0;
      margin-left: calc(16px + 12px + 34px);
      min-width: 1px; } }
  ._3Ie25Nn9fMHYhnHuYB3bGL ._1apluV-uTU2VcxdfsRUwLU::before {
    content: '';
    width: 136px;
    height: 2px;
    background: white;
    position: absolute;
    right: -136px;
    top: 64px; }
    @media (max-width: 1220px) {
      ._3Ie25Nn9fMHYhnHuYB3bGL ._1apluV-uTU2VcxdfsRUwLU::before {
        width: 41px;
        right: -41px; } }
    @media (max-width: 832px) {
      ._3Ie25Nn9fMHYhnHuYB3bGL ._1apluV-uTU2VcxdfsRUwLU::before {
        top: 16px;
        width: 16px;
        left: -16px;
        right: auto; } }

._2_fudIip0rTtAJcaxYiU3M {
  font-size: 2.375em;
  line-height: 0.84;
  color: #143264;
  margin: 0;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-weight: 800; }
  @media (max-width: 832px) {
    ._2_fudIip0rTtAJcaxYiU3M {
      font-size: 1.75em;
      line-height: 0.86; } }

._38szIkjokM3yyaUTJyxWts {
  font-size: 1.125em;
  line-height: 1.33;
  margin: 0; }

._38szIkjokM3yyaUTJyxWts a {
  color: #0078e6;
  text-decoration: underline;
  font-weight: bold; }
  ._38szIkjokM3yyaUTJyxWts a:hover {
    text-decoration: none; }

._3Ie25Nn9fMHYhnHuYB3bGL .-nwmr1SXc9NP_WzCeT-EK {
  background: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 832px) {
    ._3Ie25Nn9fMHYhnHuYB3bGL .-nwmr1SXc9NP_WzCeT-EK {
      display: none; } }
  ._3Ie25Nn9fMHYhnHuYB3bGL .-nwmr1SXc9NP_WzCeT-EK::before {
    display: none; }

._3Ie25Nn9fMHYhnHuYB3bGL .-nwmr1SXc9NP_WzCeT-EK svg {
  width: 284px;
  height: auto; }
  @media (max-width: 1024px) {
    ._3Ie25Nn9fMHYhnHuYB3bGL .-nwmr1SXc9NP_WzCeT-EK svg {
      width: 148px; } }

._3Ie25Nn9fMHYhnHuYB3bGL ._1kSXYZqCRT91GPL4vVzj-H {
  margin-right: auto;
  margin-left: calc(136px + 40px + 24px); }
  @media (max-width: 1220px) {
    ._3Ie25Nn9fMHYhnHuYB3bGL ._1kSXYZqCRT91GPL4vVzj-H {
      margin-left: calc(41px + 12px + 24px); } }
  @media (max-width: 832px) {
    ._3Ie25Nn9fMHYhnHuYB3bGL ._1kSXYZqCRT91GPL4vVzj-H {
      margin-left: calc(16px + 12px + 34px); } }
  ._3Ie25Nn9fMHYhnHuYB3bGL ._1kSXYZqCRT91GPL4vVzj-H::before {
    left: -136px;
    right: auto; }
    @media (max-width: 1220px) {
      ._3Ie25Nn9fMHYhnHuYB3bGL ._1kSXYZqCRT91GPL4vVzj-H::before {
        left: -41px; } }
    @media (max-width: 832px) {
      ._3Ie25Nn9fMHYhnHuYB3bGL ._1kSXYZqCRT91GPL4vVzj-H::before {
        left: -16px; } }

.AMZWTefCtsmMDL1z3KLD6::before {
  display: none; }

@media (max-width: 832px) {
  .AMZWTefCtsmMDL1z3KLD6 ._1apluV-uTU2VcxdfsRUwLU:last-of-type {
    margin-top: 32px; } }

@media (max-width: 832px) {
  ._9MmkPVHsHcBLpaJEy20qx ._1apluV-uTU2VcxdfsRUwLU:last-of-type {
    margin-top: 32px; } }

@media (max-width: 832px) {
  ._9MmkPVHsHcBLpaJEy20qx ._1apluV-uTU2VcxdfsRUwLU:last-of-type::before {
    display: none; } }

._3Ie25Nn9fMHYhnHuYB3bGL._5JJK-h6nRURm-vRinILnd ._1apluV-uTU2VcxdfsRUwLU {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.Jd_MWgH9_ASsEhEP_EkNx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 24px;
  cursor: pointer; }
  .Jd_MWgH9_ASsEhEP_EkNx:last-of-type {
    margin-bottom: 0; }
  @media (max-width: 1024px) {
    .Jd_MWgH9_ASsEhEP_EkNx {
      flex-flow: column;
      margin-bottom: 48px; } }
  @media (max-width: 540px) {
    .Jd_MWgH9_ASsEhEP_EkNx {
      margin-bottom: 24px; } }

._3EZRjK9eWTBGy7fmD_KhX_ {
  width: 525px;
  height: 322px;
  position: relative;
  overflow: hidden; }
  ._3EZRjK9eWTBGy7fmD_KhX_::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    mix-blend-mode: overlay;
    background-blend-mode: overlay, overlay;
    background-image: linear-gradient(to bottom, #0078e6, #0078e6); }

._1RME_kE6nsc44mvrtgtDze {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1); }

._2chNT1uts8GiMl2RMqkh3p {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  transition: -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1);
  transition: transform 0.2s cubic-bezier(0.35, 0, 0.65, 1), -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.65, 1); }

.jHOSpr0I9Udz434JMG2Jz {
  width: 52px;
  height: 52px;
  background: white;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center; }

._3mCcmLTA7LryZr-jxv2UOW {
  font-weight: bold;
  font-size: 1em;
  color: white; }

._2jtP-Py-RABuL3Hhbnvp-v {
  width: calc(100% - 525px);
  padding-left: 62px; }
  @media (max-width: 1024px) {
    ._2jtP-Py-RABuL3Hhbnvp-v {
      padding-left: 0;
      width: 100%; } }

._3QaIfAyzrSDY-9nh5vske_ {
  font-size: 2.375em;
  font-weight: 900;
  line-height: 0.84;
  color: #143264;
  text-transform: uppercase;
  max-width: 400px;
  margin-top: 28px;
  margin-bottom: 24px; }
  @media (max-width: 486px) {
    ._3QaIfAyzrSDY-9nh5vske_ {
      font-size: 1.75em;
      line-height: 0.93; } }

._2w_PIgFTeTToyMuFHmtOlq {
  font-size: 1.125em;
  line-height: 1.33;
  max-width: 489px;
  margin-top: 0;
  margin-bottom: 24px; }

@media (max-width: 540px) {
  ._26gaYC6Qq-dU0NSH0nM4xB {
    display: flex;
    flex-flow: column;
    align-items: flex-start; } }

@media (max-width: 540px) {
  ._26gaYC6Qq-dU0NSH0nM4xB .button {
    margin-bottom: 16px; } }

._26gaYC6Qq-dU0NSH0nM4xB .button:last-of-type {
  margin-bottom: 0; }

._26gaYC6Qq-dU0NSH0nM4xB .button:nth-child(2) {
  width: auto;
  margin-left: 8px; }
  @media (max-width: 540px) {
    ._26gaYC6Qq-dU0NSH0nM4xB .button:nth-child(2) {
      height: auto;
      margin-left: 0; } }
  @media (max-width: 540px) {
    ._26gaYC6Qq-dU0NSH0nM4xB .button:nth-child(2) .content {
      padding: 0;
      height: auto;
      min-height: 1px; } }

._3EZRjK9eWTBGy7fmD_KhX_:hover ._1RME_kE6nsc44mvrtgtDze {
  -webkit-transform: scale(1.11);
          transform: scale(1.11); }

._3xbXFTCubJT3fbHwKWdXLd {
  display: flex;
  flex-direction: column;
  padding-bottom: 127px;
  padding-top: 64px;
  position: relative;
  z-index: 2; }
  @media (max-width: 768px) {
    ._3xbXFTCubJT3fbHwKWdXLd {
      margin-bottom: 32px;
      padding-bottom: 0;
      padding-top: 0; } }

._2cY53NecT-RuBcYaGhDp4n {
  padding: 48px 0;
  position: relative; }

._2cY53NecT-RuBcYaGhDp4n p {
  max-width: 680px;
  margin-top: 0;
  margin-bottom: 32px; }

._2cY53NecT-RuBcYaGhDp4n .button {
  margin-bottom: 0; }

._1dgCahqoa_4Df7yQtlzsHK {
  max-width: 776px; }

._2nl_qeJ10xPPrV1x8HijOx {
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1.67;
  color: #000000;
  margin: 0;
  margin-bottom: 48px; }

._2RkwO6qWkCxHbTCKNL3Ayu {
  margin-bottom: 110px; }
  ._2RkwO6qWkCxHbTCKNL3Ayu h2 {
    font-family: Mark;
    font-size: 2.375em;
    font-weight: 900;
    line-height: 0.84;
    color: #143264;
    margin-top: 0;
    text-transform: uppercase; }
  ._2RkwO6qWkCxHbTCKNL3Ayu p {
    font-family: Mark;
    font-size: 1.125em;
    line-height: 1.56;
    color: #000000;
    margin: 0;
    margin-bottom: 24px; }
    @media (max-width: 900px) {
      ._2RkwO6qWkCxHbTCKNL3Ayu p {
        line-height: 1.56; } }
  ._2RkwO6qWkCxHbTCKNL3Ayu ul {
    margin-bottom: 24px; }
    ._2RkwO6qWkCxHbTCKNL3Ayu ul li {
      min-height: 43px;
      font-size: 1.125em;
      color: #000000;
      position: relative;
      padding-left: 44px; }
      ._2RkwO6qWkCxHbTCKNL3Ayu ul li:last-of-type {
        min-height: 28px; }
      ._2RkwO6qWkCxHbTCKNL3Ayu ul li span {
        position: absolute;
        left: 0;
        top: 0; }
      ._2RkwO6qWkCxHbTCKNL3Ayu ul li svg {
        margin-right: 16px; }

.m-3fRYK3pqa0N4ci0m-qk {
  margin-right: 16px; }

._3L48n5DNx_rLi7AWLfPq8T {
  padding-top: 190px; }
  @media (max-width: 1024px) {
    ._3L48n5DNx_rLi7AWLfPq8T {
      padding-top: 118px; } }

._3ShfFuwkqfxMboE253yFC2 {
  color: #143264;
  font-size: 4.125em;
  font-weight: 900;
  margin: 0 0 24px 0;
  line-height: 53px;
  text-transform: uppercase; }
  @media (max-width: 486px) {
    ._3ShfFuwkqfxMboE253yFC2 {
      font-size: 3em;
      line-height: 0.79; } }

._280_uM1mqmySDI-aEo-pQM {
  color: #0078e6;
  font-weight: 500;
  margin: 4px;
  text-transform: uppercase;
  font-size: 1.5em; }
  @media (max-width: 486px) {
    ._280_uM1mqmySDI-aEo-pQM {
      font-size: 1.125em; } }

._2CCNgRI7MiLfJdSiHvaVcx {
  margin: 32px 0;
  font-size: 1.5em;
  font-weight: normal; }
  @media (max-width: 486px) {
    ._2CCNgRI7MiLfJdSiHvaVcx {
      font-size: 1.125em;
      line-height: 1.33;
      width: calc(100% - 54px); } }

._2UmsERYdqm762QWo7M73Rb {
  position: relative;
  z-index: 2;
  padding-bottom: 115px; }
  @media (max-width: 1024px) {
    ._2UmsERYdqm762QWo7M73Rb {
      padding-bottom: 43px; } }

._2UmsERYdqm762QWo7M73Rb input {
  max-width: 360px; }

._2TPOQFk1PR97hkT-zM9dKY {
  font-size: 1em;
  margin-bottom: 0;
  margin-top: 24px; }

 {
  /* Helpers */
  /*
  1. Algemene style regels zet je hier neer
*/
  /* Flex helpers */
  /* Text styles */
  /* Margin */
  /* Padding */
  /* Width */
  /* Font weight */
  /* Font style */
  /* Font transform */
  /* Font decoration */
  /* Font align */
  /* Display */
  /* Text transforms */
  /* Base */
  /* Layout */
  /* SimpleBar */ }
  img {
    max-width: 100%; }
  .clearfix:after {
    content: '';
    display: table;
    clear: both; }
  @media screen and (max-width: 768px) {
    .hide-mobile {
      display: none !important; } }
  .flex {
    display: flex; }
  .flex-1 {
    flex: 1 1; }
  .flex-ajbottom {
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-start; }
  .flex-ajtop {
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start; }
  .flex-center {
    align-items: center;
    align-content: center;
    justify-content: flex-start; }
  .flex-justify {
    justify-content: space-between; }
  .title {
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0; }
  .ls0 {
    letter-spacing: 0px; }
  .ls1 {
    letter-spacing: 1px; }
  .ls2 {
    letter-spacing: 2px; }
  .ls4 {
    letter-spacing: 4px; }
  .ls11 {
    letter-spacing: 11px; }
  .mt0 {
    margin-top: 0px; }
  .mt10 {
    margin-top: 10px; }
  .mt12 {
    margin-top: 12px; }
  .mt24 {
    margin-top: 24px; }
  .mt32 {
    margin-top: 32px; }
  .mt38 {
    margin-top: 38px; }
  .mt40 {
    margin-top: 40px; }
  .mt42 {
    margin-top: 42px; }
  .mt44 {
    margin-top: 44px; }
  .mt50 {
    margin-top: 50px; }
  .mt55 {
    margin-top: 55px; }
  .mt64 {
    margin-top: 64px; }
  .mt60 {
    margin-top: 60px; }
  .mt80 {
    margin-top: 80px; }
  .mt100 {
    margin-top: 100px; }
  .mt120 {
    margin-top: 120px; }
  .ml5 {
    margin-left: 5px; }
  .ml10 {
    margin-left: 10px; }
  .ml20 {
    margin-left: 20px; }
  .ml30 {
    margin-left: 30px; }
  .mr8 {
    margin-right: 8px; }
  .mr16 {
    margin-right: 16px; }
  .mr20 {
    margin-right: 20px; }
  .mr30 {
    margin-right: 30px; }
  .mr40 {
    margin-right: 30px; }
  .mb0 {
    margin-bottom: 0; }
  .mb8 {
    margin-bottom: 8px; }
  .mb16 {
    margin-bottom: 16px; }
  .mb24 {
    margin-bottom: 24px; }
  .mb32 {
    margin-bottom: 32px; }
  .mb35 {
    margin-bottom: 35px; }
  .mb38 {
    margin-bottom: 38px; }
  .mb42 {
    margin-bottom: 42px; }
  .mb48 {
    margin-bottom: 48px; }
  .mb50 {
    margin-bottom: 50px; }
  .mb64 {
    margin-bottom: 64px; }
  .mb80 {
    margin-bottom: 80px; }
  .mb100 {
    margin-bottom: 100px; }
  .pt20 {
    padding-top: 20px; }
  .pt40 {
    padding-top: 40px; }
  .pt50 {
    padding-top: 50px; }
  .pt100 {
    padding-top: 100px; }
  .pt150 {
    padding-top: 150px; }
  .pb10 {
    padding-bottom: 10px; }
  .pb50 {
    padding-bottom: 50px; }
  .pb70 {
    padding-bottom: 70px; }
  .pb100 {
    padding-bottom: 100px; }
  .pb150 {
    padding-bottom: 150px; }
  .pl20 {
    padding-left: 20px; }
  .pr20 {
    padding-right: 20px; }
  .w50 {
    width: 50%; }
  .f-light {
    font-weight: 300; }
  .f-regular {
    font-weight: 400; }
  .f-medium {
    font-weight: 500; }
  .f-bold {
    font-weight: 800; }
  .f-black {
    font-weight: 900; }
  .italic {
    font-style: italic; }
  .uppercase {
    text-transform: uppercase; }
  .line-through {
    text-decoration: line-through; }
  .underline {
    text-decoration: underline; }
  .td-none {
    text-decoration: none; }
  .ta-left {
    text-align: left; }
  .ta-center {
    text-align: center; }
  .ta-right {
    text-align: right; }
  .hidden {
    visibility: hidden;
    display: none;
    opacity: 0; }
  .d-block {
    display: block; }
  .lowercase {
    text-transform: lowercase; }
  .uppercase {
    text-transform: uppercase; }
  .disable-scrolling {
    overflow: hidden; }
  .title {
    font-size: 4.125em;
    line-height: 0.8;
    font-weight: 300;
    margin: 0;
    margin-left: -3px;
    text-transform: uppercase;
    transition: all 0.2s cubic-bezier(0.35, 0, 0.65, 1); }
    .title b {
      font-weight: 900; }
    @media (max-width: 768px) {
      .title {
        font-size: 3em; } }
  * {
    box-sizing: border-box; }
  html {
    height: 100%;
    width: 100%; }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    cursor: default; }
  .react-reveal {
    z-index: 2;
    position: relative; }
  body {
    font-family: 'Mark', Helvetica, Arial, sans-serif;
    font-size: 1em;
    line-height: 1;
    color: #000000; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Mark', Helvetica, Arial, sans-serif;
    font-weight: 600;
    color: white; }
  h1 {
    font-size: 3em;
    line-height: 1.25em; }
    @media screen and (max-width: 768px) {
      h1 {
        font-size: 1.75em; } }
  h2 {
    font-size: 2.5em;
    line-height: normal; }
    @media screen and (max-width: 768px) {
      h2 {
        font-size: 1.875em; } }
  h3 {
    font-size: 1.5em; }
    @media screen and (max-width: 768px) {
      h3 {
        font-size: 1.25em; } }
  h4 {
    font-size: 1.25em;
    font-weight: 500; }
    @media screen and (max-width: 768px) {
      h4 {
        font-size: 1em; } }
  p {
    font-size: 1.25em;
    line-height: 1.5; }
  a {
    text-decoration: none;
    color: currentColor; }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1em;
    line-height: 1.5em; }
  .overflow-visible,
  .overflow-visible > * {
    overflow: visible !important; }
  body {
    overflow-x: hidden;
    position: relative;
    min-width: 320px; }
  body.disable-scroll {
    overflow: hidden; }
  main {
    width: 100%;
    margin-top: -76px;
    position: relative;
    overflow-x: hidden; }
    @media screen and (max-width: 1024px) {
      main {
        margin-top: 76px; } }
  @media screen and (max-width: 768px) {
    main.main--top {
      padding-top: 124px; } }
  main.blue {
    background: #0078e6; }
  .wrapper {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 32px);
    max-width: 1174px; }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      .wrapper {
        max-width: 783px; } }
    @media screen and (min-width: 768px) {
      .wrapper {
        width: calc(100% - 100px); } }
  #concreeto {
    /* feautured i18n */
    white-space: pre-line; }
    @media screen and (max-width: 1024px) {
      #concreeto {
        overflow-x: hidden; } }
  .simplebar-scrollbar {
    width: 3px;
    right: 1px; }
  .simplebar-scrollbar::before {
    border-radius: 0;
    opacity: 1;
    background: #0078e6; }
  .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1; }
  .simplebar-offset {
    right: -20px !important; }
  .simplebar-content-wrapper {
    padding-right: 20px !important; }
  .simplebar-track.simplebar-vertical {
    width: 5px; }

@font-face {
  font-family: 'Mark';
  src: url(/static/media/Mark-Heavy.d43cc373.woff2) format('woff2'),
    url(/static/media/Mark-Heavy.0de2939f.woff) format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url(/static/media/Mark-Black.f5a99c95.woff2) format('woff2'),
    url(/static/media/Mark-Black.5a5a3753.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url(/static/media/Mark-Bold.f591b84a.woff2) format('woff2'),
    url(/static/media/Mark-Bold.64981b3c.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url(/static/media/Mark-Medium.2dbbff57.woff2) format('woff2'),
    url(/static/media/Mark-Medium.752b66c6.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url(/static/media/Mark-Light.b78335d6.woff2) format('woff2'),
    url(/static/media/Mark-Light.4380e404.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url(/static/media/Mark-Regular.acf720c7.woff2) format('woff2'),
    url(/static/media/Mark-Regular.60fff9df.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

