@value variables: "styles/variables.scss";
@value blue,navy,defaultTransition from variables;

:local {
  .textblock {
    position: relative;
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 112px;

    @media (max-width: 768px) {
      margin-top: 56px;
      padding-bottom: 56px;
    }

    @media (max-width: 486px) {
      margin-top: 32px;
    }

    .namelist {
      display: flex;
      flex-flow: wrap;
      margin-top: 48px;
      justify-content: flex-start;
      width: calc(100% + 16px);
      margin-left: -8px;
      margin-right: -8px;
      margin-bottom: -28px;
    }
  }
}
