:local {
  .content {
    max-width: 776px;
  }
  .intro {
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1.67;
    color: #000000;
    margin: 0;
    margin-bottom: 48px;
  }
  .description {
    margin-bottom: 110px;
    h2 {
      font-family: Mark;
      font-size: 2.375em;
      font-weight: 900;
      line-height: 0.84;
      color: #143264;
      margin-top: 0;
      text-transform: uppercase;
    }
    p {
      font-family: Mark;
      font-size: 1.125em;
      line-height: 1.56;
      color: #000000;
      margin: 0;
      margin-bottom: 24px;
      @media (max-width: 900px) {
        line-height: 1.56;
      }
    }
    ul {
      margin-bottom: 24px;
      li {
        min-height: 43px;
        font-size: 1.125em;
        color: #000000;
        position: relative;
        padding-left: 44px;
        &:last-of-type {
          min-height: 28px;
        }
        span {
          position: absolute;
          left: 0;
          top: 0;
        }
        svg {
          margin-right: 16px;
        }
      }
    }
  }
  .iconcheck {
    margin-right: 16px;
  }
}
