@value variables: "styles/variables.scss";
@value defaultTransition from variables;

:local {
  .social {
    position: absolute;
    right: -6px;
    bottom: 110px;
    margin-bottom: 84px;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    transition: all 0.2s defaultTransition;
    opacity: 0;
    transform: translateY(10px);
    @media (max-width: 1280px) {
      bottom: 0;
      margin-bottom: 32px;
    }
    @media (max-width: 768px) {
      transform: rotate(0deg);
      flex-direction: row;
      right: initial;
      position: relative;

      margin-top: 32px;
      margin-bottom: 0;
      order: 3;
    }
  }

  .social-enter-done {
    opacity: 1;
    transform: translateY(0px);
  }

  .social-title {
    transform-origin: right;
    transform: rotate(90deg) translate(0, 0%);
    text-transform: uppercase;
    font-weight: 400;
    white-space: nowrap;
    margin-right: 100%;
    display: flex;
    align-items: center;
    align-self: flex-start;
    position: relative;
    right: 12px;
    font-size: 0.875em;
    letter-spacing: 1;
    opacity: 0;
    transition: all 0.2s defaultTransition;
    flex-direction: row-reverse;
    justify-content: flex-end;
    @media (max-width: 768px) {
      right: 0;
      transform: rotate(0deg);
      margin-right: 16px;
      margin-top: 4px;
    }
    &::before {
      content: '';
      width: 0;
      margin-left: 24px;
      background: white;
      height: 2px;
      display: inline-block;
      opacity: 0;
      // transition-delay: 2s;
      transition: all 0.2s defaultTransition;
      @media (max-width: 768px) {
        width: 39px;
      }
    }
  }

  .social-title-enter-done {
    opacity: 1;

    &::before {
      opacity: 1;
      width: 39px;
    }
  }

  .social-item {
    color: white;
    margin-bottom: 32px;
    transition: all 0.2s defaultTransition;
    opacity: 0;
    transform: scale(0);
    @media (max-width: 768px) {
      margin-right: 16px;
    }

    &:first-of-type {
      margin-top: 24px;

      @media (max-width: 768px) {
        margin-top: 0px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
      margin-right: 0;
    }

    @media (max-width: 768px) {
      margin-bottom: 0px;
    }
  }

  .social-item svg {
    width: 22px;
    height: 22px;
    transition: transform 0.2s ease;
  }

  .social-item:hover svg {
    transform: translateY(-2px);
  }

  .social-item-enter-done {
    opacity: 1;
    transform: scale(1);
  }
  .social--home {
    left: 0;
    right: auto;
    bottom: 53px;
    margin-bottom: 0;
    display: flex;
    flex-flow: row;
    @media (max-width: 1280px) {
      bottom: auto;
      margin-top: 32px;
      position: relative;
    }
  }
  .social--home .social-item {
    margin-right: 16px;
    margin-bottom: 0;
    &:first-of-type {
      margin-top: 0;
    }
  }
  .social--home .social-title {
    transform: rotate(0) translate(0, 0%);
    margin-right: 34px;
    top: 5px;
    right: 0;
  }
}
