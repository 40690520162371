@value variables: "styles/variables.scss";
@value blue from variables;

:local {
  .form {
    padding: 0 48px;
    position: relative;
    color: black;
    font-weight: bold;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 32px;
    @media (max-width: 486px) {
      width: 100%;
      padding: 0 16px;
      height: 100%;
    }

    .names {
      display: flex;
      justify-content: space-between;

      label {
        input {
          width: 180px;
          @media (max-width: 486px) {
            width: initial;
          }
        }
      }
      @media (max-width: 486px) {
        flex-direction: column;
      }
    }

    label {
      display: flex;
      flex-direction: column;
      @media (max-width: 486px) {
        width: calc(100% - 34px);
      }
    }

    .error {
      border-color: #ff7373;
    }
    .success {
      border-color: #00dc32;
    }
  }
  .form--blue {
    background: blue;
  }
  .input-wrap {
    width: 400px;
    @media (max-width: 486px) {
      width: 100%;
    }
  }

  .progressbar {
    composes: progressbar from 'components/cv-upload/styles.scss';
  }

  .progressbar-step-1 {
    composes: progressbar-step-1 from 'components/cv-upload/styles.scss';
  }

  .progressbar-step-2 {
    composes: progressbar-step-2 from 'components/cv-upload/styles.scss';
  }
  .error {
    composes: error from 'components/cv-upload/styles.scss';
  }
  .filter {
    margin-bottom: -24px;
  }
}
