:local {
  .featured-image {
    position: relative;
    background: white;
  }
  .featured-image :global(.notch) {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .wrapper {
    position: relative;
    padding-top: 32px;
    padding-bottom: 191px;
    @media (max-width: 1280px) {
      padding-top: 0;
      padding-bottom: 44px;
    }
    @media (max-width: 1024px) {
      padding-top: 42px;
    }
  }

  .image-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    height: 100%;
    @media (max-width: 768px) {
      position: static;
    }
  }

  .content {
    width: 588px;
    @media (max-width: 1280px) {
      width: 379px;
    }
    @media (max-width: 468px) {
      width: 100%;
    }
  }
  .content :global(.button) {
    @media (max-width: 468px) {
      width: 100%;
    }
  }

  .subtitle {
    color: #0078e6;
    font-weight: 500;
    margin: 4px;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 1.125em;
    }
  }

  .title {
    color: #143264;
    font-size: 4.125em;
    font-weight: 900;
    margin: 0 0 24px 0;
    line-height: 53px;
    text-transform: uppercase;
    hyphens: auto;
    @media (max-width: 768px) {
      font-size: 3em;
      line-height: 0.79;
    }
  }

  .description {
    margin: 32px 0;
    font-size: 1.5em;
    font-weight: normal;
    @media (max-width: 768px) {
      font-size: 1.125em;
      line-height: 1.33;
      width: calc(100% - 54px);
    }
  }

  .image {
    min-height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    position: relative;
    left: calc(784px);
    top: 0;
    @media (max-width: 1280px) {
      left: calc(379px + 41px);
      width: 800px;
    }
    @media (max-width: 1024px) {
      width: 600px;
    }
    @media (max-width: 768px) {
      height: 475px;
      width: calc(100% + 32px);
      left: -16px;
    }
  }

  // right
  .featured-image--right .wrapper {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 768px) {
      justify-content: flex-start;
    }
  }
  .featured-image--right .image {
    left: calc(-100% + 391px);
    @media (max-width: 1280px) {
      right: calc(379px + 41px);
      left: auto;
      width: 100%;
    }
    @media (max-width: 768px) {
      // height: 475px;
      // width: calc(100% + 32px);
      width: calc(100% + 32px);
      left: -16px;
      right: auto;
    }
  }
}
