:local {
  .video {
    width: 100%;
    height: 768px;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56%;
    display: block;
    background: black;
  }
  .video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
