:local {
  .background {
    background: #0078e6;
    position: relative;
  }

  .block {
    z-index: 2;
    position: relative;
    display: flex;
    padding-top: 197px;
    padding-bottom: 216px;
    backface-visibility: hidden;

    @media (max-width: 1024px) {
      padding-top: 64px;
      padding-bottom: 174px;
      flex-direction: column;
      padding-bottom: 64px;
    }

    @media (max-width: 768px) {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }

  .content {
    color: white;

    h1 {
      font-size: 4.125em;
      font-weight: 900;
      margin: 0 0 24px 0;
      line-height: 0.8;
      text-transform: uppercase;
      @media (max-width: 768px) {
        font-size: 3em;
        line-height: 0.79;
      }
    }

    p {
      margin: 0 0 32px 0;
      font-size: 1.5em;
      font-weight: normal;
      @media (max-width: 768px) {
        width: calc(100% - 54px);
        font-size: 1.125em;
        line-height: 1.33;
      }
    }
    :global(.button) {
      @media (max-width: 486px) {
        width: 100%;
      }
    }
  }

  .image {
    margin-right: 52px;
    position: relative;
    left: -28px;
    @media (max-width: 1024px) {
      margin-bottom: 32px;
    }
    @media (max-width: 768px) {
      left: 0;
      margin-right: 0;
    }
    svg {
      @media (max-width: 1024px) {
        width: 250px;
        height: auto;
      }
      @media (max-width: 768px) {
        width: 192px;
        height: auto;
      }
    }
  }
}
