@value variables: "styles/variables.scss";
@value defaultTransition from variables;
:local {
  .video {
    max-width: 525px;
    height: 322px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 24px;
    @media (max-width: 520px) {
      height: 250px;
    }
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      // background: rgba(0, 0, 0, 0.6);
      left: 0;
      top: 0;
      position: absolute;
      transition: background 0.2s defaultTransition;

      mix-blend-mode: overlay;
      background-blend-mode: overlay;
      background-image: linear-gradient(to bottom, #0078e6, #0078e6);
    }
  }

  .play {
    transition: transform 0.2s defaultTransition;
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }

  .icon {
    // position: absolute;
    width: 52px;
    height: 52px;
    background: white;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .caption {
    font-weight: bold;
    font-size: 1em;
    color: white;
  }

  .image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.2s defaultTransition;
  }

  // hover
  .video:hover .image {
    transform: scale(1.05);
  }
}
