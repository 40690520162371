:local {
  .footer {
    background: #000000;
    padding: 72px 0px 22px 0px;
    user-select: none;
    height: 100%;
    position: relative;
    overflow: hidden;
    color: white;

    @media (max-width: 1024px) {
      padding-bottom: 32px;
    }
    @media (max-width: 486px) {
      padding-top: 40px;
    }
  }

  .grid {
    width: 100%;
    max-width: 1174px;
    position: absolute;
    left: 0;
    right: 0;
    top: -110px;
    margin: 0 auto;
    height: calc(100% + 110px);
    z-index: 3;
    // padding: 0px 97px 0px 139px;
    background-repeat: initial;
    background-size: 196px;
    background-position: left;
    background-image: linear-gradient(
      to right,
      rgba(151, 151, 151, 0.4) 1px,
      transparent 1px
    );

    &::before {
      content: '';
      width: 100vw;
      height: calc(100% + 110px);
      position: absolute;
      left: calc(-100vw - 196px);
      top: 0;
      transform: rotate(180deg);
      background-repeat: initial;
      background-size: 196px;
      background-position: left;
      background-image: linear-gradient(
        to right,
        rgba(151, 151, 151, 0.4) 1px,
        transparent 1px
      );
    }

    &::after {
      content: '';
      width: 100vw;
      height: calc(100% + 110px);
      right: -100vw;
      position: absolute;
      top: 0;
      background-repeat: initial;
      background-size: 196px;
      background-position: left;
      background-image: linear-gradient(
        to right,
        rgba(151, 151, 151, 0.4) 1px,
        transparent 1px
      );
    }

    @media (max-width: 486px) {
      display: none;
    }
  }

  .wrapper {
    display: flex;

    position: relative;
    // line-height: 1.75;

    @media (max-width: 1024px) {
      flex-direction: column;
      margin-left: 16px;
      font-size: 1em;
    }

    .address {
      margin-right: 138px;

      svg {
        width: 255px;
        height: 39px;
        object-fit: contain;

        @media (max-width: 486px) {
          width: 179px;
          height: 27px;
        }
      }

      p {
        margin: 30px 0 8px 0;
        font-size: 1.5em;

        @media (max-width: 486px) {
          font-size: 1.125em;
        }
      }

      a {
        margin: 0 0 8px 0;

        svg {
          margin-bottom: 0;
          margin-left: 8px;
          width: 21px;
          height: 15px;
          transition: all 100ms ease;
        }
      }

      .route {
        font-weight: bold;
        font-size: 1.5em;

        @media (max-width: 486px) {
          font-size: 1.125em;
        }

        &:hover {
          svg {
            margin-left: 16px;
          }
        }

        &:active {
          svg {
            margin-left: 16px;
          }
        }
      }
      @media (max-width: 486px) {
        margin-right: initial;
      }
    }
  }

  .postfooter {
    display: flex;
    // margin: 0 auto;
    justify-content: flex-start;
    justify-content: space-between;
    z-index: 3;
    position: relative;
    font-size: 1.125em;
    width: 100%;
    // margin: 0 0 0 367px;
    @media (max-width: 1024px) {
      flex-flow: column;
    }
    @media (max-width: 486px) {
      font-size: 1em;
    }
    .copyright {
      opacity: 0.5;
      position: relative;
      margin-right: 390px;
      min-width: 392px;
      @media (max-width: 1280px) {
        min-width: 1px;
        margin-right: 0;
      }
    }

    .pral {
      // margin-left: 380px;
      min-width: 453px;
      display: flex;
      @media (max-width: 1280px) {
        min-width: 1px;
      }
      @media (max-width: 1024px) {
        margin-top: 16px;
        flex-flow: column;
      }
      a {
        text-decoration: underline;
        font-weight: normal;
        transition: all 100ms ease;
        margin-left: 44px;
        &:first-of-type {
          margin-left: 0;
        }
        &:hover {
          font-weight: 800;
        }
        @media (max-width: 1024px) {
          margin-left: 0;
          margin-bottom: 8px;
        }
        @media (max-width: 486px) {
          margin: 8px 0;
        }
        &:last-of-type {
          @media (max-width: 1024px) {
            margin-bottom: 0;
          }
        }
      }
      @media (max-width: 486px) {
        width: 100%;
        margin-left: 0;
        flex-direction: column;
        margin: 8px 0;
      }
    }
    @media (max-width: 486px) {
      flex-direction: column;
      margin: 0;
      margin-left: 16px;
    }
  }

  .slogan {
    position: absolute;
    width: 227px;
    margin-top: 149px;
    right: -33px;
    @media (max-width: 1024px) {
      position: relative;
      // margin-top: 50px;
      right: 0;
    }
    @media (max-width: 486px) {
      width: 143px;
    }
  }
}
