@value variables: "styles/variables.scss";
@value mainFont from variables;

body {
  font-family: mainFont;
  font-size: em(18, 18);
  line-height: 1;
  color: #000000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: mainFont;
  font-weight: 600;
  color: white;
}

h1 {
  font-size: em(48, 16);
  line-height: em(20, 16);

  @media screen and (max-width: 768px) {
    font-size: em(28, 16);
  }
}

h2 {
  font-size: em(40, 16);
  line-height: normal;

  @media screen and (max-width: 768px) {
    font-size: em(30, 16);
  }
}

h3 {
  font-size: em(24, 16);

  @media screen and (max-width: 768px) {
    font-size: em(20, 16);
  }
}

h4 {
  font-size: em(20, 16);
  font-weight: 500;

  @media screen and (max-width: 768px) {
    font-size: em(16, 16);
  }
}

p {
  font-size: em(20, 16);
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: currentColor;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1em;
  line-height: 1.5em;
}