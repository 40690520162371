@value variables: "styles/variables.scss";
@value blue,defaultTransition from variables;
:local {
  .content {
    padding-top: 190px;
    @media (max-width: 1024px) {
      padding-top: 118px;
    }
  }
  .title {
    color: #143264;
    font-size: 4.125em;
    font-weight: 900;
    margin: 0 0 24px 0;
    line-height: 53px;
    text-transform: uppercase;

    @media (max-width: 486px) {
      font-size: 3em;
      line-height: 0.79;
    }
  }
  .subtitle {
    color: #0078e6;
    font-weight: 500;
    margin: 4px;
    text-transform: uppercase;
    font-size: 1.5em;

    @media (max-width: 486px) {
      font-size: 1.125em;
    }
  }

  .description {
    margin: 32px 0;
    font-size: 1.5em;
    font-weight: normal;

    @media (max-width: 486px) {
      font-size: 1.125em;
      line-height: 1.33;
      width: calc(100% - 54px);
    }
  }

  .form {
    position: relative;
    z-index: 2;
    padding-bottom: 115px;
    @media (max-width: 1024px) {
      padding-bottom: 43px;
    }
  }
  .form input {
    max-width: 360px;
  }
  .response {
    font-size: 1em;
    margin-bottom: 0;
    margin-top: 24px;
  }
}
