@value variables: "styles/variables.scss";
@value defaultTransition from variables;
:local {
  .grid {
    // position: relative;
  }

  .blueprint {
    composes: wrapper from global;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    height: calc(100%);
    z-index: 1;
    background-repeat: initial;
    background-size: 196px;
    background-position: left;
    background-image: linear-gradient(
      to right,
      rgba(151, 151, 151, 0.4) 1px,
      transparent 1px
    );
    @media (max-width: 768px) {
      display: none;
    }
    &::before {
      content: '';
      width: 100vw;
      height: calc(100%);
      position: absolute;
      left: calc(-100vw - 196px);
      top: 0;
      transform: rotate(180deg);
      background-repeat: initial;
      background-size: 196px;
      background-position: left;
      background-image: linear-gradient(
        to right,
        rgba(151, 151, 151, 0.4) 1px,
        transparent 1px
      );
    }
    &::after {
      content: '';
      width: 100vw;
      height: calc(100%);
      right: -100vw;
      position: absolute;
      top: 0;
      background-repeat: initial;
      background-size: 196px;
      background-position: left;
      background-image: linear-gradient(
        to right,
        rgba(151, 151, 151, 0.4) 1px,
        transparent 1px
      );
    }
  }
}
