@value variables: "styles/variables.scss";
@value blue,defaultTransition from variables;
:local {
  .contact {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;
    @media (max-width: 1024px) {
      flex-flow: column;
    }
  }
  .contact :global(.blueprint) {
    // z-index: 2;
  }
}
