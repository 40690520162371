:local {
  .navigation {
    align-items: center;
    align-self: center;
    border-left: solid white 1px;
    background-color: #0078e6;
    color: white;
    display: flex;
    flex-flow: row;
    height: 100%;
    justify-self: center;
    letter-spacing: 0.9px;
    position: relative;
    text-transform: uppercase;
    user-select: none;
    width: 80px;
    z-index: 2;
  }
  .languageSelector{
    display:flex;
    padding-left: 6px;
    .arrow {
      margin-left: 6px;
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .current {
    cursor: pointer;
    font-weight: 1600;
    margin-left: 10px;
    position: relative;
    z-index: 2;
    &::before {
      background-color: #0078e6;
      border-radius: 10px;
      content: "";
      height: 100%;
      left: -10px;
      position: absolute;
      top: 2px;
      width: 44px;
      z-index: -1;
    }
    &::after {
      background: currentColor;
      bottom: -2px;
      content: "";
      height: 1.5px;
      left: 0;
      position: absolute;
      transition: all 300ms ease;
      width: 0;
    }
  }
  .current--is-open {
    &::after {
      width: 100%;
    }
  }
  // List -- css transition
  .list {
    background-color: white;
    color: #0078e6;
    overflow: hidden;
    left: 0;
    padding: 0;
    position: absolute;
    top: calc(100% - 80px);
    transition: all 300ms ease;
    width: 100%;
    z-index: 3;
    li {
      text-align: center;
      cursor: pointer;
      margin-top: 10px;
      svg {
        height: 100%;
        width: 80%;
      }
    }
    .arrow {
      margin-bottom: 10px;
      svg {
        height: 40%;
        width: 50%;
      }
    }
  }
  // List -- css transition - modifiers
  .list--enter {
    opacity: 0;
    transform: translateY(-16px);
  }
  .list--enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 200ms, transform 200ms;
  }
  .list--exit {
    opacity: 1;
  }
  .list--exit-active {
    opacity: 0;
    transform: translateY(-16px);
    transition: opacity 200ms, transform 200ms;
  }
}