@value variables: "styles/variables.scss";
@value blue,navy,defaultTransition from variables;

:local {
  .contact {
    display: flex;
    flex-direction: column;
    padding-top: 78px;
    color: white;
    font-size: 1.500em;

    a {
      margin-bottom: 24px;
      font-weight: normal;
      transition: all 100ms linear;

      &:hover {
        font-weight: bold;

        svg rect {
          fill: #dbdbdb;
        }
      }
      @media (max-width: 486px) {
        margin-bottom: 10px;
      }
    }

    @media (max-width: 486px) {
      font-size: 1.125em;
      padding-top: 26px;
    }
  }

  .svg--black {
    margin-right: 16px;

    @media (max-width: 486px) {
      margin-right: 8px;
      width: 32px;
    }

    rect {
      fill: white;
    }

    path {
      fill: black;
    }
  }

  .svg--blue {
    margin-right: 16px;

    @media (max-width: 486px) {
      margin-right: 8px;
      width: 32px;
    }

    rect {
      fill: white;
    }

    path {
      fill: blue;
    }
  }
}