@value variables: "styles/variables.scss";
@value blue, navy, defaultTransition from variables;
:local {
  .filter-item {
    background: white;
    border-right: 1px solid #e4e4e4;
    color: #000;
    position: relative;
    user-select: none;
    width: 230px;
    min-width: 230px;
    // max-width: 230px;
    // height: 90px;
    @media (max-width: 768px) {
      width: 100%;
      min-width: 100%;
      // height: 60px;
      border-bottom: 1px solid #e4e4e4;
      border-right: 0;
    }
    &:last-of-type {
      border-right: 0;
    }
    &:nth-child(1) {
      width: 251px;
      min-width: 251px;
      @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
      }
    }
    &:nth-child(2) {
      width: 200px;
      min-width: 200px;
      @media (max-width: 1024px) {
        width: 200px;
        min-width: 200px;
      }
      @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
      }
    }
    &:nth-child(3) {
      width: 274px;
      min-width: 274px;

      @media (max-width: 1280px) {
        clear: left;
        width: 274px;
        min-width: 274px;
      }
      @media (max-width: 1024px) {
        width: 251px;
        min-width: 251px;
      }
      @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
      }
    }
    &:nth-child(4) {
      width: 274px;
      min-width: 274px;

      @media (max-width: 1280px) {
        width: 240px;
        min-width: 240px;
      }
      @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
      }
    }
  }
  .filter-item--is-active {
    z-index: 4;
  }
  .filter-item--disabled {
    pointer-events: none;
    * {
      opacity: 0.6;
    }
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.125em;
    color: #afafaf;
    height: 90px;
    padding: 0 24px;
    cursor: pointer;
    background: white;
    position: relative;
    z-index: 2;
    @media (max-width: 768px) {
      z-index: 2;
      height: 60px;
    }
  }
  .label .icon {
    color: blue;
    width: 17px;
    height: 10px;
    transition: transform 0.2s ease;
  }
  .label--is-active .icon {
    transform: rotate(180deg);
  }
  .value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
    width: calc(100% - 17px - 8px);
  }
  .value--is-active {
    font-weight: bold;
    color: #000000;
  }
  .dropdown {
    list-style: none;
    margin: 0;
    padding: 0;
    background: white;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.2s ease, padding 0.3s ease;
    position: absolute;
    top: 90px;
    left: 0;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
    // overflow: auto;
    @media (max-width: 768px) {
      top: 0;
      box-shadow: none;
      // border-bottom: 1px solid #e4e4e4
    }
    li:last-child {
      @media (max-width: 768px) {
        border-bottom: none;
      }
    }
  }
  .dropdown--is-active {
    max-height: 400px;

    @media (max-width: 768px) {
      position: relative;
    }
    // @media (max-width: 440px) {
    //   padding-top: 24px;
    //   padding-bottom: 26px;
    // }
  }
  .dropdown li {
    padding: 18px 24px;
    font-size: 1.125em;
    border-bottom: 1px solid #e4e4e4;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background 0.2s defaultTransition;
  }
  .dropdown li svg,
  .dropdown li svg g,
  .dropdown li svg path {
    fill: blue;
  }
  .dropdown li:hover .term {
    font-weight: bold;
  }
  .dropdown li:active {
    background: #f1f1f1;
  }
  .dropdown-item--is-active {
    font-weight: bold;
  }
  .action {
    width: 16px;
    height: 11px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }
  .remove {
    // display: none;
    width: 11px;
    height: 11px;
    pointer-events: none;
    opacity: 0;
    transform: translateY(4px);
    transition: all 0.2s defaultTransition;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .added {
    width: 16px;
    height: 11px;
    pointer-events: none;
    opacity: 0;
    transform: translateY(4px);
    transition: all 0.2s defaultTransition;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .dropdown-item--is-active .added {
    opacity: 1;
    transform: translateY(0);
  }
  .dropdown-item--is-active:hover .remove {
    opacity: 1;
    transform: translateY(0);
  }
  .dropdown-item--is-active:hover .added {
    opacity: 0;
    transform: translateY(-4px);
  }
  .filter-item--alert {
    width: calc(50% - 16px) !important; //Sorry
    min-width: 1px;
    margin-right: 32px;
    margin-bottom: 24px;
    border-right: none;
    border-bottom: 0;
    &:nth-of-type(2n + 2) {
      margin-right: 0;
    }
    @media (max-width: 768px) {
      order: 2;
      margin-right: 0;
      width: 100% !important;
    }
    @media (max-width: 486px) {
      width: calc(100% - 34px) !important;
    }
  }
  .filter-item--alert .label {
    border: 1px solid #d5d5d5;
    height: 48px;
    font-size: 1em;
    padding: 0 10px;
  }
  .filter-item--alert .label .icon {
    width: 13px;
  }
  .filter-item--alert .dropdown {
    top: calc(48px + 16px + 3px);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    border: 1px solid #d5d5d5;
    // @media (max-width: 768px) {
    //   top: -1px;
    // }
  }
  .filter-item--alert .dropdown--is-active {
    @media (max-width: 768px) {
      position: absolute;
    }
  }

  .filter-item--alert .dropdown li {
    padding: 8px 10px;
    font-size: 1em;
    &:last-of-type {
      border-bottom: 0;
    }
  }
  .filter-item--alert .action {
    width: 13px;
  }
  .filter-item--alert .added {
    width: 13px;
  }

  .field-name {
    margin-bottom: 4px;
  }
}
