@value variables: "styles/variables.scss";
@value blue,navy,defaultTransition from variables;

:local {
  .top {
    pointer-events: none;
    position: relative;
    z-index: 2;
    @media (max-width: 768px) {
      pointer-events: initial;
    }
  }
  .top :global(.button) {
    @media (max-width: 486px) {
      width: 100%;
    }
  }
  .top :global(.notch) {
    @media (max-width: 1280px) {
      display: none;
    }
  }
  .dummy {
    composes: sidebar from 'components/vacancy-detail/styles.scss';
    @media (max-width: 1280px) {
      display: none;
    }
  }

  .amount {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: 100px;
    padding-top: 58px;
    @media (max-width: 1024px) {
      margin-bottom: 56px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: initial;
      margin-bottom: 24px;
      padding-top: 34px;
    }
    @media (max-width: 486px) {
      width: 100%;
    }
  }

  .amount-item {
    text-transform: uppercase;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    // margin-left: 66px;
    width: 196px;

    @media (max-width: 768px) {
      margin-bottom: 18px;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 48px;

      @media (max-width: 768px) {
        margin-bottom: 34px;
      }
    }

    span {
      font-size: 4.125em;
      line-height: 0.8;
      font-weight: 900;
      margin-right: 6px;
    }

    b {
      font-size: 1.125em;
      font-weight: 800;
      line-height: 0.83;
    }
  }

  .wrapper {
    display: flex;
    position: relative;
    z-index: 2;
    margin-top: -220px;

    @media (max-width: 1280px) {
      margin-top: 0;
    }
  }

  .categories {
    display: flex;
    flex-flow: wrap;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: -16px;
  }

  .category-item {
    background-color: white;
    padding: 34px 40px;
    width: calc(50% - 16px);
    margin: 16px 8px;
    margin-top: 0;
    display: flex;
    transition: background 0.2s defaultTransition,
      transform 0.2s defaultTransition;

    &:hover {
      background-color: #eaeaea;
      transform: translateY(-2px);
    }
  }

  .content {
    margin-left: 65px;
  }

  .title {
    text-transform: uppercase;
    color: navy;
    font-weight: 800;
    line-height: 0.84;
    margin: 0;
    font-size: 2.375em;
  }

  .description {
    margin: 0;
    margin-top: 6px;
    line-height: 1.17;
    font-size: 1.5em;
    color: navy;
  }

  .iconname {
    width: fit-content;
    font-size: 1.125em;
    font-weight: 800;
    line-height: 0.94;
    color: #143264;
    padding: 11px;
  }
}
