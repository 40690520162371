:local {
  .sidebar {
    width: 344px;
    min-width: 344px;
    margin-right: 48px;
    z-index: 2;
    position: relative;
    @media (max-width: 1180px) {
      width: 244px;
      min-width: 244px;
      margin-right: 32px;
    }
    @media (max-width: 900px) {
      width: 100%;
      margin-right: 0;
      order: 2;
      margin-top: 24px;
    }
  }
  .sidebar :global(.button) {
    width: 100%;
    height: 63px;
    margin-top: 30px;
    max-width: 344px;
    @media (max-width: 486px) {
      max-width: 100%;
    }
  }

  .image {
    width: 186px;
    height: 186px;
    margin: 0;
  }

  .title {
    width: 197px;
    height: 17px;
    font-size: 1.5em;
    font-weight: 900;
    line-height: 0.71;
    letter-spacing: normal;
    color: #143264;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .contact {
    // width: 377px;
    font-size: 18px;
    font-weight: 800;
    line-height: 0.94;
    letter-spacing: normal;
    color: #0078e6;
    margin-top: 24px;
  }

  .subtitle {
    width: 226px;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
  }

  .wrapper {
    display: flex;
    margin-bottom: 101px;
    @media (max-width: 900px) {
      flex-flow: column;
      margin-bottom: 32px;
    }
  }

  .content {
    margin-top: -10px;
    @media (max-width: 900px) {
      margin-top: 0;
      width: 100%;
      order: 1;
    }
  }

  .related-wrap {
    @media (max-width: 900px) {
      order: 3;
    }
  }

  .related {
    display: flex;
    @media (max-width: 1024px) {
      flex-flow: column;
    }
  }

  .relatedItem {
    background-color: #143264;
    width: 380px;
    padding: 42px;
    // height: 380px;
    margin-right: 16px;
    z-index: 2;
    @media (max-width: 1280px) {
      // height: 280px;
      padding: 32px;
      width: calc(50% - 8px);
    }
    @media (max-width: 1024px) {
      width: 100%;
      margin-bottom: 16px;
      padding: 48px;
    }
    @media (max-width: 768px) {
      padding: 24px;
    }
    &:last-of-type {
      margin-right: 0;
      @media (max-width: 1024px) {
        margin-bottom: 0;
      }
    }
  }

  .relatedTitle {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 0.84;
    color: #ffffff;
    margin: 0;
    margin-bottom: 57px;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 1.75em;
      margin-bottom: 16px;
    }
  }

  .relatedSubTitle {
    width: 297px;
    font-family: Mark;
    font-size: 1.125em;
    color: #ffffff;
    text-transform: uppercase;
  }

  .icon {
    margin-bottom: 25px;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .personDetails {
    margin-bottom: 9px;
  }

  .icontag {
    padding: 10px;
    text-transform: uppercase;
  }

  .jobDescription {
    width: 500px;
    height: 43px;
    font-family: Mark;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
}
