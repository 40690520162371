@value variables: "styles/variables.scss";
@value defaultTransition from variables;
:local {
  .item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 24px;
    cursor: pointer;
    &:last-of-type {
      margin-bottom: 0;
    }
    @media (max-width: 1024px) {
      flex-flow: column;
      margin-bottom: 48px;
    }
    @media (max-width: 540px) {
      margin-bottom: 24px;
    }
  }
  .image {
    width: 525px;
    height: 322px;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      mix-blend-mode: overlay;
      background-blend-mode: overlay, overlay;
      background-image: linear-gradient(to bottom, #0078e6, #0078e6);
    }
  }
  .figure {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.2s defaultTransition;
  }

  .play {
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    transition: transform 0.2s defaultTransition;
  }

  .icon {
    // position: absolute;
    width: 52px;
    height: 52px;
    background: white;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .caption {
    font-weight: bold;
    font-size: 1em;
    color: white;
  }

  .content {
    width: calc(100% - 525px);
    padding-left: 62px;
    @media (max-width: 1024px) {
      padding-left: 0;
      width: 100%;
    }
  }
  .title {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 0.84;
    color: #143264;
    text-transform: uppercase;
    max-width: 400px;
    margin-top: 28px;
    margin-bottom: 24px;
    @media (max-width: 486px) {
      font-size: 1.75em;
      line-height: 0.93;
    }
  }
  .description {
    font-size: 1.125em;
    line-height: 1.33;
    max-width: 489px;
    margin-top: 0;
    margin-bottom: 24px;
  }

  .button-cta {
    @media (max-width: 540px) {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
    }
  }
  .button-cta :global(.button) {
    @media (max-width: 540px) {
      margin-bottom: 16px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .button-cta :global(.button):nth-child(2) {
    width: auto;
    margin-left: 8px;
    @media (max-width: 540px) {
      height: auto;
      margin-left: 0;
    }
    :global(.content) {
      @media (max-width: 540px) {
        padding: 0;
        height: auto;
        min-height: 1px;
      }
    }
  }
  // hover
  .image:hover .figure {
    transform: scale(1.11);
  }
}
