@value variables: "styles/variables.scss";
@value defaultTransition from variables;
/*
  1. Algemene style regels zet je hier neer
*/

img {
  max-width: 100%;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.hide-mobile {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

/* Flex helpers */
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-ajbottom {
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-start;
}
.flex-ajtop {
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
}
.flex-center {
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

.flex-justify {
  justify-content: space-between;
}

/* Text styles */
.title {
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0;
}

.ls0 {
  letter-spacing: 0px;
}
.ls1 {
  letter-spacing: 1px;
}
.ls2 {
  letter-spacing: 2px;
}
.ls4 {
  letter-spacing: 4px;
}
.ls11 {
  letter-spacing: 11px;
}

/* Margin */
.mt0 {
  margin-top: 0px;
}
.mt10 {
  margin-top: 10px;
}
.mt12 {
  margin-top: 12px;
}
.mt24 {
  margin-top: 24px;
}
.mt32 {
  margin-top: 32px;
}
.mt38 {
  margin-top: 38px;
}
.mt40 {
  margin-top: 40px;
}
.mt42 {
  margin-top: 42px;
}
.mt44 {
  margin-top: 44px;
}
.mt50 {
  margin-top: 50px;
}
.mt55 {
  margin-top: 55px;
}
.mt64 {
  margin-top: 64px;
}
.mt60 {
  margin-top: 60px;
}
.mt80 {
  margin-top: 80px;
}
.mt100 {
  margin-top: 100px;
}
.mt120 {
  margin-top: 120px;
}

.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}
.ml30 {
  margin-left: 30px;
}

.mr8 {
  margin-right: 8px;
}
.mr16 {
  margin-right: 16px;
}
.mr20 {
  margin-right: 20px;
}
.mr30 {
  margin-right: 30px;
}
.mr40 {
  margin-right: 30px;
}

.mb0 {
  margin-bottom: 0;
}
.mb8 {
  margin-bottom: 8px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb32 {
  margin-bottom: 32px;
}
.mb35 {
  margin-bottom: 35px;
}
.mb38 {
  margin-bottom: 38px;
}
.mb42 {
  margin-bottom: 42px;
}
.mb48 {
  margin-bottom: 48px;
}
.mb50 {
  margin-bottom: 50px;
}
.mb64 {
  margin-bottom: 64px;
}
.mb80 {
  margin-bottom: 80px;
}
.mb100 {
  margin-bottom: 100px;
}

/* Padding */
.pt20 {
  padding-top: 20px;
}
.pt40 {
  padding-top: 40px;
}
.pt50 {
  padding-top: 50px;
}
.pt100 {
  padding-top: 100px;
}
.pt150 {
  padding-top: 150px;
}

.pb10 {
  padding-bottom: 10px;
}
.pb50 {
  padding-bottom: 50px;
}
.pb70 {
  padding-bottom: 70px;
}
.pb100 {
  padding-bottom: 100px;
}
.pb150 {
  padding-bottom: 150px;
}

.pl20 {
  padding-left: 20px;
}

.pr20 {
  padding-right: 20px;
}

/* Width */
.w50 {
  width: 50%;
}

/* Font weight */
.f-light {
  font-weight: 300;
}
.f-regular {
  font-weight: 400;
}
.f-medium {
  font-weight: 500;
}
.f-bold {
  font-weight: 800;
}
.f-black {
  font-weight: 900;
}

/* Font style */
.italic {
  font-style: italic;
}

/* Font transform */
.uppercase {
  text-transform: uppercase;
}

/* Font decoration */
.line-through {
  text-decoration: line-through;
}
.underline {
  text-decoration: underline;
}
.td-none {
  text-decoration: none;
}

/* Font align */
.ta-left {
  text-align: left;
}
.ta-center {
  text-align: center;
}
.ta-right {
  text-align: right;
}

.hidden {
  visibility: hidden;
  display: none;
  opacity: 0;
}

/* Display */
.d-block {
  display: block;
}

/* Text transforms */
.lowercase {
  text-transform: lowercase;
}
.uppercase {
  text-transform: uppercase;
}

.disable-scrolling {
  overflow: hidden;
  // height: 100%;
}

.title {
  font-size: 4.125em;
  line-height: 0.8;
  font-weight: 300;
  margin: 0;
  margin-left: -3px;
  text-transform: uppercase;
  transition: all 0.2s defaultTransition;
  b {
    font-weight: 900;
  }
  @media (max-width: 768px) {
    font-size: 3em;
  }
  // @media (max-width: 400px) {
  //   font-size: 2.25em;
  // }
}
