@value variables: "styles/variables.scss";
@value blue,red,green,navy from variables;

:local {
  .intro {
    width: 534px;
    @media (max-width: 1192px) {
      width: 100%;
    }
    @media (max-width: 486px) {
      width: 100%;
    }
  }
  .subtitle {
    text-transform: uppercase;
    font-size: 1.125em;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #0078e6;
    margin: 65px 0 8px 0;
    @media (max-width: 768px) {
      margin: 64px 0 0 0;
    }
  }

  .title {
    text-transform: uppercase;
    font-size: 2.375em;
    font-weight: 800;
    line-height: 0.84;
    color: #143264;
    margin: 0;
    margin-bottom: 16px;
    @media (max-width: 486px) {
      font-size: 1.75em;
      line-height: 0.93;
    }
  }

  .description {
    font-size: 1.125em;
    line-height: 1.56;
    color: #000000;
    font-weight: normal;
    @media (max-width: 486px) {
      margin-bottom: 14px;
    }
  }
}
