@value variables: "styles/variables.scss";
@value blue,navy,defaultTransition from variables;

:local {
  .textblock {
    position: relative;
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    background: #143264;
    padding-bottom: 145px;

    @media (max-width: 768px) {
      padding-top: 56px;
      padding-bottom: 8px;
    }

    @media (max-width: 486px) {
      padding-top: 32px;
    }

    .iconlist {
      display: flex;
      flex-flow: wrap;

      div {
        display: flex;
        align-items: flex-start;
      }

      span {
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      h2 {
        width: 324px;
        height: 96px;
        font-size: 38px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.84;
        letter-spacing: normal;
        margin-left: 40px;
        margin-right: 32px;
        display: flex;
        align-items: center;
      }
    }

    .panellist {
      display: flex;
      flex-flow: wrap;
      width: 110%;
      padding-bottom: 16px;
    }
  }
}
