@value variables: "styles/variables.scss";
@value blue,
navy,
defaultTransition from variables;

:local {
  .textblock {
    position: relative;
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 258px;

    @media (max-width: 768px) {
      margin-top: 56px;
      margin-bottom: 56px;
    }

    @media (max-width: 486px) {
      margin-top: 32px;
    }

    h3 {
      text-transform: uppercase;
      color: #0078e6;
      font-weight: 500;
      margin: 4px;

      @media (max-width: 768px) {
        font-size: 1.125em;
      }
    }

    h1 {
      font-size: 4.125em;
      font-weight: 900;
      line-height: 0.8;
      color: #143264;
      margin: 0 0 24px 0;
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 3em;
        line-height: 0.79;
      }
    }

    p {
      max-width: 968px;
      font-size: 1.5em;
      line-height: 1.75;
      color: black;

      @media (max-width: 768px) {
        width: auto;
        font-size: 1.125em;
        line-height: 1.56;
      }
    }
    .content {
      margin: 0 0 40px 0;
    }
  }
}
