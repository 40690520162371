@value variables: "styles/variables.scss";
@value blue from variables;
:local {
  .dropzone {
    width: 448px;
    min-height: 271px;
    border-style: dashed;
    border-color: #dcdcdc;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    transition: background-color 300ms ease;

    @media (max-width: 486px) {
      width: 100%;
    }

    svg {
      margin-bottom: 8px;
    }

    .text {
      font-size: 1.125em;
      font-weight: bold;
      width: 235px;
      line-height: 1.56;
    }

    .bluetext {
      color: blue;
    }

    &:hover {
      background-color: #dcdcdc;
      border-color: #c1c1c1;
    }
  }

  .dropzonecomplete {
    background-color: #dcdcdc;
  }

  .filledCV {
    width: 448px;
    min-height: 271px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0078e6;
    @media (max-width: 486px) {
      width: 100%;
    }

    svg {
      transform: rotate(180deg);
      margin-bottom: 8px;
    }

    .text {
      font-size: 1.125em;
      font-weight: bold;
      color: white;
      line-height: 1.56;
      text-align: center;
      display: flex;
      align-items: center;
      flex-flow: column;
    }

    .linktext {
      color: white;
      text-decoration: underline;
      cursor: pointer;
      font-weight: normal;
    }
  }
  .file-name {
    max-width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    @media (max-width: 486px) {
      max-width: 80%;
    }
  }
}
