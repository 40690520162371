:local {
  .block {
    //   // height: 945px;
    z-index: 2;
    position: relative;
    //   max-width: 1174px;
    //   margin-left: auto;
    //   margin-right: auto;
    //   @media (max-width: 486px) {
    //     margin-left: 16px;
    //     margin-right: 46px;
    //     height: 100%;
    //   }
    @media (max-width: 1024px) {
      padding: 118px 0 calc(110px + 32px);
    }
    @media (max-width: 768px) {
      padding: 24px 0 32px;
    }
  }

  .background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      mix-blend-mode: multiply;
      background-blend-mode: multiply, multiply;
      background-image: linear-gradient(
          to bottom,
          rgba(0, 120, 230, 0.5),
          rgba(0, 120, 230, 0.5)
        ),
        linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    }
  }

  .title {
    font-size: 4.125em;
    font-weight: 900;
    margin: 0 0 24px 0;
    padding: 117px 0 0 0;
    line-height: 0.8;
    text-transform: uppercase;
    @media (max-width: 1024px) {
      padding-top: 0;
    }
    @media (max-width: 768px) {
      font-size: 3em;
      line-height: 0.79;
      width: 250px;
    }
  }

  .description {
    font-size: 24px;
    color: #ffffff;
    max-width: 793px;
    margin-bottom: 88px;

    @media (max-width: 768px) {
      font-size: 1.125em;
      line-height: 1.33;
      margin-bottom: 24px;
    }
  }

  .headers {
    display: flex;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .answerblock {
    margin-right: 76px;
    @media (max-width: 1024px) {
      margin-right: 0;
    }

    h2 {
      margin: 0;
      font-weight: 800;
      text-transform: uppercase;
      @media (max-width: 768px) {
        font-size: 1.75em;
        line-height: 1.14;
      }
    }

    p {
      margin: 0 0 15px 0;
      font-size: 1.5em;
      line-height: 1.75;
      &:last-of-type {
        margin-bottom: 95px;
        @media (max-width: 1024px) {
          margin-bottom: 48px;
        }
        @media (max-width: 768px) {
          margin-bottom: 32px;
        }
      }
      @media (max-width: 768px) {
        font-size: 1.124em;
        line-height: 1.33;
      }
    }
    &:last-of-type {
      margin-right: 0;
      @media (max-width: 1024px) {
        margin-bottom: 0;
      }
    }
  }

  .answer {
    font-size: 24px;
    color: #ffffff;
    max-width: 317px;

    a {
      font-weight: bolder;
      position: relative;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }

      @media (max-width: 768px) {
        font-weight: bold;
      }
    }
  }

  .buttonwrapper {
    display: flex;
    justify-content: flex-end;
  }
  .headers :global(.button) {
    margin-bottom: 32px;
    @media (max-width: 1024px) {
      margin-bottom: 0;
    }
    @media (max-width: 486px) {
      width: 100%;
    }
  }
}
