@value variables: "styles/variables.scss";
@value blue,red,green,navy from variables;

:local {
  .overall {
    z-index: 999;
    position: sticky;
    top: 0;
    width: 100%;
    @media (max-width: 1024px) {
      position: fixed;
    }
  }
  :global(.ie) .overall {
    position: fixed;
    left: 0;
    width: 100%;
  }
  .btncontainer {
    position: absolute;
    z-index: 999;
    border-bottom: solid 1px #ffffff;
    font-weight: bold;
    font-size: 1.125em;
    transition: display 175ms linear;
    transition-delay: 500ms;
    width: 100%;
    max-width: 457px;
    @media (max-width: 486px) {
      max-width: 486px;
    }
  }

  .invisible {
    display: none;
  }

  .visible {
    display: flex;
  }

  .menubtn {
    display: flex;
    align-items: center;
    height: 75px;
    justify-content: center;
    color: white;
    width: 100%;
    max-width: 232px;
    min-width: 232px;
    font-size: 1.125em;
    .hamburger {
      margin-right: 8px;
      margin-top: 3px;
      width: 13px;
      height: 13px;
    }

    .hamburger-fill-A > g {
      fill: navy;
    }

    .hamburger-fill-B > g {
      fill: blue;
    }
    @media (max-width: 468px) {
      min-width: 0;
    }
  }

  .menubtn-background-A {
    background-color: blue;
  }

  .menubtn-background-B {
    background-color: navy;
  }

  .logo {
    width: 100%;
    transition: width 175ms ease;
    background: white;
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 224px;
  }

  .menucontainer {
    width: 100vw;
    position: fixed;
    background: white;
    color: navy;
    padding: 62px 204px 0 92px;
    max-width: 456px;
    height: calc(100vh - 76px);
    top: 76px;
    transition: left 300ms linear;
    z-index: 3;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @media (max-width: 700px) {
      padding: 30px 0 0 25px;
      padding-bottom: 98px;
      max-width: 456px;
    }

    h2 {
      color: navy;
      font-size: 2.375em;
      font-weight: 800;
      margin-top: 0;
      @media (max-width: 320px) {
        margin-bottom: 10px;
      }
    }

    li {
      margin-bottom: 28px;
      white-space: nowrap;
      font-size: 1.375em;
      font-weight: normal;

      &:last-of-type {
        margin-bottom: 0;
      }
      @media (max-width: 320px) {
        margin-bottom: 20px;
      }
    }
  }
  .amount {
    background: blue;
    display: inline-block;
    color: white;
    font-weight: bold;
    font-size: 16px;
    padding: 0 8px;
    margin-left: 4px;
  }

  .link--is-active {
    color: blue;
  }

  .closed {
    left: -500px;
  }

  .open {
    left: 0;
  }

  .phonebtn {
    position: fixed;
    // width: calc(100% - 92px);
    width: 100%;
    max-width: 456px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    bottom: 0;
    left: -456px;
    font-size: 1.375em;
    font-weight: bold;
    transition: left 300ms linear;
  }
  .open .phonebtn {
    left: 0;
  }

  .phone {
    margin-right: 8px;
  }

  .phone-fill-A > g {
    fill: navy;
  }

  .phonebtn-background-A {
    background-color: blue;
    color: white;
  }

  .uppercase {
    text-transform: uppercase;
  }
}
