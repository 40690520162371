@value variables: "styles/variables.scss";
@value navy,defaultTransition from variables;
:local .category-item {
  background-color: white;
  padding: 34px 40px;
  width: calc(50% - 16px);
  margin: 16px 8px;
  margin-top: 0;
  display: flex;
  align-items: flex-start;
  transition: background 0.2s defaultTransition, transform 0.2s defaultTransition; }
  @media (max-width: 1024px) {
    :local .category-item {
      padding: 23px 20px; } }
  @media (max-width: 680px) {
    :local .category-item {
      width: 100%; } }
  :local .category-item:hover {
    background-color: #eaeaea;
    transform: translateY(-2px); }

:local .icon {
  width: 120px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 1024px) {
    :local .icon {
      width: 70px;
      height: 70px; } }
  @media (max-width: 660px) {
    :local .icon {
      width: 100px;
      height: 100px; } }

:local .content {
  width: calc(100% - 120px - 34px);
  margin-left: 34px;
  padding-top: 24px; }
  @media (max-width: 1024px) {
    :local .content {
      margin-left: 22px;
      width: calc(100% - 70px - 22px); } }
  @media (max-width: 768px) {
    :local .content {
      padding-top: 0;
      width: calc(100% - 100px - 22px); } }

:local .title {
  text-transform: uppercase;
  color: navy;
  font-weight: 800;
  line-height: 0.84;
  margin: 0;
  font-size: 1.75em; }
  @media (max-width: 660px) {
    :local .title {
      font-size: 1.75em;
      line-height: 1.14; } }

:local .description {
  margin: 0;
  margin-top: 6px;
  line-height: 1.17;
  font-size: 1.5em;
  color: navy; }
  @media (max-width: 1024px) {
    :local .description {
      font-size: 1.125em;
      line-height: 1.33; } }
  @media (max-width: 486px) {
    :local .description {
      margin-top: 0; } }

:local .category-item--simple {
  padding: 0;
  background: none;
  pointer-events: none;
  margin-bottom: 72px; }

:local .category-item--simple .title {
  color: white; }

:local .category-item--simple .description {
  color: white; }

:local .category-item--simple svg,
:local .category-item--simple svg g,
:local .category-item--simple svg g path,
:local .category-item--simple path,
:local .category-item--simple polygon {
  fill: white; }
