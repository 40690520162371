:global {
  /* Helpers */
  @import './helpers/_functions';
  @import './helpers/_helpers';

  /* Base */
  @import './base/_additional';
  @import './base/_typography';

  /* Layout */
  @import './layout/_grid';

  #concreeto {
    @media screen and (max-width: 1024px) {
      overflow-x: hidden;
    }
    /* feautured i18n */
    white-space: pre-line;
  }

  /* SimpleBar */
  .simplebar-scrollbar {
    width: 3px;
    right: 1px;
  }
  .simplebar-scrollbar::before {
    border-radius: 0;
    opacity: 1;
    background: #0078e6;
  }
  .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
  }
  .simplebar-offset {
    right: -20px !important;
  }
  .simplebar-content-wrapper {
    padding-right: 20px !important;
  }
  .simplebar-track.simplebar-vertical {
    width: 5px;
  }
}
