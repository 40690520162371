:local{
  .wrapper {
    display: flex;
    margin-top: 20px;
    cursor: pointer;
    align-items: center;
    align-self: flex-start;
  }

  .dash {
    display: inline-block;
    border-top: 2px solid #FFFFFF;
    content: "";
    width: 30px;

    margin-left: 10px;
    margin-right: 10px;
  }

  .text {
    text-transform: uppercase;
    font-weight: 400;
  }
}

:global {
  .modal-video {
    position: fixed;
    //left: 25%;
    width: 100%;
    z-index: 999;
    justify-content: center;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    height: 100vh;
    top: 0;
    align-items: center;
  }

  .modal-video-movie-wrap {
    padding-bottom: 0 !important;
  }

  .modal-video-close-btn {
    position: relative;
    z-index: 999;
    top: -230px;
    left: 950px;
    display: inline-block;
    width: 35px;
    height: 35px;
    border: none;
    background: transparent;
  }

  .modal-video-close-btn:after, .modal-video-close-btn:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px;
  }

  .modal-video-close-btn:after {
    transform: rotate(-45deg);
  }

  .modal-video-close-btn:before {
    transform: rotate(45deg);
  }

  .modal-video-movie-wrap iframe {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 8px 22px 0 rgba(0, 0, 0, 0.19);
    height: 460px;
    width: 920px;
  }

  .no-scroll {
    overflow: hidden;
  }

  @media (max-width: 920px) {
    .modal-video-movie-wrap iframe {
      width: 100%;
    }
  }
}