@value variables: "styles/variables.scss";
@value blue, navy, grey, defaultTransition from variables;
:local {
  .item {
    background: white;

    display: block;
    width: calc(33.333333% - 20px);
    margin: 0 10px;
    margin-bottom: 20px;
    transform: translateY(-8px);
    opacity: 0;
    transition: transform 0.2s defaultTransition, opacity 0.2s defaultTransition;
    max-width: 372px;
    min-height: 400px;
    @media (max-width: 1180px) {
      width: calc(50% - 20px);
      max-width: calc(50% - 20px);
    }
    @media (max-width: 768px) {
      width: calc(100%);
      max-width: calc(100%);
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 8px;
      min-height: 100px;
    }
  }
  .content {
    padding: 32px;
  }
  .image {
    width: 100%;
    height: 100%;

    max-height: 224px;
    object-fit: cover;
  }
  .title {
    color: navy;
    font-weight: 900;
    line-height: 0.84;
    text-transform: uppercase;
    font-size: 2em;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 16px;
    hyphens: auto;
    @media (max-width: 1024px) {
      font-size: 1.75em;
    }
  }
  .subtitle {
    text-transform: uppercase;
    font-size: 1.125em;
    margin-bottom: 12px;
    display: block;
  }
  .description {
    color: grey;
    font-weight: 300;
    margin: 0;
    font-size: 1.125em;
  }
  .description p {
    margin: 0;
    font-size: 1em;
    line-height: 1.78;
  }
  .description span {
    font-size: 1em;
  }
  .link {
    color: blue;
    font-weight: bold;
    display: block;
    margin-top: 8px;
    svg {
      margin-left: 5px;
      position: relative;
      top: -1px;
      transition: transform 0.2s defaultTransition;
    }
  }

  //transition
  .item-enter-done {
    transform: translateY(0);
    opacity: 1;
  }

  // hover
  .item:hover {
    @media (min-width: 769px) {
      transform: translateY(-4px);
    }
  }
  .item:hover .link svg {
    @media (min-width: 769px) {
      transform: translateX(4px);
    }
  }
}
