@value variables: "styles/variables.scss";
@value blue,
navy,
defaultTransition from variables;

:local {
  .simple-content h3 {
    text-transform: uppercase;
    color: #0078e6;
    font-weight: 500;
    margin: 4px;
    margin-left: -1px;

    @media (max-width: 768px) {
      font-size: 1.125em;
      margin-left: 1px;
    }
  }

  .simple-content h1 {
    font-size: 4.125em;
    font-weight: 900;
    line-height: 0.8;
    color: #143264;
    margin: 0 0 24px 0;
    text-transform: uppercase;
    max-width: 760px;

    @media (max-width: 768px) {
      font-size: 3em;
      line-height: 0.79;
    }
  }

  .simple-content p {
    max-width: 968px;
    font-size: 1.5em;
    line-height: 1.75;
    color: #000000;

    @media (max-width: 768px) {
      width: auto;
      font-size: 1.125em;
      line-height: 1.56;
    }
  }
  .simple-content p a {
    color: #0078e6;
    &:hover {
      text-decoration: underline;
    }
  }
  .content {
    margin: 0 0 40px 0;
  }

  .button-container {
    display: flex;
    a {
      &:first-of-type {
        margin-right: 50px;
      }
    }

    @media (max-width: 470px) {
      flex-direction: column;
      height: 200px;
      justify-content: space-evenly;
    }
  }

  .simple-content--blue p {
    color: white;
  }
  .simple-content--blue p a {
    color: #143264;
  }
  .simple-content--blue h1 {
    color: white;
  }
  .simple-content--blue h3 {
    color: #143264;
  }

  .simple-content--dark-blue p {
    color: white;
  }
  .simple-content--dark-blue h1 {
    color: white;
  }
  .simple-content--dark-blue h3 {
    color: #0078e6;
  }
}
