:local {
  .list {
    display: flex;
    flex-direction: column;
    padding-bottom: 127px;
    position: relative;
    z-index: 2;
    @media (max-width: 768px) {
      margin-bottom: 32px;
      padding-bottom: 0;
    }
  }
}
