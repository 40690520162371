:local {
  .related-wrap {
    margin-top: 74px;
    @media (max-width: 900px) {
      margin-top: 32px;
      order: 3;
    }
  }

  .related {
    display: flex;
    @media (max-width: 1024px) {
      flex-flow: column;
    }
    @media (max-width: 768px) {
      margin-top: 0;
    }
  }
  .title {
    font-size: 2.375em;
    hyphens: auto;
    font-weight: 900;
    line-height: 0.84;
    letter-spacing: normal;
    color: #143264;
    margin-top: 0;
    text-transform: uppercase;
    @media (max-width: 768px) {
      display: none;
    }
  }
}
