@value variables: "styles/variables.scss";
@value blue, navy, grey, defaultTransition from variables;
:local {
  .grid {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    flex: 1;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: -20px;
    @media (max-width: 768px) {
      margin-left: -16px;
      margin-right: -16px;
      margin-bottom: -8px;
    }
  }
}
