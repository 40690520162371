@value variables: "styles/variables.scss";
@value blue,navy,defaultTransition from variables;

:local {
  .panel {
    width: 550px;
    // height: 500px;
    margin-bottom: 48px;
    margin-right: 47px;
    h2 {
      font-size: 38px;
      margin-top: 24px;
      margin-bottom: 0;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.84;
      letter-spacing: normal;
      color: #ffffff;
      text-transform: uppercase;
    }
    .position {
      font-size: 24px;
      font-weight: normal;
      color: #0078e6;
      text-transform: uppercase;
      margin-top: 8px;
      display: inline-block;
      //   margin-top: -20px;
    }
    p {
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #ffffff;
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
  .emailaddress {
    display: inline-block;
    color: white;
    margin-top: 16px;
  }
  .emailaddress svg {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }
  .emailaddress svg rect {
    fill: #0078e6;
  }
  .emailaddress svg path {
    fill: white;
  }
}
