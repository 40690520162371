* {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  cursor: default;
}

.react-reveal {
  z-index: 2;
  position: relative;
}
