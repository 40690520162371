@value variables: "styles/variables.scss";
@value blue, navy, grey, defaultTransition from variables;
:local {
  .pagination {
    display: flex;
    margin-top: 40px;
    color: white;
    @media (max-width: 768px) {
      margin-top: 32px;
    }
  }
  .pagination a,
  .pagination span {
    color: white;
    width: auto;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.2s defaultTransition;
    @media (max-width: 560px) {
      height: 39px;
    }
  }

  .pages {
    font-size: 1.5em;
    font-weight: 900;
    display: flex;
    align-items: center;
    @media (max-width: 560px) {
      font-size: 1.125em;
      overflow: auto;
    }
  }

  .pages li {
    position: relative;
    margin-right: 22px;
    @media (max-width: 560px) {
      margin-right: 22px;
    }
    @media (max-width: 360px) {
      margin-right: 10px;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  .pages li a {
    white-space: nowrap;
    cursor: pointer;
  }

  // active
  .pages li.page-active {
    opacity: 1;
  }
  .pages li.page-active a {
    color: blue;
    background: white;
    width: 49px;
    @media (max-width: 560px) {
      width: 39px;
    }
  }

  .pagination .button {
    background: white;
    width: 49px;
    height: 49px;
    @media (max-width: 560px) {
      width: 39px;
      height: 39px;
    }
    cursor: pointer;
    margin-left: 72px;
    @media (max-width: 768px) {
      margin-left: 22px;
    }
  }
  .pagination .button svg {
    width: 21.7px;
    height: 15.7px;
    transition: transform 0.2s ease;
    fill: currentColor;
    color: blue;
    @media (max-width: 560px) {
      width: 17.3px;
      height: 12.5px;
    }
  }
  .pagination .button--prev {
    margin-right: 72px;
    margin-left: 0;
    @media (max-width: 768px) {
      margin-right: 22px;
    }
  }
  .pagination .button--prev svg {
    transform: rotate(180deg);
  }
  // .button:hover svg {
  //   transform: translateY(-2px);
  // }
  // .button--prev:hover svg {
  //   transform: translateY(-2px) rotate(180deg);
  // }
}
