:local {
  .maplayer {
    position: absolute;
    border-style: none;
    z-index: 3;
    bottom: 0;
    width: 50%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    @media (max-width: 1024px) {
      position: relative;
      height: 450px;
      width: 100%;
    }
  }
  .maplayer iframe {
    height: 100%;
    width: 100%;
  }
}
