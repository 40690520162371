@value variables: "styles/variables.scss";
@value blue,red,green,navy,defaultTransition from variables;

:local {
  .story {
    position: relative;
    top: 0;
    left: 0;
    width: 457px;
    // height: 100%;
    display: flex;
    // transition: left 0.2s defaultTransition;
    background-color: #0078e6;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: 486px) {
      display: none;
    }
  }

  .image {
    height: 100%;
    width: 100%;
  }

  .content {
    left: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 0 51px 32px 32px;
  }

  .subtitle {
    text-transform: uppercase;
    z-index: 3;
    font-size: 1em;
    font-weight: 500;
    letter-spacing: -0.4px;
    margin: 0;
  }
  .title {
    text-transform: uppercase;
    z-index: 3;
    font-size: 2em;
    font-weight: 800;
    line-height: 0.84;
    margin: 8px 0 31px 0;
  }

  .slogan {
    width: 170px;
    height: 34px;
    z-index: 3;

    g {
      fill: white;
    }
  }

  .story--loaded {
    background-color: none;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      mix-blend-mode: multiply;
      background-blend-mode: multiply, multiply;
      background-image: linear-gradient(
          to bottom,
          rgba(0, 120, 230, 0.8),
          rgba(0, 120, 230, 0.8)
        ),
        linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    }
  }

  .loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subtitle,
  .title,
  .slogan {
    opacity: 0;
    transition: all 0.2s defaultTransition;
    transform: translateY(10px);
  }

  .subtitle.subtitle-enter-done,
  .title.title-enter-done,
  .slogan.slogan-enter-done {
    opacity: 1;
    transform: translateY(0);
  }
}
